import type React from "react";
import { useState } from "react";

import type { GridColDef } from "@mui/x-data-grid";
import { format, parseISO } from "date-fns";
import styled from "styled-components";

import { usePostPhysicianMedicalReferral } from "api/hooks/usePostPhysicianMedicalReferral";
import { usePostPhysicianPlanOfCare } from "api/hooks/usePostPhysicianPlanOfCare";
import type { PatientBase } from "api/schemas/physician";
import useApiClient from "api/useApiClient";
import { ButtonContainer, CareEventContainer } from "routes/admin-billing/views/AdminBillingView";
import DynamicMedicalNoteForm from "routes/patients/PatientProfile/components/PatientHeader/SignNotesTable/components/DynamicMedicalNoteForm";
import type { NoteFormCareEventProps } from "routes/patients/PatientProfile/components/PatientHeader/SignNotesTable/components/DynamicMedicalNoteForm/DynamicMedicalNoteForm";
import Popup from "shared/atoms/Popup";
import { MedicalNoteTypes } from "types";

import { HandledPatients, StatusIcon, UnhandledPatients } from "../components";

const PhysicianDashboard: React.VFC = () => {
  const client = useApiClient();
  const [show, setShow] = useState(false);
  const [careEvent, setCareEvent] = useState<NoteFormCareEventProps | null>(null);

  const postPhysicianMedicalReferral = usePostPhysicianMedicalReferral();
  const postPhysicianPlanOfCare = usePostPhysicianPlanOfCare();

  const onMedicalReferralClick = (row: PatientBase) => {
    if (row.medical_referral_id === null) {
      postPhysicianMedicalReferral.mutateAsync(
        { data: { patient_id: row.patient_id } },
        {
          onSuccess(data) {
            setCareEvent({ ...data, label: MedicalNoteTypes.PHYSICIAN_MEDICAL_REFERRAL, medical_record_note: null });
            setShow(true);
          },
        }
      );
    } else {
      client.getPhysicianMedicalReferral(row.medical_referral_id).then(mr => {
        setCareEvent({ ...mr, label: MedicalNoteTypes.PHYSICIAN_MEDICAL_REFERRAL, medical_record_note: null });
        setShow(true);
      });
    }
  };

  const onPlanOfCareClick = (row: PatientBase) => {
    if (row.plan_of_care_id === null) {
      postPhysicianPlanOfCare.mutateAsync(
        { data: { patient_id: row.patient_id } },
        {
          onSuccess(data) {
            client.getCareEvent(data.care_event_id).then(ce => {
              setCareEvent({ ...ce, ...data, label: MedicalNoteTypes.PHYSICIAN_PLAN_OF_CARE });
            });
            setShow(true);
          },
        }
      );
    } else {
      client.getPhysicianPlanOfCare(row.plan_of_care_id).then(poc => {
        client.getCareEvent(poc.care_event_id).then(ce => {
          setCareEvent({ ...ce, ...poc, label: MedicalNoteTypes.PHYSICIAN_PLAN_OF_CARE });
          setShow(true);
        });
      });
    }
  };

  const getColums = (type: "handled" | "unhandled"): GridColDef[] => {
    return [
      { field: "patient_id", headerName: "ID", flex: 1 },
      { field: "patient_full_name", headerName: "Name", flex: 1 },
      {
        field: "icd_codes",
        headerName: "ICD",
        valueFormatter: value => value?.[0],
        flex: 1,
      },
      ...(type === "handled"
        ? [
            {
              field: "referral_handled_at",
              headerName: "Referral Handled",
              valueFormatter: (value: string) => (value ? format(parseISO(value), "PP") : ""),
              flex: 1,
            },
            {
              field: "plan_of_care_handled_at",
              headerName: "POC Handled",
              valueFormatter: (value: string) => (value ? format(parseISO(value), "PP") : ""),
              flex: 1,
            },
          ]
        : [
            {
              field: "first_evaluation",
              headerName: "First Evaluation",
              valueFormatter: (value: string) => (value ? format(parseISO(value), "PP") : ""),
              flex: 1,
            },
          ]),
      {
        field: "medical_referral_status",
        type: "actions",
        headerName: "Referral",
        renderCell: ({ row }) => {
          return (
            <ButtonContainer
              onClick={() => {
                onMedicalReferralClick(row);
              }}
            >
              <StatusIcon status={row.medical_referral_status} />
            </ButtonContainer>
          );
        },
        disableColumnMenu: true,
        sortable: false,
        flex: 1,
      },
      {
        field: "plan_of_care_status",
        type: "actions",
        headerName: "Plan of Care",
        renderCell: ({ row }) => {
          return (
            <ButtonContainer
              onClick={() => {
                onPlanOfCareClick(row);
              }}
            >
              <StatusIcon status={row.plan_of_care_status} />
            </ButtonContainer>
          );
        },
        disableColumnMenu: true,
        sortable: false,
        flex: 1,
      },
    ];
  };

  const clearSelectedCareEvent = () => {
    setCareEvent(null);
  };

  return (
    <>
      <Container>
        <UnhandledPatients columns={getColums("unhandled")} />
        <HandledPatients columns={getColums("handled")} />
      </Container>

      {careEvent && show && (
        <Popup
          fullBgOpacity
          onClickOutside={() => {
            setShow(false);
            setCareEvent(null);
          }}
          showCloseButton
        >
          <CareEventContainer>
            <DynamicMedicalNoteForm
              careEvent={careEvent}
              animate
              open
              isPhysician
              skipMerge
              showNoteHeader
              readOnly={careEvent.status !== "pending"}
              clearSelectedCareEvent={clearSelectedCareEvent}
            />
          </CareEventContainer>
        </Popup>
      )}
    </>
  );
};
export default PhysicianDashboard;

const Container = styled.div`
  padding: 20px;
  margin-bottom: 20px;

  .unhandled-medical-referrals {
    margin-bottom: 40px;
  }
`;
