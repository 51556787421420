import { useQuery } from "react-query";

import useApiClient from "api/useApiClient";

export const getPartnerInvitedPatientsQueryKey = (page: number) => ["getPartnerInvitedPatients", page] as const;

const useGetPartnerInvitedPatients = (page: number) => {
  const client = useApiClient();

  return useQuery(getPartnerInvitedPatientsQueryKey(page), () => client.getPartnerInvitedPatients(page));
};

export default useGetPartnerInvitedPatients;
