import { z } from "zod";

import { MetadataSchema } from "../common";

const StatusSchema = z.enum(["accepted", "pending", "rejected"]);
export type Status = z.infer<typeof StatusSchema>;

const PatientBaseSchema = z.object({
  patient_id: z.number(),
  patient_full_name: z.string(),
  icd_codes: z.array(z.string()),
  medical_referral_id: z.number().nullable(),
  medical_referral_status: StatusSchema,
  plan_of_care_care_event_id: z.number().nullable(),
  plan_of_care_id: z.number().nullable(),
  plan_of_care_status: StatusSchema,
});
export type PatientBase = z.infer<typeof PatientBaseSchema>;

export const HandledPatientSchema = PatientBaseSchema.extend({
  referral_handled_at: z.string(),
  plan_of_care_handled_at: z.string(),
});

export const HandledPatientsResponseSchema = z.object({
  items: z.array(HandledPatientSchema),
  metadata: MetadataSchema,
});

export const HandledPatientResponseSchema = HandledPatientSchema;

export const UnhandledPatientSchema = PatientBaseSchema.extend({
  first_evaluation: z.string(),
});

export const UnhandledPatientsResponseSchema = z.object({
  items: z.array(UnhandledPatientSchema),
  metadata: MetadataSchema,
});

export const UnhandledPatientResponseSchema = UnhandledPatientSchema;

export const PhysicianMedicalReferralSchema = z.object({
  comment: z.string().nullable(),
  condition: z.string(),
  diagnosis: z.string(),
  icd_codes: z.array(z.string()),
  id: z.number(),
  handled_at: z.string().nullable(),
  npi: z.string(),
  occupational_therapy: z.boolean(),
  patient_birthday: z.string(),
  patient_id: z.number(),
  patient_name: z.string(),
  physician_id: z.number(),
  physician_name: z.string(),
  physical_therapy: z.boolean(),
  request_services: z.array(z.string()).nullable(),
  speciality_services: z.array(z.string()).nullable(),
  speech_therapy: z.boolean(),
  status: StatusSchema,
  treatment_duration: z.string(),
  treatment_frequency: z.string(),
  user_course_id: z.number(),
});
export type PhysicianMedicalReferral = z.infer<typeof PhysicianMedicalReferralSchema>;

export const PhysicianPlanOfCareSchema = z.object({
  care_event_id: z.number(),
  comment: z.string().nullable(),
  handled_at: z.string().nullable(),
  id: z.number(),
  npi: z.string(),
  patient_birthday: z.string(),
  patient_name: z.string(),
  physician_name: z.string(),
  status: StatusSchema,
});
export type PhysicianPlanOfCare = z.infer<typeof PhysicianPlanOfCareSchema>;

const PostArgsSchema = z.object({
  data: z.object({
    patient_id: z.number(),
  }),
});
export type PostArgs = z.infer<typeof PostArgsSchema>;

const PutPhysicianMedicalReferralArgsSchema = z.object({
  medicalReferralID: z.number(),
  data: PhysicianMedicalReferralSchema,
});
export type PutPhysicianMedicalReferralArgs = z.infer<typeof PutPhysicianMedicalReferralArgsSchema>;

const PutPhysicianPlanOfCareArgsSchema = z.object({
  planOfCareID: z.number(),
  data: PhysicianPlanOfCareSchema,
});
export type PutPhysicianPlanOfCareArgs = z.infer<typeof PutPhysicianPlanOfCareArgsSchema>;
