import { useQuery } from "react-query";

import type { PaginationArgs } from "api/schemas/common";
import useApiClient from "api/useApiClient";

export const queryKeyPrefix = "getPhysicianHandledPatients";

export const getPhysicianHandledPatientsQueryKey = (args: PaginationArgs) => [queryKeyPrefix, args.limit, args.page];

export const useGetPhysicianHandledPatients = (args: PaginationArgs) => {
  const client = useApiClient();

  return useQuery(getPhysicianHandledPatientsQueryKey(args), () => client.getPhysicianHandledPatients(args));
};
