import { useQuery } from "react-query";

import useApiClient from "api/useApiClient";

export const getPhysicianHandledPatientQueryKey = (patientID: number) => ["getPhysicianHandledPatient", patientID];

export const useGetPhysicianHandledPatient = (patientID: number) => {
  const client = useApiClient();

  return useQuery(getPhysicianHandledPatientQueryKey(patientID), () => client.getPhysicianHandledPatient(patientID));
};
