import { useMutation } from "react-query";

import type { PhysicianMedicalReferral, PostArgs } from "api/schemas/physician";
import useApiClient from "api/useApiClient";

export const usePostPhysicianMedicalReferral = () => {
  const client = useApiClient();

  return useMutation<PhysicianMedicalReferral, Error, PostArgs>(args => client.postPhysicianMedicalReferral(args));
};
