import type { UseMutationOptions, UseMutationResult } from "react-query";
import { useMutation, useQueryClient } from "react-query";
import invariant from "ts-invariant";

import type { GetPatientResponse } from "api/types/GetPatient";
import type { UseGetPatientFullDetailsQueryResponses } from "utils/contexts/providers/useGetPatientsFullDetailsQuery";
import { getPatientFullDetailsQueryKey } from "utils/contexts/providers/useGetPatientsFullDetailsQuery";

import type { PutPatientMarkAsPrioArgs, PutPatientMarkAsPrioResponse } from "../types/PutPatientMarkAsPrio";
import useApiClient from "../useApiClient";

export type UsePutPatientMarkAsPrioQueryArgs = {
  patientId?: number;
  therapistId?: number;
  therapistMarket?: string;
  previousTreatmentId?: number | null;
};

export type UsePutPatientMarkAsPrioQueryVariables = Omit<PutPatientMarkAsPrioArgs, "patientId">;

type UsePutPatientMarkAsPrioQueryContext = {
  queryData: UseGetPatientFullDetailsQueryResponses;
  queryKey: ReturnType<typeof getPatientFullDetailsQueryKey>;
} | null;

export const usePutPatientMarkAsPrioMutation = (
  args: UsePutPatientMarkAsPrioQueryArgs,
  options?: UseMutationOptions<PutPatientMarkAsPrioResponse, Error, UsePutPatientMarkAsPrioQueryVariables>
): UseMutationResult<PutPatientMarkAsPrioResponse, Error, UsePutPatientMarkAsPrioQueryVariables> => {
  const { patientId, therapistId, therapistMarket, previousTreatmentId } = args;

  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<PutPatientMarkAsPrioResponse, Error, UsePutPatientMarkAsPrioQueryVariables>(
    ({ data }) => {
      if (!patientId) {
        return Promise.reject(new Error("Invalid mutation arguments"));
      }
      return client.putPatientMarkAsPrio({ data, patientId });
    },
    {
      ...options,
      onMutate(variables) {
        if (patientId && therapistId && therapistMarket) {
          // Instead of just invalidating the getPatientFullDetailsQuery, we
          // mutate the React Query cache to avoid 4-7 requests.
          const queryKey = getPatientFullDetailsQueryKey({
            patientId,
            therapistId,
            therapistMarket,
            previousTreatmentId,
          });
          const queryData = queryClient.getQueryData(queryKey);

          queryClient.setQueryData<UseGetPatientFullDetailsQueryResponses>(queryKey, input => {
            invariant(input);
            return mutateGetPatientResponse(input, variables.data);
          });
          return { queryData, queryKey };
        }
        return null;
      },
      onError: (error, variables, context) => {
        const ctx = context as UsePutPatientMarkAsPrioQueryContext;

        if (ctx) {
          // Roll back
          queryClient.setQueryData(ctx.queryKey, ctx.queryData);
        }
      },
    }
  );
};

export const mutateGetPatientResponse = (
  responses: UseGetPatientFullDetailsQueryResponses,
  changes: Partial<GetPatientResponse>
): UseGetPatientFullDetailsQueryResponses => {
  const [
    getPatientHealthJournalResponse,
    getPatientWidgetsPainLocationResponse,
    getPatientResponse,
    getUsPatientInsuranceResponse,
    getPatientCurrentSubscriptionResponse,
    getPatientUsProviderGroupProfileResponse,
  ] = responses;

  return [
    getPatientHealthJournalResponse,
    getPatientWidgetsPainLocationResponse,
    { ...getPatientResponse, ...changes },
    getUsPatientInsuranceResponse,
    getPatientCurrentSubscriptionResponse,
    getPatientUsProviderGroupProfileResponse,
  ];
};
