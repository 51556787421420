import { z } from "zod";

import { MetadataSchema } from "../common";

const InvitedPatientSchema = z.object({
  id: z.number(),
  patient_name: z.string().nullable(),
  sent_at: z.string().nullable(),
  created_at: z.string().nullable(),
});

export const InvitedPatientsResponseSchema = z.object({
  invitations: z.array(InvitedPatientSchema),
  metadata: MetadataSchema,
});
