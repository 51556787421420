import { useTranslation } from "react-i18next";

import type { FallPrevention, FallSymptoms } from "api/schemas/patients/HealthJournal";

import { booleanToAnswer } from "../helpers";
import { exists, textToAnswer } from "../helpers/helpers";

import { Row } from "./helpers";

interface Props {
  fields: FallPrevention;
}

export const FallPreventionQuestions: React.VFC<Props> = ({ fields }) => {
  const { t } = useTranslation();
  const {
    fall_prevention_accident_or_fall_last_months,
    fall_prevention_assistive_device_advised,
    fall_prevention_fallen_past_year,
    fall_prevention_have_or_had_cancer,
    fall_prevention_injured_by_fall,
    fall_prevention_medicine_side_effects,
    fall_prevention_sudden_weight_loss,
    fall_prevention_symptoms_before_falling,
    fall_prevention_times_fallen_past_year,
    fall_prevention_unsteady_standing_or_walking,
    fall_prevention_using_assistive_device_during_fall,
    fall_prevention_worried_about_falling = true,
  } = fields;

  const getSymtoms = (symptoms: FallSymptoms): string => {
    if (symptoms && symptoms.length > 0) {
      return symptoms
        .map(symptom => t(`patient.ONBOARDING.FALL_PREVENTION.FALL_SYMPTOMS_ANSWERS.${symptom}`))
        .join(", ");
    }

    return "";
  };

  return (
    <>
      {exists(fall_prevention_worried_about_falling) && (
        <Row
          left={t("patient.ONBOARDING.FALL_PREVENTION.FALL_CONCERN")}
          right={booleanToAnswer(fall_prevention_worried_about_falling)}
          translated
        />
      )}
      {fall_prevention_unsteady_standing_or_walking && (
        <Row
          left={t("patient.ONBOARDING.FALL_PREVENTION.FALL_FEEL")}
          right={fall_prevention_unsteady_standing_or_walking}
        />
      )}
      {fall_prevention_accident_or_fall_last_months && (
        <Row
          left={t("patient.ONBOARDING.FALL_PREVENTION.FALL_PAIN_CAUSED_BY_ACCIDENT")}
          right={textToAnswer(fall_prevention_accident_or_fall_last_months)}
          translated
        />
      )}
      {exists(fall_prevention_fallen_past_year) && (
        <Row
          left={t("patient.ONBOARDING.FALL_PREVENTION.FALL_UNINTENTIONAL")}
          right={booleanToAnswer(fall_prevention_fallen_past_year)}
          translated
        />
      )}
      {exists(fall_prevention_times_fallen_past_year) && (
        <Row
          left={t("patient.ONBOARDING.FALL_PREVENTION.FALL_RATE")}
          right={`${fall_prevention_times_fallen_past_year} ${t("patient.ONBOARDING.FALL_PREVENTION.FALL_TIMES")}`}
          translated
        />
      )}
      {fall_prevention_symptoms_before_falling && (
        <Row
          left={t("patient.ONBOARDING.FALL_PREVENTION.FALL_SYMPTOMS")}
          right={getSymtoms(fall_prevention_symptoms_before_falling)}
          translated
        />
      )}
      {fall_prevention_injured_by_fall && (
        <Row
          left={t("patient.ONBOARDING.FALL_PREVENTION.FALL_INJURY")}
          right={textToAnswer(fall_prevention_injured_by_fall)}
          translated
        />
      )}
      {exists(fall_prevention_assistive_device_advised) && (
        <Row
          left={t("patient.ONBOARDING.FALL_PREVENTION.FALL_ASSISTIVE_DEVICE")}
          right={booleanToAnswer(fall_prevention_assistive_device_advised)}
          translated
        />
      )}
      {exists(fall_prevention_using_assistive_device_during_fall) && (
        <Row
          left={t("patient.ONBOARDING.FALL_PREVENTION.FALL_TIME_ASSISTIVE_DEVICE")}
          right={booleanToAnswer(fall_prevention_using_assistive_device_during_fall)}
          translated
        />
      )}
      {fall_prevention_medicine_side_effects && (
        <Row
          left={t("patient.ONBOARDING.FALL_PREVENTION.FALL_MEDICINE")}
          right={textToAnswer(fall_prevention_medicine_side_effects)}
          translated
        />
      )}
      {exists(fall_prevention_have_or_had_cancer) && (
        <Row
          left={t("patients.medical_records.have_had_cancer")}
          right={booleanToAnswer(fall_prevention_have_or_had_cancer)}
          translated
          highlight={Boolean(fall_prevention_have_or_had_cancer)}
        />
      )}
      {exists(fall_prevention_sudden_weight_loss) && (
        <Row
          left={t("patients.medical_records.acute.weight_loss")}
          right={booleanToAnswer(fall_prevention_sudden_weight_loss)}
          translated
          highlight={Boolean(fall_prevention_sudden_weight_loss)}
        />
      )}
    </>
  );
};
