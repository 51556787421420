import type React from "react";
import { useEffect } from "react";

import { useMatch, useParams } from "react-router-dom";

import { useFeatureToggleContext } from "contexts/FeatureToggleContext";
import { useProfileContext } from "contexts/ProfileContext";
import { AnalyticsPages, AnalyticsService } from "utils/analytics";
import { Feature } from "utils/features/types";
import { userIsPhysician } from "utils/profile/profileHelper";

import PhysicianList from "../PhysicianList";
import RecordNoteAndReferralList from "../RecordNoteAndReferralList";
import RecordNoteList from "../RecordNoteList";

import { Container, RecordContainer, RecordRow } from "./helpers";

const Journal: React.VFC = () => {
  const { hasFeature } = useFeatureToggleContext();
  const match = useMatch("*");
  const patientId = Number(useParams().patientId);
  const { profile } = useProfileContext();
  const isPhysician = userIsPhysician(profile);

  useEffect(() => {
    if (match) {
      AnalyticsService.viewedPage(AnalyticsPages.PATIENTS.INFORMATION.MEDICAL_RECORD, match.pathname);
    }
  }, [match?.pathname]);

  return (
    <Container data-testid="journal">
      {!isPhysician &&
        (hasFeature(Feature.REFERRALS) ? (
          <RecordRow>
            <RecordContainer>
              <RecordNoteAndReferralList />
            </RecordContainer>
          </RecordRow>
        ) : (
          <RecordRow>
            <RecordContainer>
              <RecordNoteList />
            </RecordContainer>
          </RecordRow>
        ))}
      {isPhysician && (
        <RecordRow>
          <RecordContainer>
            <PhysicianList patientId={patientId} />
          </RecordContainer>
        </RecordRow>
      )}
    </Container>
  );
};

export default Journal;
