import type React from "react";

import { Document, Page, Text, View } from "@react-pdf/renderer";
import { parseISO } from "date-fns";
import { useTranslation } from "react-i18next";

import type { CareEvent } from "api/models/CareEvent";
import type { GetPatientIcdCodesResponse } from "api/types/GetPatientIcdCodes";
import { getInitials } from "routes/videocall/components/DisabledPublicationsOverlay";

import { styles } from "./styles";

interface Props {
  availableICDCodes: GetPatientIcdCodesResponse;
  planOfCare: CareEvent;
}

export const PlanOfCarePDF: React.VFC<Props> = ({ availableICDCodes, planOfCare }) => {
  const { t } = useTranslation();

  const {
    comment,
    handled_at,
    health_care_professional_name,
    health_care_professional_type,
    icd_codes,
    npi,
    patient_birthday,
    patient_name,
    physician_name,
    structured_fields: {
      assessment,
      goal_free_text,
      goal_functionality,
      goal_pain,
      objective,
      plan_duration_days,
      subjective,
    },
  } = planOfCare;

  const filteredICDCodes = availableICDCodes.filter(item => icd_codes?.includes(item.code));

  return (
    <Document>
      <Page style={styles.page}>
        <Text style={styles.title}>{t("dashboard.tables.notes.types.PHYSICIAN_PLAN_OF_CARE")}</Text>

        <View style={styles.section}>
          <View style={[styles.row, styles.mb, { justifyContent: "space-between" }]}>
            <View style={styles.row}>
              <Text style={styles.label}>{t("patients.extended_medical_note.patient_name.label")}</Text>
              <Text>{patient_name}</Text>
            </View>

            <View style={styles.row}>
              <Text style={styles.label}>{t("patients.notes.fields.handled_at")}</Text>
              <Text>{handled_at ? parseISO(handled_at).toLocaleDateString("en-US", { timeZone: "UTC" }) : null}</Text>
            </View>
          </View>

          <View style={[styles.row, styles.mb]}>
            <Text style={styles.label}>{t("patients.extended_medical_note.patient_birthday.label")}</Text>
            <Text>{patient_birthday}</Text>
          </View>

          <View style={[styles.row, styles.mb]}>
            <Text style={styles.label}>{t("patients.notes.fields.health_care_professional_name")}</Text>
            <Text>{health_care_professional_name}</Text>
          </View>

          <View style={[styles.row, styles.mb]}>
            <Text style={styles.label}>{t("patients.notes.fields.health_care_professional_type")}</Text>
            <Text>{health_care_professional_type}</Text>
          </View>

          <View style={[styles.row, styles.mb]}>
            <Text style={styles.label}>{t("patients.notes.fields.physician_name")}</Text>
            <Text>{physician_name}</Text>
          </View>

          <View style={[styles.row, styles.mb]}>
            <Text style={styles.label}>{t("patients.notes.fields.npi")}</Text>
            <Text>{npi}</Text>
          </View>
        </View>

        <View style={styles.section}>
          <Text style={[styles.bold, styles.mb]}>{t("patients.notes.fields.subjective")}</Text>
          <Text>{subjective}</Text>
        </View>

        <View style={styles.section}>
          <Text style={[styles.bold, styles.mb]}>{t("patients.notes.fields.objective")}</Text>
          <Text>{objective}</Text>
        </View>

        <View style={styles.section}>
          <Text style={[styles.bold, styles.mb]}>{t("patients.notes.fields.assessment")}</Text>
          <Text>{assessment}</Text>
        </View>

        <View style={styles.section}>
          <Text style={[styles.bold, styles.mb]}>{t("patients.notes.fields.icd_codes")}</Text>
          {filteredICDCodes.map(icdCode => (
            <View key={icdCode.code} style={[styles.row, styles.mb]}>
              <Text style={styles.label}>{icdCode.code}</Text>
              <Text>{icdCode.description}</Text>
            </View>
          ))}
        </View>

        <View style={[styles.row, styles.mb]}>
          <Text style={styles.label}>{t("patients.notes.fields.goal_pain")}</Text>
          <Text>{goal_pain}</Text>
        </View>

        <View style={[styles.row, styles.mb]}>
          <Text style={styles.label}>{t("patients.notes.fields.goal_functionality")}</Text>
          <Text>{goal_functionality}</Text>
        </View>

        <View style={styles.section}>
          <Text style={[styles.bold, styles.mb]}>{t("patients.notes.fields.goal_free_text")}</Text>
          <Text>{goal_free_text}</Text>
        </View>

        <View style={styles.section}>
          <View style={[styles.row, styles.mb]}>
            <Text style={styles.label}>{t("patients.notes.fields.frequency")}</Text>
            <Text>{t("patients.extended_medical_note.frequency.value")}</Text>
          </View>

          <View style={[styles.row, styles.mb]}>
            <Text style={styles.label}>{t("patients.notes.fields.plan_duration_days")}</Text>
            <Text>{plan_duration_days}</Text>
          </View>
        </View>

        <View style={{ marginBottom: 40 }}>
          <Text style={[styles.bold, styles.mb]}>{t("patients.notes.fields.comment")}</Text>
          <Text>{comment}</Text>
        </View>

        <View wrap={false}>
          <View style={{ marginBottom: 40 }}>
            <Text>{physician_name}</Text>
            <View style={[styles.border, styles.mb, { width: 200, marginTop: 5 }]} />
            <Text>{t("physician.printed_name")}</Text>
          </View>

          <View>
            {physician_name && <Text>{getInitials(physician_name)}</Text>}
            <View style={[styles.border, styles.mb, { width: 200 }]} />
            <Text>{t("physician.signature")}</Text>
          </View>
        </View>

        <Text style={styles.pageNumber} render={({ pageNumber }) => `Page ${pageNumber}`} fixed />
      </Page>
    </Document>
  );
};
