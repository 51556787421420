import { z } from "zod";

const patient = {
  id: z.number(),
  name: z.string(),
  latest_note_at: z.string().nullable(),
  icd_codes: z.array(z.string().nullable()),
  sources: z.array(z.string().nullable()).nullable(),
  created_at: z.string(),
  adherence: z.number(),
  deleted_at: z.string().nullable(),
};

const metadata = {
  next: z.number().nullable(),
  page: z.number().nullable(),
  page_items: z.number(),
  prev: z.number().nullable(),
  total_count: z.number(),
};

const response = {
  patients: z.array(z.object(patient)),
  metadata: z.object(metadata),
};

const InitialPartnerPatientSchema = z.object(patient);

export const RawPartnerPatientSchema = z.object(response);

export type RawPartnerPatient = z.infer<typeof RawPartnerPatientSchema>;

export type PartnerPatient = z.infer<typeof InitialPartnerPatientSchema>;
