import type React from "react";
import { useContext, useEffect, useState } from "react";

import invariant from "ts-invariant";

import { useGetPatientNoteQuery } from "api/hooks/useGetPatientNote";
import { useProfileContext } from "contexts/ProfileContext";
import { CurrentPatientContext } from "utils/contexts";

import PatientEditStickyNote from "./PatientEditStickyNote";
import PatientViewStickyNote from "./PatientViewStickyNote";

const PatientStickyNote: React.VFC = () => {
  const { patient } = useContext(CurrentPatientContext);
  const { profile } = useProfileContext();
  invariant(profile, "Profile must be defined");
  const { data } = useGetPatientNoteQuery({ patientId: patient?.id, therapistId: profile?.id });
  const [stickyNoteMessage, setStickyNoteMessage] = useState(data?.note ?? "");
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setStickyNoteMessage(data?.note ?? "");
  }, [data]);

  return isEditing ? (
    <PatientEditStickyNote
      stickyNoteMessage={stickyNoteMessage}
      setStickyNoteMessage={setStickyNoteMessage}
      setIsEditing={setIsEditing}
    />
  ) : (
    <PatientViewStickyNote setIsEditing={setIsEditing} stickyNoteMessage={stickyNoteMessage} />
  );
};

export default PatientStickyNote;
