import type React from "react";

import { useTranslation } from "react-i18next";

import type { PrimaryJoint } from "api/schemas/Patients";
import type { Ailment } from "api/schemas/patients/HealthJournal";
import capitalize from "utils/string/capitalize";

type JointLabelProps = {
  ailment?: Ailment;
  joint: PrimaryJoint | undefined;
};

const JointLabel: React.VFC<JointLabelProps> = ({ ailment, joint }) => {
  const { t } = useTranslation();

  if (!joint) {
    return null;
  }

  if (ailment === "fall_prevention") {
    return <>{t("patients.ailments.fall_prevention")}</>;
  }

  if (ailment === "osteoporosis") {
    return <>{t("patients.ailments.osteoporosis")}</>;
  }

  const { lateral_location, location } = joint;

  if (lateral_location === "both" && location !== "neck" && location !== "spine") {
    return (
      <>
        {capitalize(
          t("patients.pain_construct", {
            lateral: t(`patients.lateral_location.${lateral_location}`),
            // FIXME: type translation
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            location: t(`patients.pain_location.${location}.1`),
          })
        )}
      </>
    );
  }

  if (lateral_location === "undefined") {
    // FIXME: type translation
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <>{t(`patients.pain_location.${location}.0`)}</>;
  }

  return (
    <>
      {capitalize(
        t("patients.pain_construct", {
          // FIXME: type translation
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          lateral: t(`patients.lateral_location.${lateral_location}`),
          // FIXME: type translation
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          location: t(`patients.pain_location.${location}.0`),
        })
      )}
    </>
  );
};

export default JointLabel;
