import { useEffect, useMemo, useState } from "react";

import { type GridColDef } from "@mui/x-data-grid";
import { format, parseISO } from "date-fns";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import invariant from "ts-invariant";

import useGetPartnerPatientList from "api/hooks/useGetPartnerPatientList";
import { SearchIcon } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import { BaseButton } from "shared/atoms/BaseButton";
import { MUIDataGrid } from "shared/molecules/MUIDataGrid";
import { AnalyticsEvents, AnalyticsService } from "utils/analytics";

import { HeaderContainer, StyledSearch, Title, Wrapper } from "../helpers";

const paginationModel = { page: 0, pageSize: 20 };

export const PatientList: React.VFC = () => {
  const { register, setValue, getValues } = useForm();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { profile } = useProfileContext();
  invariant(profile);

  const [queryParams, setQueryParams] = useState(`?page[limit]=${paginationModel.pageSize}`);
  const { data, isFetching, isLoading } = useGetPartnerPatientList(queryParams);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(paginationModel.page);
  const patients = data?.patients || [];
  const metadata = data?.metadata;

  useEffect(() => {
    let queryString = `?page[limit]=${paginationModel.pageSize}`;

    if (searchValue !== "") {
      queryString += `&search[name]=${searchValue}`;
    }

    queryString += `&page[page]=${page + 1}`;
    setQueryParams(queryString);
  }, [searchValue, page]);

  const columns = useMemo((): GridColDef[] => {
    return [
      {
        field: "id",
        headerName: t("patients.id"),
        flex: 1,
      },
      {
        field: "name",
        headerName: t("patients.name"),
        flex: 1,
      },
      {
        field: "created_at",
        headerName: t("patients.header_info.joined_date"),
        valueFormatter: (value: string) => (value ? format(parseISO(value), "PP") : ""),
        flex: 1,
      },
      {
        field: "latest_note_at",
        headerName: t("partner_patients.latest_note"),
        valueFormatter: (value: string) => (value ? format(parseISO(value), "PP") : ""),
        flex: 1,
      },
      { field: "sources", headerName: t("partner_patients.clinic"), flex: 1 },
      { field: "icd_codes", headerName: t("partner_patients.icd_code"), valueFormatter: value => value?.[0], flex: 1 },
      {
        field: "adherence",
        headerName: t("partner_patients.adherence"),
        renderCell: ({ row }) => <DisplayAdherence adherence={row.adherence}>{row.adherence}%</DisplayAdherence>,
        flex: 1,
      },
    ];
  }, []);

  const renderTableSearch = () => {
    return (
      <SearchContainer>
        <StyledSearch
          data-testid="search-input-patients"
          reset={() => setValue("patientSearch", "")}
          icon={SearchIcon}
          {...register("patientSearch")}
          placeholder="Search by name"
        />
        <BaseButton
          text={t("patients.search")}
          onClick={() => {
            if (searchValue !== getValues("patientSearch")) {
              setPage(paginationModel.page);
            }
            setSearchValue(getValues("patientSearch"));
          }}
          style={{ margin: "0 0 0 20px" }}
          uppercase
        />
      </SearchContainer>
    );
  };

  return (
    <Container>
      <HeaderContainer>
        <Title>{t("partner_enrolled_patients_table.header")}</Title>
      </HeaderContainer>
      {renderTableSearch()}
      <Wrapper>
        <MUIDataGrid
          columns={columns}
          rows={patients}
          onCellClick={({ row }) => {
            navigate(`/patients/${row.id}`, { state: { pathname, text: "dashboard" } });
            AnalyticsService.track(AnalyticsEvents.CLINIC_PARTNER.PATIENT_PROFILE_VIEWED);
          }}
          loading={isFetching || isLoading}
          initialState={{ pagination: { paginationModel } }}
          paginationMode="server"
          onPaginationModelChange={model => setPage(model.page)}
          rowCount={metadata?.total_count}
          rowSelection={false}
        />
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 40px;
`;

const DisplayNumber = styled.div`
  margin: auto;
  color: ${props => props.theme.colors.greys.dark};
  font-weight: ${props => props.theme.fontWeight.regular};
`;

const DisplayAdherence = styled(DisplayNumber)<{ adherence: number }>`
  color: ${props => props.adherence < 60 && props.theme.colors.orange};
`;

const SearchContainer = styled.div`
  display: flex;
  margin-bottom: 16px;

  & > button {
    flex: 0;
  }

  ${props => props.theme.belowBreakpoint} {
    justify-content: center;
  }
`;
