import { useQuery } from "react-query";

import type { PaginationArgs } from "api/schemas/common";
import useApiClient from "api/useApiClient";

export const queryKeyPrefix = "getPhysicianUnhandledPatients";

export const getPhysicianUnhandledPatientsQueryKey = (args: PaginationArgs) => [queryKeyPrefix, args.limit, args.page];

export const useGetPhysicianUnhandledPatients = (args: PaginationArgs) => {
  const client = useApiClient();

  return useQuery(getPhysicianUnhandledPatientsQueryKey(args), () => client.getPhysicianUnhandledPatients(args));
};
