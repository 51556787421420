export const ICDCodeType = {
  ALL: "ALL",
  HEAD: "head",
  NECK: "neck",
  SHOULDER: "shoulder",
  ELBOW: "elbow",
  HAND: "hand",
  BACK: "back",
  PELVIS: "pelvis",
  HIP: "hip",
  KNEE: "knee",
  FOOT: "foot",
  EXCLUSION: "exclusion",
  OSTEOPOROSIS: "osteoporosis",
} as const;
export type ICDCodeType = typeof ICDCodeType[keyof typeof ICDCodeType];

interface KeywordType {
  [key: string]: readonly string[];
}

export const ICDCodeTypeKeywordsSE: KeywordType = {
  HIP: ["höft"],
  KNEE: ["knä"],
  HAND: ["hand", "finger", "fingrar"],
  BACK: ["rygg"],
  SHOULDER: ["axel", "skuldra"],
  NECK: ["nacke"],
  PELVIS: ["bäcken"],
  FOOT: ["fot", "tår"],
  HEAD: ["huvud", "käke"],
  ELBOW: ["armbåge"],
  EXCLUSION: ["behandling avslutas"],
  GENERAL: ["reaktiv artrit, ospecificerad"],
} as const;

export const ICDCodeCategory = {
  ALL: "ALL",
  A00_B99: "A00-B99",
  C00_D48: "C00-D48",
  E00_E90: "E00-E90",
  F00_F99: "F00-F99",
  G00_G99: "G00-G99",
  H60_H95: "H60-H95",
  I00_I99: "I00-I99",
  J00_J99: "J00-J99",
  K00_K93: "K00-K93",
  L00_L99: "L00-L99",
  M00_M99: "M00-M99",
  N00_N99: "N00-N99",
  O00_O99: "O00-O99",
  R00_R99: "R00-R99",
  S00_T98: "S00-T98",
  Z00_Z99: "Z00-Z99",
} as const;
export type ICDCodeCategory = typeof ICDCodeCategory[keyof typeof ICDCodeCategory];

type IcdCodeSE = "M16.1" | "M17.1" | "M19.0D" | "M25.5B" | "M54.2" | "M54.5" | "R52.9";

type IcdCodeUS =
  | "M16.0"
  | "M16.10"
  | "M16.11"
  | "M16.12"
  | "M17.0"
  | "M17.10"
  | "M17.11"
  | "M17.12"
  | "M54.50"
  | "R52.9"
  | "M25.511"
  | "M25.512"
  | "M25.519";

export type ICDCode = IcdCodeSE | IcdCodeUS;

export interface ICDCodeResponse {
  category: string;
  code: string;
  description: string;
  parent_code: string;
  type: ICDCodeType;
}
