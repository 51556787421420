import type { UseQueryOptions } from "react-query";
import { useQuery } from "react-query";

import type { PhysicianMedicalReferral } from "api/schemas/physician";
import useApiClient from "api/useApiClient";

type QueryKey = ["getManagerMedicalReferral", number];
export const getManagerMedicalReferralQueryKey = (medicalReferralID: number): QueryKey => [
  "getManagerMedicalReferral",
  medicalReferralID,
];

export const useGetManagerMedicalReferral = (
  medicalReferralID: number,
  options?: UseQueryOptions<PhysicianMedicalReferral, Error, PhysicianMedicalReferral, QueryKey>
) => {
  const client = useApiClient();

  return useQuery(
    getManagerMedicalReferralQueryKey(medicalReferralID),
    () => client.getManagerMedicalReferral(medicalReferralID),
    options
  );
};
