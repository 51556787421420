import type { UseQueryOptions, UseQueryResult } from "react-query";
import { useQuery } from "react-query";

import type { GetPatientArgs, GetPatientResponse } from "api/types/GetPatient";
import useApiClient from "api/useApiClient";

export type UseGetPatientQueryArgs = GetPatientArgs;

type QueryKey = ["useGetPatientQuery", UseGetPatientQueryArgs];

export const getPatientQueryKey = (args: UseGetPatientQueryArgs): QueryKey => ["useGetPatientQuery", args];

export const useGetPatientQuery = (
  args: UseGetPatientQueryArgs,
  options?: UseQueryOptions<GetPatientResponse, Error, GetPatientResponse, QueryKey>
): UseQueryResult<GetPatientResponse, Error> => {
  const client = useApiClient();

  return useQuery(getPatientQueryKey(args), () => client.getPatient(args), options);
};
