import type React from "react";
import { useRef } from "react";

import type { FieldError } from "react-hook-form";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { Payer } from "api/models/CareEvent";
import { PayerTypes } from "api/models/CareEvent";
import type { Ailment } from "api/schemas/patients/HealthJournal";
import { useProfileContext } from "contexts/ProfileContext";
import {
  MUICheckBox,
  MUIDropdown,
  MUIMaskedInput,
  MUIMultiselectDropdown,
  MUIRadioGroup,
  MUITextInput,
} from "shared/atoms/inputs";
import { Notification } from "shared/atoms/Notification";
import Textbox from "shared/atoms/Textbox";
import type { MedicalNoteFieldType, MedicalNoteType } from "types";
import { MedicalNoteFieldTypes, MedicalNoteTypes } from "types";
import useTranslationMacro from "utils/hooks/useTranslationMacro";
import { getFieldLabelKey, getFieldOptionKey } from "utils/medicalNotes";
import { getPresentPlaceholders } from "utils/medicalNotes/placeholderValidation";
import { userIsPartner } from "utils/profile/profileHelper";

import { mandatoryOaDiagnosisCodes } from "../../PatientHeader/SignNotesTable/utils/ICDCodes";

import { CPTAutocomplete } from "./CPTAutocomplete";
import { PreviousSurveysDropdown } from "./PreviousSurveysDropdown";

import {
  CommunicationTypeDropdown,
  ControllerResponderCheckbox,
  ICDSelect,
  KVAAutocomplete,
  RangeDropdown,
  TextInputQuickText,
} from ".";

interface Props {
  readonly name: string;
  readonly options?: string[];
  readonly readonly?: boolean;
  readonly required?: boolean;
  readonly isNoteSigned?: boolean;
  readonly forceLabel?: boolean;
  readonly type?: MedicalNoteFieldType;
  readonly note_type?: MedicalNoteType;
  readonly payer?: Payer;
  readonly signedLimitReached?: boolean;
  readonly patientId: number;
  readonly ailment?: Ailment;
  readonly painLocation?: string | null;
  readonly onChange?: () => void;
}

let tickedDischargeUponSigning = false;

const DynamicField: React.VFC<Props> = ({
  name,
  type,
  readonly,
  required = true,
  options,
  onChange,
  payer,
  note_type,
  isNoteSigned,
  forceLabel,
  signedLimitReached,
  patientId,
  ailment,
  painLocation,
}) => {
  const { formState, getValues, setValue } = useFormContext();
  const { t } = useTranslation();
  const { errors } = formState;
  const { profile } = useProfileContext();
  const isPartner = userIsPartner(profile);

  const path = name.split(".");
  if (path.length > 2) throw new Error(`Invalid field name path length in ${path}`);
  if (name === "confirmed_insurance_and_identity") path[0] = "confirmed_identity";
  const error: FieldError | undefined = path.length > 1 ? errors[path[0]]?.[path[1]] : errors[path[0]];
  const errorType = error?.type;
  // FIXME: type translation
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const label: string = t(getFieldLabelKey(forceLabel ? `${name}_forced` : name));
  // FIXME: type translation
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const trimmedLabel = t(getFieldLabelKey(name.replace("_v2", "")));
  const translateError = useTranslationMacro(`form.validation.${errorType}`);
  const isFrosjo = payer?.payer_type === PayerTypes.FROSJO;
  const isVGR = payer?.payer_type === PayerTypes.VGR;
  const isStockholm = payer?.payer_type === PayerTypes.STOCKHOLM;

  // CHECKBOX_CONFIRMED_DIAGNOSIS to have a different label and PREVIOUS_SURVEYS_INPUT to show extra dropdown menus
  // For legacy Frösjö notes the default label is still displayed
  const fieldWhen = getValues("structured_fields.previous_surveys_when"); // the "when" field is used as a reference for legacy notes
  const hasDefaultLabel = !isFrosjo || (isNoteSigned && (fieldWhen === null || fieldWhen === undefined));
  const savedDischargeUponSigning = useRef(getValues("structured_fields.discharge_upon_signing"));

  switch (type) {
    case MedicalNoteFieldTypes.ICD_DROPDOWN: {
      const hideField = note_type?.toLocaleUpperCase() !== MedicalNoteTypes.PROTOCOL_WEEKLY_W0 && isFrosjo;

      if (hideField) return null;

      return (
        <ICDSelect
          error={error && translateError()}
          label={label}
          name={name}
          disabled={readonly}
          required={required}
          onChange={onChange}
          icdFiltersEnabled={profile?.market === "SE" && !isNoteSigned && !isFrosjo}
          patientId={patientId}
        />
      );
    }

    case MedicalNoteFieldTypes.DROPDOWN: {
      if (!options) throw new Error(`Missing options for dropdown ${name}`);
      return (
        <MUIDropdown
          error={error && translateError()}
          label={label}
          name={name}
          disabled={readonly}
          required={required}
          // FIXME: type translation
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          options={options.map(option => ({ label: t(getFieldOptionKey(name, option)) as string, value: option }))}
          onChange={onChange}
        />
      );
    }

    case MedicalNoteFieldTypes.MULTISELECT_DROPDOWN: {
      if (!options) throw new Error(`Missing options for multiselect dropdown ${name}`);
      return (
        <MUIMultiselectDropdown
          error={error && translateError()}
          label={label}
          name={name}
          disabled={readonly}
          required={required}
          options={options}
          onChange={onChange}
        />
      );
    }

    case MedicalNoteFieldTypes.RADIO: {
      if (!options) throw new Error(`Missing options for radio group ${name}`);
      // FIXME: type translation
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const radioOptions = options.map(option => ({ label: t(getFieldOptionKey(name, option)), value: option }));
      return (
        <MUIRadioGroup
          error={error && translateError()}
          label={label}
          name={name}
          // FIXME: type translation
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          options={radioOptions}
          disabled={readonly}
          required={required}
          onChange={onChange}
        />
      );
    }

    case MedicalNoteFieldTypes.PAIN_DROPDOWN: {
      const showField = ailment === "joint_pain";

      if (showField) {
        return (
          <RangeDropdown
            error={error && translateError()}
            label={label}
            name={name}
            disabled={readonly}
            required={required}
            onChange={onChange}
          />
        );
      }
      return null;
    }

    case MedicalNoteFieldTypes.FEELING_INPUT: {
      const showField = ailment === "fall_prevention" || ailment === "osteoporosis";

      const min = 0;
      const max = 100;

      if (showField) {
        return (
          <MUITextInput
            error={error && translateError({ min, max })}
            label={label}
            name={name}
            type="number"
            min={min}
            max={max}
            disabled={readonly}
            required={required}
            onUpdate={onChange}
          />
        );
      }
      return null;
    }

    case MedicalNoteFieldTypes.BMI: {
      const min = 10;
      const max = 100;

      return (
        <MUITextInput
          error={error && translateError({ min, max })}
          label={label}
          name={name}
          type="number"
          min={required ? min : undefined}
          max={max}
          step={0.1}
          disabled={readonly}
          required={required}
          onUpdate={onChange}
        />
      );
    }

    case MedicalNoteFieldTypes.FUNCTIONALITY_INPUT: {
      const min = 0;
      const max = 35;

      return (
        <MUITextInput
          error={error && translateError({ min, max })}
          label={label}
          name={name}
          type="number"
          min={min}
          max={max}
          disabled={readonly}
          required={required}
          onUpdate={onChange}
        />
      );
    }

    case MedicalNoteFieldTypes.FREQUENCY_READONLY:
      return (
        <MUITextInput
          disabled
          unregistered
          label={label}
          name={name}
          // FIXME: type translation
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          value={t("patients.extended_medical_note.frequency.value") as string}
          onUpdate={onChange}
        />
      );

    case MedicalNoteFieldTypes.DAYS_INPUT:
      return (
        <MUIMaskedInput
          error={error && translateError()}
          label={label}
          name={name}
          disabled={readonly}
          suffix={` ${t("common.days")}`}
          required={required}
          onBlur={onChange}
        />
      );

    case MedicalNoteFieldTypes.COMMUNICATION_TYPE_DROPDOWN: {
      // The field is hidden if:
      // the note type is not PROTOCOL_DISCHARGE or DISCHARGE
      // and the VGR flag is missing
      const showField =
        isVGR ||
        isStockholm ||
        note_type?.toLocaleUpperCase() === MedicalNoteTypes.DISCHARGE ||
        note_type?.toLocaleUpperCase() === MedicalNoteTypes.PROTOCOL_DISCHARGE;

      if (showField) {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <CommunicationTypeDropdown
              name={name}
              label={label}
              disabled={readonly}
              error={error && translateError()}
              required={required}
              onChange={onChange}
              isVGR={isVGR}
              note_type={note_type}
            />
            {!isNoteSigned && signedLimitReached && (
              <Notification variant="warning" style={{ marginTop: "16px" }}>
                {t("patients.notes.signed_limit_reached")}
              </Notification>
            )}
          </div>
        );
      }
      return null;
    }

    case MedicalNoteFieldTypes.CHECKBOX:
      return (
        <MUICheckBox
          error={error && translateError()}
          label={label}
          name={name}
          disabled={readonly}
          required={required}
          onChange={onChange}
        />
      );

    case MedicalNoteFieldTypes.CHECKBOX_CONFIRMED_DIAGNOSIS: {
      // The checkbox should be mandatory if OA icd code is selected
      const forcedRequired = () => {
        if (getValues("icd_codes")) {
          const isPublicRegionPayer = isFrosjo || isVGR || isStockholm;
          const isHipOrKnee = painLocation === "hip" || painLocation === "knee";
          const hasSpecificICDCode = getValues("icd_codes").some((icdCode: string) =>
            mandatoryOaDiagnosisCodes.includes(icdCode)
          );

          return isPublicRegionPayer && isHipOrKnee && hasSpecificICDCode;
        }
        return false;
      };

      return (
        <MUICheckBox
          error={error && translateError()}
          label={hasDefaultLabel ? label : t("patients.notes.fields.confirmed_diagnosis_frosjo")}
          name={name}
          disabled={readonly}
          required={forcedRequired()}
          onChange={onChange}
        />
      );
    }

    case MedicalNoteFieldTypes.CONFIRMED_INSURANCE_AND_IDENTITY_CHECKBOX: {
      const controllerName = "confirmed_identity";
      const responderName = "confirmed_insurance";

      return (
        <ControllerResponderCheckbox
          error={error && translateError()}
          label={label}
          controller={controllerName}
          responder={responderName}
          disabled={readonly}
          required={required}
          onChange={onChange}
        />
      );
    }

    case MedicalNoteFieldTypes.CHECKBOX_CONFIRMED_DATE:
      // the checkbox is not displayed in the records
      if (isNoteSigned) {
        return null;
      }
      return (
        <MUICheckBox
          error={error && translateError()}
          label={label}
          name={name}
          disabled={readonly}
          required={required}
          onChange={onChange}
        />
      );

    case MedicalNoteFieldTypes.KVA_CODES_MULTISELECT_DROPDOWN: {
      // VGR only allow 6 KVÅ codes per note
      const max = isVGR ? 6 : undefined;

      return (
        <KVAAutocomplete
          error={error && translateError({ max })}
          label={label}
          name={name}
          max={max}
          disabled={readonly}
          required={required}
          payer={payer}
          onChange={onChange}
        />
      );
    }

    case MedicalNoteFieldTypes.CHAPERONE_TEXT: {
      return getValues("confirmed_chaperone") ? (
        <MUITextInput
          error={error && translateError()}
          label={label}
          name={name}
          disabled={readonly}
          required={required}
          onUpdate={onChange}
        />
      ) : null;
    }

    case MedicalNoteFieldTypes.PREVIOUS_SURVEYS_TEXT: {
      return (
        <MUITextInput
          error={error && translateError({ placeholder: getPresentPlaceholders(getValues(name)) })}
          label={label}
          name={name}
          disabled={readonly}
          multiline
          required={required}
          onUpdate={onChange}
        />
      );
    }

    case MedicalNoteFieldTypes.PREVIOUS_SURVEYS_DROPDOWN: {
      return !hasDefaultLabel ? (
        <PreviousSurveysDropdown
          error={error && translateError()}
          label={label}
          name={name}
          disabled={readonly}
          required={required}
          onChange={onChange}
          locale={profile?.locale}
        />
      ) : null;
    }

    case MedicalNoteFieldTypes.PREVIOUS_SURVEYS_MULTISELECT: {
      if (!options) throw new Error(`Missing options for multiselect dropdown ${name}`);

      return !hasDefaultLabel ? (
        <MUIMultiselectDropdown
          error={error && translateError()}
          label={label}
          name={name}
          disabled={readonly}
          required={required}
          options={options}
          onChange={onChange}
        />
      ) : null;
    }

    case MedicalNoteFieldTypes.PREVIOUS_SURVEYS_QUICK_TEXT: {
      return !hasDefaultLabel ? (
        <TextInputQuickText
          error={error && translateError()}
          // FIXME: type translation
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          label={trimmedLabel}
          name={name}
          disabled={readonly}
          required={required}
          onUpdate={onChange}
          isNoteSigned={isNoteSigned}
        />
      ) : null;
    }

    case MedicalNoteFieldTypes.QUICK_TEXT: {
      return (
        <TextInputQuickText
          error={error && translateError()}
          // FIXME: type translation
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          label={trimmedLabel}
          name={name}
          disabled={readonly}
          required={required}
          onUpdate={onChange}
          isNoteSigned={isNoteSigned}
        />
      );
    }

    case MedicalNoteFieldTypes.UK_PLAN_TEXT: {
      const showIndicator = getValues("structured_fields.discharge_upon_signing");

      if (showIndicator && !tickedDischargeUponSigning && !savedDischargeUponSigning.current && !readonly) {
        tickedDischargeUponSigning = true;
        setValue("structured_fields.plan", "");
      }

      return (
        <UKPlanContainer>
          {showIndicator && <>{t("patients.notes.fields.discharge_upon_signing_helptext")}</>}
          <MUITextInput
            error={error && translateError({ placeholder: getPresentPlaceholders(getValues(name)) })}
            label={label}
            name={name}
            disabled={readonly}
            multiline
            required={required}
            onUpdate={onChange}
          />
        </UKPlanContainer>
      );
    }

    case MedicalNoteFieldTypes.DISCHARGE_FURTHER_ACTION: {
      const urgentDischarge = getValues("structured_fields.discharge_urgency") === "urgent";

      if (urgentDischarge) {
        setValue("structured_fields.discharge_further_action", true);
      }

      return (
        <MUICheckBox
          error={error && translateError()}
          label={label}
          name={name}
          disabled={urgentDischarge || readonly}
          required={required}
          onChange={onChange}
        />
      );
    }

    case MedicalNoteFieldTypes.CPT_MULTISELECT_DROPDOWN: {
      if (!options) throw new Error(`Missing options for multiselect dropdown ${name}`);
      return (
        <CPTAutocomplete
          error={error && translateError()}
          label={label}
          name={name}
          disabled={readonly}
          required={required}
          options={options}
          onChange={onChange}
        />
      );
    }

    default:
      return isPartner ? (
        <Textbox label={label} name={name} />
      ) : (
        <MUITextInput
          error={error && translateError({ placeholder: getPresentPlaceholders(getValues(name)) })}
          label={label}
          name={name}
          disabled={readonly}
          multiline
          required={required}
          onUpdate={onChange}
        />
      );
  }
};

export { DynamicField };

const UKPlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
