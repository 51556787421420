import type React from "react";
import { useCallback } from "react";

import { useVideoCallContext } from "contexts/VideoCallContext";

import VideoCall from "./views/VideoCall";

const VideoCallRoute: React.VFC = () => {
  const { callData, setCallData, minimised, setMinimised } = useVideoCallContext();

  const endCall = useCallback(() => {
    setCallData(null);
    setMinimised(false);
  }, [setCallData, setMinimised]);

  return (
    <>
      {callData && (
        <VideoCall callData={callData} minimised={minimised} setMinimised={setMinimised} onCallEnded={endCall} />
      )}
    </>
  );
};

export * from "./types";

export default VideoCallRoute;
