import styled from "styled-components";

import Search from "shared/molecules/Search";

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: ${props => props.theme.spacing.S_20} 0;
  ${props => props.theme.belowBreakpoint} {
    display: block;
    text-align: center;
  }
`;

const StyledSearch = styled(Search)`
  flex-grow: 2;
  ${props => props.theme.belowBreakpoint} {
    width: 100%;
  }
`;

const Title = styled.span`
  ${props => props.theme.font.header2};
  color: ${props => props.theme.colors.primary.base};
  flex-grow: 1;
  margin: auto;
`;

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
`;

export { HeaderContainer, StyledSearch, Title, Wrapper };
