import { useEffect, useState } from "react";
import type React from "react";

import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { usePostDismissAppointmentSuggestion } from "api/hooks/usePostDismissAppointmentSuggestion";
import { AppointmentToSchedule } from "api/models/Patient";
import type { CeStructureType } from "api/models/PatientProfile";
import { CrossIcon } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import { BaseButton } from "shared/atoms/BaseButton";
import { PrimaryButton, TextButton } from "shared/atoms/Button";
import Hidden from "shared/atoms/Hidden";
import ConfirmPopup from "shared/molecules/ConfirmPopup/ConfirmPopup";
import { AnalyticsEvents, AnalyticsService } from "utils/analytics";
import { getPatientFullDetailsQueryKey } from "utils/contexts/providers/useGetPatientsFullDetailsQuery";

interface Props {
  patientId: number;
  appointmentToSchedule: AppointmentToSchedule;
  ceStructure: CeStructureType;
  isPremiumSignedUp?: boolean;
  isStockholm?: boolean;
  isVGR?: boolean;
}

const ScheduleCallBox: React.VFC<Props> = ({
  patientId,
  appointmentToSchedule,
  ceStructure,
  isPremiumSignedUp = false,
  isStockholm = false,
  isVGR = false,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { profile } = useProfileContext();
  const queryClient = useQueryClient();

  const isAdherence = appointmentToSchedule === AppointmentToSchedule.ADHERENCE;
  // the notification is orange instead of blue
  const isWarning = isAdherence;

  const getPurpose = () => {
    if (appointmentToSchedule === AppointmentToSchedule.FOLLOW_UP_VIDEO_CALL) {
      return AppointmentToSchedule.FOLLOW_UP;
    }
    if (
      appointmentToSchedule === AppointmentToSchedule.ADHERENCE ||
      appointmentToSchedule === AppointmentToSchedule.FOLLOW_UP_CAP_RESET ||
      appointmentToSchedule === AppointmentToSchedule.FOLLOW_UP_GUIDED_CARE ||
      appointmentToSchedule === AppointmentToSchedule.FOLLOW_UP_RESUME_TREATMENT
    ) {
      return AppointmentToSchedule.GENERAL;
    }

    return appointmentToSchedule;
  };

  const getMedium = () => {
    const videoURLstring = "&meeting_medium=video";

    if ((isStockholm || isVGR) && (AppointmentToSchedule.FOLLOW_UP || AppointmentToSchedule.KICK_OFF)) {
      return videoURLstring;
    }
    if (AppointmentToSchedule.FOLLOW_UP_VIDEO_CALL) {
      return videoURLstring;
    }
    return "";
  };

  const isAppointmentDismissible =
    appointmentToSchedule === AppointmentToSchedule.ADHERENCE ||
    appointmentToSchedule === AppointmentToSchedule.DISCHARGE_CALL ||
    ((isStockholm || isVGR) && appointmentToSchedule === AppointmentToSchedule.FOLLOW_UP) ||
    appointmentToSchedule === AppointmentToSchedule.FOLLOW_UP_CAP_RESET ||
    appointmentToSchedule === AppointmentToSchedule.FOLLOW_UP_GUIDED_CARE ||
    appointmentToSchedule === AppointmentToSchedule.FOLLOW_UP_RESUME_TREATMENT ||
    appointmentToSchedule === AppointmentToSchedule.FOLLOW_UP_VIDEO_CALL ||
    appointmentToSchedule === AppointmentToSchedule.TREATMENT_SWITCHED;

  // Description is displayed for dismissible appointment
  const showDescription = isAppointmentDismissible;

  const [showDismiss, setShowDismiss] = useState(isAppointmentDismissible);
  const [confirmOpen, setConfirm] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const postDismissAppointmentSuggestion = usePostDismissAppointmentSuggestion();
  const initiateDismissAppointment = async () => {
    await postDismissAppointmentSuggestion.mutate(
      {
        patientId,
        data: {
          suggestion_type: appointmentToSchedule,
        },
      },
      {
        onSuccess() {
          // invalidate patient query
          if (profile) {
            const queryKey = getPatientFullDetailsQueryKey({
              patientId,
              therapistId: profile.id,
              therapistMarket: profile.market ?? "",
            });
            queryClient.invalidateQueries(queryKey);
          }
          setConfirm(false);
          setShowDismiss(isAppointmentDismissible);
        },
        onError() {
          setShowDismiss(true);
        },
      }
    );
  };

  useEffect(() => {
    if (isAdherence) {
      trackCheckInAction("Notification shown");
    }
  }, [isAdherence]);

  const trackCheckInAction = (action: string): void => {
    AnalyticsService.track(AnalyticsEvents.PATIENTS.CHECK_IN_CALL, {
      therapistId: profile?.id,
      patientId,
      action,
    });
  };

  // FE hack to pass a different appointment for reset patients that were never in Premium
  // Rendering different translations for FOLLOW_UP_RESUME_TREATMENT and FOLLOW_UP_CAP_RESET appointments
  const appointmentToScheduleCustomized =
    appointmentToSchedule === AppointmentToSchedule.FOLLOW_UP_CAP_RESET && !isPremiumSignedUp
      ? AppointmentToSchedule.FOLLOW_UP_RESUME_TREATMENT
      : appointmentToSchedule;

  const showDescriptionText = () => {
    const description =
      appointmentToScheduleCustomized === AppointmentToSchedule.FOLLOW_UP_VIDEO_CALL && ceStructure === "rtm"
        ? t("messages.appointments.description.FOLLOW_UP_VIDEO_CALL_RTM")
        : // FIXME: type translation
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          t(`messages.appointments.description.${appointmentToScheduleCustomized.toUpperCase()}`);

    return <Description>{description}</Description>;
  };

  const showDismissButton = () => {
    return (
      <>
        <StyledTextButton
          data-testid="dismiss-appointment-button"
          onClick={event => {
            event.stopPropagation();
            setConfirm(prevState => !prevState);
          }}
        >
          <Hidden type="aboveTablet">{t(`buttons.dismiss`)}</Hidden>
          <Hidden type="belowTablet">
            <CrossIcon />
          </Hidden>
        </StyledTextButton>

        <ConfirmPopup
          text={t(`messages.appointments.confirm_dismiss`)}
          visible={confirmOpen}
          dataTestId="confirm-popup"
          close={() => {
            setConfirm(false);
          }}
          onConfirm={() => {
            initiateDismissAppointment();
          }}
          error={error}
          setError={setError}
        />
      </>
    );
  };

  const getMessage = (): JSX.Element => {
    if (isAdherence) {
      return (
        <TextWrapper $inverted>
          <span>{t("messages.appointments.types.LOW_ADHERENCE")}</span>
          {showDescription && showDescriptionText()}
        </TextWrapper>
      );
    }

    return (
      <TextWrapper>
        <span>
          {isAppointmentDismissible && appointmentToSchedule !== AppointmentToSchedule.FOLLOW_UP_VIDEO_CALL
            ? t(`messages.appointments.consider_scheduling_a`)
            : t(`messages.appointments.it_is_time`)}
        </span>
        {/* FIXME: type translation */}
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <span>{t(`messages.appointments.types.${appointmentToSchedule.toUpperCase()}`)}</span>
        {showDescription && showDescriptionText()}
      </TextWrapper>
    );
  };

  const getActions = (): JSX.Element => {
    const purpose = getPurpose();
    const medium = getMedium();

    if (isAdherence) {
      return (
        <>
          <StyledPrimaryButton
            onClick={() => {
              navigate(`/messages/${patientId}?purpose=${purpose}`);
              trackCheckInAction("Clicked message");
            }}
          >
            {t("messages.message")}
          </StyledPrimaryButton>
          <StyledPrimaryButton
            onClick={() => {
              navigate(`/messages/${patientId}?meeting_purpose=${purpose}`);
              trackCheckInAction("Clicked schedule call");
            }}
          >
            {t(`messages.schedule_call`)}
          </StyledPrimaryButton>
          {showDismiss && showDismissButton()}
        </>
      );
    }

    return (
      <ButtonsWrapper>
        <BaseButton
          text={t("messages.schedule_call")}
          onClick={() => {
            navigate(`/messages/${patientId}?meeting_purpose=${purpose}${medium}`);
          }}
          uppercase
        />
        {showDismiss && showDismissButton()}
      </ButtonsWrapper>
    );
  };

  return (
    <Container data-testid="schedule-call-box" $isWarning={isWarning}>
      {getMessage()}
      {getActions()}
    </Container>
  );
};

export default ScheduleCallBox;

const Container = styled.div<{ $isWarning: boolean }>`
  background: ${({ $isWarning, theme }) => ($isWarning ? theme.colors.exercise.background : theme.colors.white)};
  border: 1px solid
    ${({ $isWarning, theme }) => ($isWarning ? theme.colors.exercise.accent : theme.colors.greys.silver)};
  border-radius: ${props => props.theme.borderRadius.basic};
  padding: 16px ${props => props.theme.spacing.S_10} 16px 24px;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  position: relative;
  ${props => props.theme.belowBreakpoint} {
    flex-direction: column;
    border-radius: 0;
    border-left: none;
    border-right: none;
    padding: ${props => props.theme.spacing.S_15} ${props => props.theme.spacing.S_20};
  }
`;

const ButtonsWrapper = styled.div`
  margin-left: 12px;
  text-align: center;
  display: flex;
  ${props => props.theme.belowBreakpoint} {
    margin: auto;
    flex-direction: column;
  }
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  width: 260px;
  margin-right: 12px;
  ${props => props.theme.belowBreakpoint} {
    margin: 12px auto;
  }
`;

const StyledTextButton = styled(TextButton)`
  ${props => props.theme.aboveBreakpoint} {
    min-width: 40px;
    padding: 0;
  }
`;

const TextWrapper = styled.div<{ $inverted?: boolean }>`
  margin: auto 0;
  span {
    color: ${props => props.theme.colors.greys.dark};
    ${props => props.theme.font.body1};
    &:first-child {
      font-weight: ${({ $inverted, theme }) => ($inverted ? theme.fontWeight.medium : theme.fontWeight.light)};
    }
    &:nth-child(2) {
      font-weight: ${({ $inverted, theme }) => ($inverted ? theme.fontWeight.light : theme.fontWeight.medium)};
      color: ${props => props.theme.colors.primary.base};
      margin-left: ${props => props.theme.spacing.S_5};
    }
  }
  ${props => props.theme.belowBreakpoint} {
    margin: 0 auto;
    padding-bottom: ${props => props.theme.spacing.S_10};
    ${props => props.theme.font.link3};
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
  }
`;

const Description = styled.div`
  margin-top: 8px;
  font-weight: 300;
  color: ${props => props.theme.colors.greys.slate};
  line-height: 25px;
  font-size: 18px;
  white-space: pre-wrap;
`;
