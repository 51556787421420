import { z } from "zod";

export const MetadataSchema = z.object({
  next: z.number().nullable(),
  page: z.number().nullable(),
  page_items: z.number(),
  prev: z.number().nullable(),
  total_count: z.number(),
});

const PaginationArgsSchema = z.object({
  limit: z.number(),
  page: z.number(),
});
export type PaginationArgs = z.infer<typeof PaginationArgsSchema>;
