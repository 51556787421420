import type { FC } from "react";
import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { Navigate, useLocation } from "react-router-dom";
import styled from "styled-components";

import { useGetAuthenticatedUserQuery } from "api/hooks/useGetAuthenticatedUserQuery";
import type { User } from "api/models/User";
import { useAppConfigContext } from "contexts/AppConfigContext";
import { useProfileContext } from "contexts/ProfileContext";
import type { Appointment } from "routes/messages/types";
import OooBanner from "shared/atoms/OooBanner";
import Spinner from "shared/atoms/Spinner";
import NavBar from "shared/NavBar";
import { NAVBAR_WIDTH, NAVBAR_WIDTH_COLLAPSED } from "shared/NavBar/NavBar";
import { LOGIN_URL } from "utils/auth";
import { getRestHeaders, removeAuthCookies } from "utils/cookie";
import {
  initalRouteForProfile,
  isRouteAllowed,
  userIsAdmin,
  userIsPartner,
  userIsPatient,
  userIsPhysician,
  userIsTherapist,
} from "utils/profile/profileHelper";
import { StatuspageBar } from "utils/statuspage/components/StatuspageBar";

import { getAppointments } from "./queries";

const ONBOARDING_PATH = "/onboarding";

const isOnboardingInProgress = ({ state }: User) => state === "in_onboarding";

interface ProtectedRouteProps {
  path: string;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ children, path }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const hasAuthCookies = getRestHeaders() !== null;
  const { profile, setProfile, setScheduledCalls } = useProfileContext();
  const { navBarCollapsed } = useAppConfigContext();

  const isOnboardingRoute = location.pathname.startsWith(ONBOARDING_PATH);

  // Always fetch profile
  const getAuthenticatedUserQuery = useGetAuthenticatedUserQuery({
    enabled: hasAuthCookies,
    onSuccess(response) {
      setProfile(response.data);
    },
  });

  useEffect(() => {
    if (
      profile &&
      !userIsPartner(profile) &&
      !userIsPhysician(profile) &&
      !isOnboardingInProgress(profile) &&
      !userIsPatient(profile) &&
      !(userIsAdmin(profile) && !userIsTherapist(profile)) // user can be hcp_admin AND therapist
    ) {
      // TODO Use ReactQuery instead
      getAppointments(profile.id).then(data => {
        setScheduledCalls(data.data as Appointment[]);
      });
    }
  }, [profile]);

  if (!hasAuthCookies) {
    return <Navigate to={{ pathname: LOGIN_URL }} state={{ from: location }} />;
  }

  if (getAuthenticatedUserQuery.isError) {
    removeAuthCookies();
    return <Navigate to={{ pathname: LOGIN_URL }} state={{ from: location, error: getAuthenticatedUserQuery.error }} />;
  }

  if (profile) {
    if (userIsPatient(profile)) {
      removeAuthCookies();
      setProfile(undefined);
      return <Navigate to={{ pathname: LOGIN_URL }} state={{ from: location, error: t("errors.patient_login") }} />;
    }
    if (isOnboardingInProgress(profile) && !isOnboardingRoute) {
      return <Navigate to={{ pathname: ONBOARDING_PATH }} />;
    }
    if (!isRouteAllowed(profile, path)) {
      return <Navigate to={{ pathname: initalRouteForProfile(profile) }} state={{ from: location }} />;
    }
    return (
      <>
        {!isOnboardingRoute && <NavBar />}
        <PageWrapper $isOnboardingRoute={isOnboardingRoute} $navBarCollapsed={navBarCollapsed}>
          <BannerWrapper>
            <StatuspageBar />
            <OooBanner />
          </BannerWrapper>
          {children}
        </PageWrapper>
      </>
    );
  }

  return (
    <Loading>
      <Spinner dataTestId="spinner" />
    </Loading>
  );
};

export default ProtectedRoute;

const Loading = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PageWrapper = styled.div<{ $isOnboardingRoute: boolean; $navBarCollapsed: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - ${({ $navBarCollapsed }) => ($navBarCollapsed ? NAVBAR_WIDTH_COLLAPSED : NAVBAR_WIDTH)}px);

  /* Note: Navbar is not rendered in onboarding routes, this is a fix to make the page full width. */

  width: ${props => props.$isOnboardingRoute && "100%"};
  ${props => props.theme.belowBreakpoint} {
    width: 100%;
  }
`;

const BannerWrapper = styled.div`
  width: 100%;
`;
