import type React from "react";

import { faCheckCircle, faCircleXmark, faPlusCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "styled-components";

import type { Status } from "api/schemas/physician";

interface Props {
  status: Status;
}

export const StatusIcon: React.VFC<Props> = ({ status }) => {
  const theme = useTheme();

  switch (status) {
    case "pending":
      return (
        <FontAwesomeIcon
          icon={faPlusCircle}
          color={theme.colors.redesign.b100}
          style={{ height: "18px", width: "18px" }}
        />
      );
    case "accepted":
      return (
        <FontAwesomeIcon
          icon={faCheckCircle}
          color={theme.colors.redesign.g100}
          style={{ height: "18px", width: "18px" }}
        />
      );
    case "rejected":
      return (
        <FontAwesomeIcon
          icon={faCircleXmark}
          color={theme.colors.redesign.r100}
          style={{ height: "18px", width: "18px" }}
        />
      );

    default:
      return (
        <FontAwesomeIcon
          icon={faPlusCircle}
          color={theme.colors.redesign.b100}
          style={{ height: "18px", width: "18px" }}
        />
      );
  }
};
