import type React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useGetPhysicianUnhandledPatient } from "api/hooks/useGetPhysicianUnhandledPatient";
import { Table, TableHeader, TableMissingContent } from "shared/molecules/Table";

import PhysicianMedicalReferralItem from "./components/PhysicianMedicalReferralItem";
import PlanOfCareItem from "./components/PlanOfCareItem";

interface Props {
  $isFullWidthOnMobile?: boolean;
  patientId: number;
}

const PhysicanToDoTable: React.VFC<Props> = ({ $isFullWidthOnMobile = false, patientId }) => {
  const { t } = useTranslation();

  const { data, isLoading: isLoadingPhysicianUnhandledPatient, isError } = useGetPhysicianUnhandledPatient(patientId);
  const { medical_referral_status, plan_of_care_status, first_evaluation, medical_referral_id, plan_of_care_id } =
    data || {};

  const pendingReferral = medical_referral_status === "pending";
  const pendingPlanOfCare = plan_of_care_status === "pending";
  const totalLength = [pendingReferral, pendingPlanOfCare].filter(Boolean).length;

  if (!data || isError) {
    return null;
  }

  return (
    <TableContainer>
      <Table overflow="visible" $isFullWidthOnMobile={$isFullWidthOnMobile}>
        <thead>
          <TableHeader colSpan={4} title={t("dashboard.tables.physician_todo.title")} notifications={totalLength} />
        </thead>
        <tbody>
          <TableMissingContent loading={isLoadingPhysicianUnhandledPatient} />
          {!isLoadingPhysicianUnhandledPatient && (
            <DividerRow>
              <td>
                <PlanOfCareItem
                  patientId={patientId}
                  planOfCareId={plan_of_care_id}
                  date={first_evaluation}
                  status={plan_of_care_status || "pending"}
                />
              </td>
            </DividerRow>
          )}
          {!isLoadingPhysicianUnhandledPatient && (
            <DividerRow>
              <td>
                <PhysicianMedicalReferralItem
                  patientId={patientId}
                  medicalReferralId={medical_referral_id}
                  date={first_evaluation}
                  status={medical_referral_status || "pending"}
                />
              </td>
            </DividerRow>
          )}
        </tbody>
      </Table>
    </TableContainer>
  );
};

const TableContainer = styled.div`
  margin: 32px 0;
`;

const DividerRow = styled.tr`
  border-bottom: 1px solid #e0e0e0;

  &:last-of-type {
    border-bottom: none;
  }
`;

export default PhysicanToDoTable;
