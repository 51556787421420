import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { MedicalReferral } from "api/models/MedicalReferral";
import { AccordionItem } from "shared/atoms/Accordion";
import { MUIMaskedInput, MUITextInput } from "shared/atoms/inputs";
import useLocalizedDate from "utils/date";

import { ICDAutocomplete, SectionRow } from "../../../DynamicMedicalNotes/components";
import { Form } from "../../../DynamicMedicalNotes/utils/formStyles";

interface Props {
  patientId: number;
  record: MedicalReferral;
}

const RecordReferral = ({ patientId, record }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { format, parseISO } = useLocalizedDate();
  const form = useForm({
    defaultValues: record,
  });

  return (
    <AccordionItem
      buttonLabel={t("patients.comments.show_more")}
      print
      header={
        <HeaderWrapper>
          <DateWrapper>{format(parseISO(record.date_of_physician_signature ?? ""), "PP")}</DateWrapper>
          <TextWrapper>{t("patients.medical_records.referral")}</TextWrapper>
        </HeaderWrapper>
      }
      content={
        <>
          <FormProvider {...form}>
            <Form>
              <ContentWrapper>
                <Section>
                  <SectionRow>
                    <StyledMUITextInput name="doctor_name" label={t("patients.medical_records.responsible")} disabled />
                    <StyledMUIMaskedInput
                      name="duration_days"
                      label={t("patients.medical_records.referral_for")}
                      suffix={` ${t("common.days")}`}
                      disabled
                    />
                  </SectionRow>
                  <SectionRow>
                    <ICDAutocomplete
                      name="icd_codes"
                      label={t("patients.medical_records.diagnosis")}
                      patientId={patientId}
                      disabled
                    />
                  </SectionRow>
                  <SectionRow>
                    <MUITextInput name="reason" label={t("patients.medical_records.note")} multiline disabled />
                  </SectionRow>
                </Section>
              </ContentWrapper>
            </Form>
          </FormProvider>
        </>
      }
    />
  );
};

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 1;
  ${props => props.theme.font.body1}
  color: ${props => props.theme.colors.greys.dark};
  overflow: hidden;
`;

const DateWrapper = styled.div`
  font-weight: ${props => props.theme.fontWeight.regular};
  min-width: 130px;
  flex-shrink: 0;
`;

const TextWrapper = styled.span`
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: ${props => props.theme.spacing.S_30};
`;

const StyledMUITextInput = styled(MUITextInput)`
  flex: 1;
  margin-right: ${props => props.theme.spacing.S_15};
`;

const StyledMUIMaskedInput = styled(MUIMaskedInput)`
  width: 125px;
  flex: none;

  ${props => props.theme.belowBreakpoint} {
    width: 100px;
  }
`;

const Section = styled.div`
  padding: ${props => props.theme.spacing.S_20};
  padding-right: 120px;

  ${props => props.theme.belowBreakpoint} {
    padding-right: ${props => props.theme.spacing.S_20};
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

export default RecordReferral;
