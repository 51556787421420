import type { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useGetPhysicianHandledPatients } from "api/hooks/useGetPhysicianHandledPatients";
import { paginationModel, usePagination } from "api/hooks/usePagination";
import { MUIDataGrid } from "shared/molecules/MUIDataGrid";

import { Container, Header, Title } from "./styles";

interface Props {
  columns: GridColDef[];
}

export const HandledPatients: React.VFC<Props> = ({ columns }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pagination, setPagination } = usePagination();
  const { data } = useGetPhysicianHandledPatients({ limit: pagination.limit, page: pagination.page + 1 });
  const rows = data?.items;
  const metadata = data?.metadata;

  return (
    <Container>
      <Header>
        <Title>{t("dashboard.tables.physician.handled_patients")}</Title>
      </Header>
      <MUIDataGrid
        columns={columns}
        rows={rows}
        getRowId={row => row.patient_id}
        initialState={{ pagination: { paginationModel } }}
        paginationMode="server"
        onPaginationModelChange={model => setPagination({ limit: model.pageSize, page: model.page })}
        rowCount={metadata?.total_count}
        onCellClick={({ field, row }) => {
          if (field !== "medical_referral_status" && field !== "plan_of_care_status") {
            navigate(`/patients/${row.patient_id}`);
          }
        }}
        localeText={{ noRowsLabel: t("physician.handled_patients_no_rows") as string }}
      />
    </Container>
  );
};
