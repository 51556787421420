import { useProfileContext } from "contexts/ProfileContext";

interface CallFeatures {
  videoCallsEnabled: boolean;
  voiceCallsEnabled: boolean;
  phoneCallsEnabled: boolean;
}

const useCallFeatures = (): CallFeatures => {
  const { profile } = useProfileContext();

  const videoCallsEnabled: boolean =
    profile?.therapist_profile?.video_calls_enabled || profile?.therapist_profile?.payer_video_call_required || false;
  const voiceCallsEnabled: boolean = profile?.therapist_profile?.feature_flags?.includes("VOICE_CALLS") || false;
  const phoneCallsEnabled: boolean = profile?.therapist_profile?.feature_flags?.includes("PHONE_CALLS") || false;

  return { videoCallsEnabled, voiceCallsEnabled, phoneCallsEnabled };
};

export default useCallFeatures;
