/* eslint-disable import/prefer-default-export */
export const QUERY_KEYS = {
  dashboard: {
    earnings: "earnings",
    eventCards: "eventCards",
  },
  patient: {
    protocol: "protocol",
    creatableCareEvents: "creatableCareEvents",
  },
  patients: "patientList",
  unreadMessagesCount: "unreadMessagesCount",
  conversations: "conversations",
};
