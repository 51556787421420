import type React from "react";

import { faFileInvoiceDollar } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateRange } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import invariant from "ts-invariant";

import { InvitePatientIcon, ProgressIcon, UsersIcon } from "assets";
import { useAppConfigContext } from "contexts/AppConfigContext";
import { useProfileContext } from "contexts/ProfileContext";
import { MarketTypes } from "types";
import { isRouteAllowed, isUserMarket, userIsPartner, userIsPhysician } from "utils/profile/profileHelper";

import ListItem from "./ListItem";
import ListItemMessage from "./ListItemMessage";
import { List, StyledIcon, StyledSpan } from "./MenuStyles";

const Menu: React.VFC<Record<string, never>> = () => {
  const { t } = useTranslation();
  const { navBarCollapsed } = useAppConfigContext();
  const { profile } = useProfileContext();
  const ifAllowed = (route: string) => !!(profile && isRouteAllowed(profile, route));

  invariant(profile, "Profile should be defined");
  const isUS = isUserMarket(MarketTypes.US, profile);
  const isPartner = userIsPartner(profile);
  const isPhysician = userIsPhysician(profile);

  return (
    <List>
      {ifAllowed("/dashboard") && (
        <ListItem to="/dashboard">
          <StyledIcon>
            <ProgressIcon />
          </StyledIcon>
          <StyledSpan $collapsed={navBarCollapsed}>{t("views.dashboard")}</StyledSpan>
        </ListItem>
      )}
      <>
        {ifAllowed("/messages") && <ListItemMessage />}

        {profile?.therapist_profile?.feature_flags?.includes("BOOKING_V2") && ifAllowed("/calendar") && (
          <ListItem to="/calendar">
            <StyledIcon>
              <DateRange />
            </StyledIcon>
            <StyledSpan $collapsed={navBarCollapsed}>{t("views.calendar")}</StyledSpan>
          </ListItem>
        )}

        {ifAllowed("/patients") && !isPartner && !isPhysician && (
          <ListItem to="/patients">
            <StyledIcon>
              <UsersIcon />
            </StyledIcon>
            <StyledSpan $collapsed={navBarCollapsed}>{t("views.patients")}</StyledSpan>
          </ListItem>
        )}

        {ifAllowed("/invite") && (
          <ListItem to="/invite">
            <StyledIcon>
              <InvitePatientIcon />
            </StyledIcon>
            <StyledSpan $collapsed={navBarCollapsed}>{t("views.invite")}</StyledSpan>
          </ListItem>
        )}

        {isUS && ifAllowed("/admin-billing") && (
          <ListItem to="/admin-billing">
            <StyledIcon>
              <FontAwesomeIcon icon={faFileInvoiceDollar} style={{ height: "24px", width: "24px" }} />
            </StyledIcon>
            <StyledSpan $collapsed={navBarCollapsed}>{t("views.admin_billing")}</StyledSpan>
          </ListItem>
        )}
      </>
    </List>
  );
};

export default Menu;
