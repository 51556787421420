import type React from "react";
import { useContext, useEffect, useRef, useState } from "react";

import type { UseFormGetValues } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import invariant from "ts-invariant";

import { useGetPatientQuery } from "api/hooks/useGetPatientQuery";
import type { ActivityTypes } from "api/models/Activity";
import { NewMessageIcon, SendIcon } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import type { SavedMessages } from "contexts/SavedInputsContext";
import { useSavedInputsContext } from "contexts/SavedInputsContext";
import { LeifGPT } from "routes/messages/components/LeifGPT";
import { SmartReplyIndicator } from "routes/messages/components/LeifGPT/LeifGPT";
import useSendMessageCall from "routes/messages/queries/useSendMessage";
import ActivityTitle from "shared/atoms/ActivityTitle";
import { PrimaryButton } from "shared/atoms/Button";
import TextArea from "shared/atoms/inputs/TextArea";
import { Notification } from "shared/atoms/Notification";
import Row from "shared/atoms/Row";
import Tag from "shared/atoms/Tag";
import MarkAsUnread from "shared/molecules/MarkAsUnread";
import { AnalyticsEvents, AnalyticsService } from "utils/analytics";
import { CurrentPatientContext } from "utils/contexts";
import useLocalizedDate from "utils/date";
import { useActivityLogging } from "utils/hooks/useActivityLogging";
import { isUsingActivityTracking } from "utils/patient";
import { userIsTherapist } from "utils/profile/profileHelper";

export interface Props {
  completedOn?: string;
  reply?: string;
  activity: ActivityTypes;
  activityName: string;
  levels: number;
  currentLevel: number;
  comment: string;
  id: number;
  viewed: boolean;
  smartReply: string | null | undefined;
  markCommentAsUnviewed: () => void;
}

const Comment: React.VFC<Props> = ({
  completedOn,
  reply,
  activity,
  activityName,
  levels,
  currentLevel,
  comment,
  id,
  viewed = true,
  smartReply,
  markCommentAsUnviewed,
}) => {
  const { t } = useTranslation();
  const { format, parseISO } = useLocalizedDate();
  const [showForm, setShowForm] = useState(false);
  const { patientId } = useParams<{ patientId: string }>();
  const [error, setError] = useState(null);
  const replyRef = useRef<null | HTMLTextAreaElement>();
  const getValuesRef = useRef<undefined | UseFormGetValues<{ replyMessage?: string }>>();
  const [localReply, setLocalReply] = useState(reply);
  const { profile } = useProfileContext();
  const { updateIsCreatable } = useContext(CurrentPatientContext);
  const { savedComments, setSavedComments } = useSavedInputsContext();
  const { trackActivity } = useActivityLogging();
  const startTime = useRef("");
  const { data: patient } = useGetPatientQuery({ patientId: parseInt(patientId || "", 10) }, { enabled: !!patientId });

  invariant(profile);
  const isTherapist = userIsTherapist(profile);

  useEffect(() => {
    return () => {
      if (getValuesRef.current) {
        const inputValue = getValuesRef.current("replyMessage");
        if (inputValue) {
          setSavedComments((c: SavedMessages) => ({ ...c, [id]: inputValue }));
        } else if (inputValue === "" && savedComments[id]) {
          setSavedComments((c: { [id: number]: string }) => {
            const comments = { ...c };
            delete comments[id];
            return comments;
          });
        }
      }
    };
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm();

  useEffect(() => {
    setValue("replyMessage", savedComments[id] ? savedComments[id] : "");
  }, []);

  useEffect(() => {
    if (showForm && replyRef.current) {
      replyRef.current.focus();
      startTime.current = new Date().toISOString();
    }
  }, [showForm]);

  useEffect(() => {
    getValuesRef.current = getValues;
  });

  const { mutateAsync: sendMessage } = useSendMessageCall();

  const onSubmit = handleSubmit(async ({ replyMessage }) => {
    sendMessage({ to: Number(patientId), body: replyMessage, activity_id: id })
      .then(({ data }) => {
        AnalyticsService.track(AnalyticsEvents.PATIENTS.COMMENT_REPLY, {
          comment_id: id,
          patient: patientId,
        });

        if (isTherapist && patientId && patient && isUsingActivityTracking(patient)) {
          trackActivity({
            category: "comment_posted",
            patient_id: parseInt(patientId, 10),
            start_time: startTime.current,
            end_time: new Date().toISOString(),
          });
        }

        setError(null);
        setShowForm(false);
        setLocalReply(data.message.body);
        updateIsCreatable();
        if (savedComments[id])
          setSavedComments((c: SavedMessages) => {
            const comments = c;
            delete comments[id];
            return comments;
          });
        reset();
      })
      .catch(errorObj => {
        setError(errorObj);
      });
  });

  const { ref: registerRef, ...rest } = register("replyMessage", {
    required: true,
    validate: value => {
      return !!value.trim();
    },
  });

  return (
    <Container data-testid="comment" $type={activity}>
      <StyledRow justify="space-between" align="center">
        <ActivityTitle
          size="small"
          type={activity}
          title={activityName}
          levels={activity === "exercise" && currentLevel !== 0 ? { current: currentLevel, max: levels } : undefined}
        />
        <InnerRow justify="flex-end" align="center">
          <DateWrapper>{completedOn && format(parseISO(completedOn), "PP")}</DateWrapper>
          <ReadWrapper>
            {viewed ? (
              <MarkAsUnread commentCallBack={markCommentAsUnviewed} />
            ) : (
              // FIXME: type translation
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <Tag dataTestId="new-comment-tag" label={t("common.new.0")} />
            )}
          </ReadWrapper>
        </InnerRow>
      </StyledRow>

      {comment}

      {!localReply ? (
        <div style={{ display: "flex", alignItems: "flex-end", justifyContent: "space-between", width: "100%" }}>
          <Wrapper data-testid="comment-reply-button" onClick={() => setShowForm(true)} $show={!showForm}>
            <StyledIcon />
            {t("buttons.reply")}
          </Wrapper>
          {smartReply && !showForm && <SmartReplyIndicator />}
        </div>
      ) : (
        <Reply data-testid="comment-reply-text">{localReply}</Reply>
      )}

      {smartReply && showForm && (
        <LeifGPT
          commentId={id}
          patientId={Number(patientId)}
          smartReply={smartReply}
          onEdit={value => {
            setValue("replyMessage", value);
            if (replyRef.current) replyRef.current.focus();
          }}
          onSubmit={value => {
            sendMessage({ to: Number(patientId), body: value, activity_id: id })
              .then(({ data }) => {
                setError(null);
                setShowForm(false);
                setLocalReply(data.message.body);
                updateIsCreatable();
                if (savedComments[id])
                  setSavedComments((c: SavedMessages) => {
                    const comments = c;
                    delete comments[id];
                    return comments;
                  });
                reset();
              })
              .catch(errorObj => {
                setError(errorObj);
              });
          }}
          dismissEvent={AnalyticsEvents.LEIF_GPT.COMMENTS.SUGGESTION_DISMISSED}
          editEvent={AnalyticsEvents.LEIF_GPT.COMMENTS.SUGGESTION_EDITED}
          presentEvent={AnalyticsEvents.LEIF_GPT.COMMENTS.SUGGESTION_PRESENTED}
          submitEvent={AnalyticsEvents.LEIF_GPT.COMMENTS.SUGGESTION_SUBMITTED}
        />
      )}

      <FormWrapper $show={showForm}>
        <form data-testid="reply-form" onSubmit={onSubmit}>
          <TextArea
            {...rest}
            rows={2}
            padding="3px 0"
            placeholder={t("patients.comments.reply_placeholder")}
            ref={e => {
              replyRef.current = e;
              registerRef(e);
            }}
            onValueUpdate={value => setValue("replyMessage", value)}
            error={errors.replyMessage && ""}
            expandToHeight={250}
            showQuickReplies
            emojiPicker
            noBorder
            scrollbarVisible={false}
          />
          <SendButton data-testid="comment-reply-submit-button" type="submit">
            <SendIcon />
          </SendButton>
          {error !== null && (
            <Notification variant="danger" style={{ marginBottom: "5px" }}>
              {t("errors.generic")}
            </Notification>
          )}
        </form>
      </FormWrapper>
    </Container>
  );
};

export default Comment;

const Container = styled.div<{ $type: ActivityTypes }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
  background-color: ${({ theme, $type }) => theme.colors[$type].background || theme.colors.white};
  border-radius: ${props => props.theme.borderRadius.basic};
  padding: ${props => props.theme.spacing.S_15};
  margin-bottom: ${props => props.theme.spacing.S_20};
  ${props => props.theme.font.header5}
  color: ${props => props.theme.colors.primary.base};
  position: relative;

  &:hover [data-testid="MarkAsUnread-menu-button"] {
    opacity: 1;
  }
`;

const DateWrapper = styled.div`
  ${props => props.theme.font.header5}
  color: ${props => props.theme.colors.greys.gunmetal};
  flex-shrink: 1;
  min-width: 60px;
`;

const StyledRow = styled(Row)`
  width: 100%;
`;

const InnerRow = styled(Row)`
  padding-bottom: ${props => props.theme.spacing.S_15};
`;

const StyledIcon = styled(NewMessageIcon)`
  width: 24px;
  height: 24px;
  margin-right: ${props => props.theme.spacing.S_5};
`;

const Wrapper = styled.div<{ $show?: boolean }>`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: ${props => props.theme.spacing.S_20} 0 ${props => props.theme.spacing.S_5};
  ${props => ({ ...props.theme.font.link1 })};
  color: ${props => props.theme.colors.primary.base};
  font-family: "Roboto", sans-serif;
  text-decoration: none;
  user-select: none;
  &:hover {
    opacity: 0.7;
  }
  cursor: pointer;
  display: ${({ $show }) => ($show ? "flex" : "none")};
`;

const Reply = styled.div`
  background-color: ${props => props.theme.colors.white};
  ${props => props.theme.font.sub_menu.link}
  color: ${props => props.theme.colors.primary.base};
  border-radius: ${props => props.theme.borderRadius.basic};
  box-sizing: border-box;
  padding: ${props => props.theme.spacing.S_10} ${props => props.theme.spacing.S_15};
  margin-top: ${props => props.theme.spacing.S_20};
  width: calc(100% - 35px);
  white-space: pre-wrap;
`;

const FormWrapper = styled.div<{ $show?: boolean }>`
  width: 100%;
  flex-direction: column;
  margin: ${props => props.theme.spacing.S_20} 0 0;
  display: ${({ $show }) => ($show ? "flex" : "none")};
  position: relative;
`;

const ReadWrapper = styled.div`
  margin-left: ${props => props.theme.spacing.S_10};
`;

const SendButton = styled(PrimaryButton)`
  position: absolute;
  right: 12px;
  bottom: 8px;
  min-height: 32px;
  height: 32px;
  min-width: 52px;
  padding: 0px;
  & svg {
    width: 24px;
    height: 24px;
  }
`;
