import type { UseQueryOptions, UseQueryResult } from "react-query";
import { useQuery } from "react-query";

import type { HealthJournal } from "api/schemas/patients/HealthJournal";
import type { GetPatientResponse } from "api/types/GetPatient";
import type { GetPatientCurrentSubscriptionResponse } from "api/types/GetPatientCurrentSubscription";
import type { GetPatientUsProviderGroupProfileResponse } from "api/types/GetPatientUsProviderGroupProfile";
import type { GetPatientWidgetsPainLocationResponse } from "api/types/GetPatientWidgetsPainLocation";
import type { GetUsPatientInsuranceResponse } from "api/types/GetUsPatientInsurance";
import useApiClient from "api/useApiClient";

type UseGetPatientFullDetailsQueryArgs = {
  patientId: number;
  therapistId: number;
  therapistMarket: string;
  previousTreatmentId?: number | null;
} | null;

type RequiredResponses = [GetPatientResponse, HealthJournal, GetPatientWidgetsPainLocationResponse];

type OptionalUsResponses = [
  GetUsPatientInsuranceResponse | undefined,
  GetPatientCurrentSubscriptionResponse | undefined,
  GetPatientUsProviderGroupProfileResponse | undefined
];

export type UseGetPatientFullDetailsQueryResponses = [...RequiredResponses, ...OptionalUsResponses];

// As all these responses are merged into a single object, we have some
// properties with the same name but with different type definitions.
// Preferably, we should not merge these into a single object in the first
// place, but now we are... So excluding the duplicate definitions from
// GetPatientHealthJournalResponse because they will be overridden by other
// responses.
// "created_at" is overridden by User from GetPatientResponse.
// "pain_locations" and "most_painful_location" is overridden by
// GetPatientWidgetsPainLocationResponse.
export type UseGetPatientFullDetailsQueryData = GetPatientResponse &
  Omit<HealthJournal, "created_at" | "pain_locations" | "most_painful_location"> &
  GetPatientWidgetsPainLocationResponse & {
    insuranceData?: GetUsPatientInsuranceResponse;
    subscriptionData?: GetPatientCurrentSubscriptionResponse;
    providerGroupData?: GetPatientUsProviderGroupProfileResponse;
  };

type QueryKey = ["useGetPatientFullDetailsQuery", UseGetPatientFullDetailsQueryArgs];

export const getPatientFullDetailsQueryKey = (args: UseGetPatientFullDetailsQueryArgs): QueryKey => [
  "useGetPatientFullDetailsQuery",
  args,
];

export const useGetPatientFullDetailsQuery = (
  args: UseGetPatientFullDetailsQueryArgs,
  options?: UseQueryOptions<UseGetPatientFullDetailsQueryResponses, Error, UseGetPatientFullDetailsQueryData, QueryKey>
): UseQueryResult<UseGetPatientFullDetailsQueryData, Error> => {
  const client = useApiClient();

  return useQuery(
    getPatientFullDetailsQueryKey(args),
    async (): Promise<UseGetPatientFullDetailsQueryResponses> => {
      if (!args) {
        return Promise.reject(new Error("Invalid query arguments"));
      }
      const { patientId, therapistMarket } = args;
      const requiredResponses = await Promise.all([
        client.getPatient({ patientId, ...(args.previousTreatmentId && { treatment_id: args.previousTreatmentId }) }),
        client.getPatientHealthJournal({
          patientId,
          ...(args.previousTreatmentId && { treatment_id: args.previousTreatmentId }),
        }),
        client.getPatientWidgetsPainLocation({
          patientId,
          ...(args.previousTreatmentId && { treatment_id: args.previousTreatmentId }),
        }),
      ]);

      if (therapistMarket === "US") {
        const optionalUsResponses: OptionalUsResponses = await Promise.allSettled([
          client.getUsPatientInsurance({ patientId }),
          client.getPatientCurrentSubscription({ patientId }),
          client.getPatientUsProviderGroupProfile({ patientId }),
        ]).then(([r1, r2, r3]) => [
          r1.status === "fulfilled" ? r1.value : undefined,
          r2.status === "fulfilled" ? r2.value : undefined,
          r3.status === "fulfilled" ? r3.value : undefined,
        ]);

        return [...requiredResponses, ...optionalUsResponses];
      }
      return [...requiredResponses, undefined, undefined, undefined];
    },
    {
      ...options,
      select(data): UseGetPatientFullDetailsQueryData {
        return transformQueryResponses(data);
      },
    }
  );
};

export const transformQueryResponses = ([
  getPatientResponse,
  getPatientHealthJournalResponse,
  getPatientWidgetsPainLocationResponse,
  getUsPatientInsuranceResponse,
  getPatientCurrentSubscriptionResponse,
  getPatientUsProviderGroupProfileResponse,
]: UseGetPatientFullDetailsQueryResponses): UseGetPatientFullDetailsQueryData => {
  const result: UseGetPatientFullDetailsQueryData = {
    // Required -- all markets
    ...getPatientResponse,
    ...getPatientHealthJournalResponse,
    ...getPatientWidgetsPainLocationResponse,
  };
  // Optional -- US specific
  if (getUsPatientInsuranceResponse) {
    result.insuranceData = getUsPatientInsuranceResponse;
  }
  if (getPatientCurrentSubscriptionResponse) {
    result.subscriptionData = getPatientCurrentSubscriptionResponse;
  }
  if (getPatientUsProviderGroupProfileResponse) {
    result.providerGroupData = getPatientUsProviderGroupProfileResponse;
  }
  return result;
};
