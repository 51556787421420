import type { VFC } from "react";
import { useContext, useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import styled from "styled-components";

import { EditIcon } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import { SecondaryButton } from "shared/atoms/Button";
import Hidden from "shared/atoms/Hidden";
import IconButton from "shared/atoms/IconButton";
import Pagination from "shared/atoms/Pagination";
import { CurrentPatientContext } from "utils/contexts";
import useLocalizedDate from "utils/date";
import { userIsTherapist } from "utils/profile/profileHelper";

import ChangeLevels from "../ChangeLevels";
import type { ProtocolWeek } from "../queries/useProtocol";

const Container = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.greys.silver};
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Header = styled.div`
  max-width: 970px;
  box-sizing: border-box;
  width: 100%;
  height: 68px;
  padding: 0 ${props => props.theme.spacing.S_15};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled(SecondaryButton)`
  margin-right: ${props => props.theme.spacing.S_30};
`;

const Text = styled(animated.div)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  ${props => props.theme.aboveBreakpoint} {
    margin-right: auto;
  }
  color: ${props => props.theme.colors.greys.dark};
`;

const MainText = styled.div`
  ${props => props.theme.font.header5}
  ${props => props.theme.belowBreakpoint} {
    text-align: center;
  }
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

const SubText = styled.div`
  ${props => props.theme.font.caption}
  ${props => props.theme.belowBreakpoint} {
    text-align: center;
  }
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

const Tag = styled.span`
  background-color: ${props => props.theme.colors.primary.base};
  border-radius: 4px;
  color: ${props => props.theme.colors.white};
  ${props => props.theme.font.caption}
  padding: 2px ${props => props.theme.spacing.S_5};
  margin-left: ${props => props.theme.spacing.S_5};
`;

type WrapperProps = {
  $right?: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  ${props => (props.$right ? `margin-left: ${props.theme.spacing.S_20}` : `margin-right: ${props.theme.spacing.S_20}`)};
  ${props => props.$right && "position: relative;"}
`;

type ProtocolHeaderProps = {
  empty?: boolean;
  onCurrentClick: () => void;
  onPageChange: (direction: string) => void;
  weekInfo: {
    startDate: ProtocolWeek["start_date"];
    endDate: ProtocolWeek["end_date"];
    nextId: ProtocolWeek["next_id"];
    previousId: ProtocolWeek["previous_id"];
    overdue: ProtocolWeek["overdue"];
    overdueDays: ProtocolWeek["overdue_delayed_days"];
    weekNumber: ProtocolWeek["number"];
  };
};

const ProtocolHeader: VFC<ProtocolHeaderProps> = ({ weekInfo, onPageChange, onCurrentClick, empty = false }) => {
  const { t } = useTranslation();
  const { format, parseISO } = useLocalizedDate();
  const navigate = useNavigate();
  const [openTooltip, setOpenTooltip] = useState(false);
  const { patient: currentPatient } = useContext(CurrentPatientContext);
  const { profile } = useProfileContext();

  const isTherapist = userIsTherapist(profile);

  // UserCourse AKA SGC (Supply side Generated Content) aka "Physio created treatment programs". In stage, user 407850 (PT: 173406) has this enabed
  const userCourseEnabled = currentPatient.current_course_strategy === "custom";

  const animatedStyles = useSpring({
    opacity: empty ? 0 : 1,
  });

  return (
    <Container data-testid="protocol-header">
      <Header>
        <Wrapper>
          <Hidden type="belowTablet">
            <Button
              data-testid="current-week-button"
              disabled={weekInfo.nextId === null || empty}
              onClick={() => onCurrentClick()}
            >
              {t("patients.protocol.current")}
            </Button>
          </Hidden>
          <Pagination
            pageInfo={{ hasNextPage: weekInfo.nextId !== null, hasPreviousPage: weekInfo.previousId !== null }}
            onPageChange={direction => onPageChange(direction)}
          />
        </Wrapper>

        <Text style={animatedStyles}>
          {weekInfo.weekNumber !== null && (
            <MainText data-testid="protocol-week-title">
              {`${t("common.week_num", { number: weekInfo.weekNumber.toString() })} `}
              {weekInfo.nextId === null &&
                (weekInfo.overdue ? (
                  <span data-testid="awaiting-completion-label">({t("patients.protocol.awaiting_completion")})</span>
                ) : (
                  <span data-testid="current-label">{`(${t("patients.protocol.current").toLowerCase()})`}</span>
                ))}
              {weekInfo.nextId && weekInfo.overdue && (
                <Tag data-testid="delay-label">{t("patients.protocol.delay", { days: weekInfo.overdueDays })}</Tag>
              )}
            </MainText>
          )}
          <SubText>
            {weekInfo.startDate &&
              weekInfo.endDate &&
              t("common.range", {
                start: format(parseISO(weekInfo.startDate), t("common.date.longNoYear")),
                end: format(parseISO(weekInfo.endDate), "PP"),
              })}
          </SubText>
        </Text>

        {isTherapist && !userCourseEnabled && (
          <Wrapper $right>
            <IconButton
              label={t("patients.protocol.change_levels")}
              hideLabel="belowTablet"
              onClick={event => {
                event.stopPropagation();
                setOpenTooltip(true);
              }}
              dataTestId="patients-protocol-change-levels-button"
            >
              <EditIcon />
            </IconButton>
            {openTooltip && <ChangeLevels open closeCallback={() => setOpenTooltip(false)} />}
          </Wrapper>
        )}

        {isTherapist && userCourseEnabled && (
          <Wrapper $right>
            <IconButton
              label={
                currentPatient.awaiting_treatment_ready ? t("usercourse.create_course") : t("usercourse.edit_course")
              }
              hideLabel="belowTablet"
              onClick={event => {
                event.stopPropagation();
                navigate(`/patients/${currentPatient.id}/usercourse`);
              }}
            >
              <EditIcon />
            </IconButton>
          </Wrapper>
        )}
      </Header>
    </Container>
  );
};

export default ProtocolHeader;
