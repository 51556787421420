import type React from "react";
import { useState } from "react";

import { parseISO } from "date-fns";
import { format } from "date-fns/format";
import { useTranslation } from "react-i18next";

import { useGetPatientIcdCodesQuery } from "api/hooks/useGetPatientIcdCodes";
import { usePostPhysicianMedicalReferral } from "api/hooks/usePostPhysicianMedicalReferral";
import type { PhysicianMedicalReferral, Status } from "api/schemas/physician";
import useApiClient from "api/useApiClient";
import { CareEventContainer } from "routes/admin-billing/views/AdminBillingView";
import { StatusIcon } from "routes/dashboard/physician/components";
import { MedicalReferralPDF } from "routes/dashboard/physician/components/PDF/MedicalReferralPDF";
import { StyledPDFDownloadLink } from "routes/dashboard/physician/components/PDF/styles";
import Spinner from "shared/atoms/Spinner";

import DynamicMedicalNoteForm from "../../SignNotesTable/components/DynamicMedicalNoteForm";

import TodoItem from "./TodoItem";

interface Props {
  patientId: number;
  medicalReferralId: number | null | undefined;
  date: string | undefined;
  status: Status;
}

const PhysicianMedicalReferralItem: React.VFC<Props> = ({ patientId, medicalReferralId, date, status }) => {
  const { t } = useTranslation();
  const client = useApiClient();
  const [expanded, setExpanded] = useState(false);

  const [medicalReferral, setMedicalReferral] = useState<PhysicianMedicalReferral | null>(null);
  const [medicalReferralIsLoading, setMedicalReferralIsLoading] = useState(false);
  const postPhysicianMedicalReferral = usePostPhysicianMedicalReferral();
  const { data: availableICDCodes = [] } = useGetPatientIcdCodesQuery({
    patientId,
  });

  const onMedicalReferralClick = () => {
    setMedicalReferralIsLoading(true);
    if (medicalReferralId === null) {
      postPhysicianMedicalReferral.mutateAsync(
        { data: { patient_id: patientId } },
        {
          onSuccess(referralData: PhysicianMedicalReferral) {
            setMedicalReferral(referralData);
          },
          onSettled() {
            setMedicalReferralIsLoading(false);
          },
        }
      );
    } else if (medicalReferralId) {
      client
        .getPhysicianMedicalReferral(medicalReferralId)
        .then(mr => {
          setMedicalReferral(mr);
          setMedicalReferralIsLoading(false);
        })
        .finally(() => {
          setMedicalReferralIsLoading(false);
        });
    }
  };

  return (
    <TodoItem
      expanded={expanded}
      title={t("dashboard.tables.notes.types.PHYSICIAN_MEDICAL_REFERRAL")}
      secondaryTitle={date ? format(parseISO(date), "PP") : ""}
      onChange={(_, isExpanded) => {
        if (isExpanded) {
          onMedicalReferralClick();
        }
        setExpanded(isExpanded);
      }}
      icon={<StatusIcon status={status} />}
    >
      <>
        {medicalReferralIsLoading && <Spinner small />}
        {medicalReferral && !medicalReferralIsLoading && (
          <CareEventContainer>
            <DynamicMedicalNoteForm
              careEvent={{
                ...medicalReferral,
                label: "PHYSICIAN_MEDICAL_REFERRAL",
                medical_record_note: null,
              }}
              animate
              open
              isPhysician
              skipMerge
              showNoteHeader
              readOnly={medicalReferral.status !== "pending"}
              clearSelectedCareEvent={() => {
                setExpanded(false);
              }}
            />
            {medicalReferral.status !== "pending" && (
              <div style={{ display: "flex", marginTop: "28px" }}>
                <StyledPDFDownloadLink
                  document={
                    <MedicalReferralPDF availableICDCodes={availableICDCodes} medicalReferral={medicalReferral} />
                  }
                  fileName={`referral_${patientId}_${medicalReferral.handled_at}`}
                >
                  {t("patients.medical_records.partner_pdf_download_button")}
                </StyledPDFDownloadLink>
              </div>
            )}
          </CareEventContainer>
        )}
      </>
    </TodoItem>
  );
};

export default PhysicianMedicalReferralItem;
