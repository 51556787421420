import type React from "react";
import { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";

import useGetCareEvent from "api/hooks/useGetCareEvent";
import useGetCareEventsBilling from "api/hooks/useGetCareEventsBilling";
import { usePutCareEventBilling } from "api/hooks/usePutCareEventBilling";
import type { CareEventBilling, Status } from "api/schemas/CareEventBilling";
import { Header } from "routes/calendar/components/EventDetails/styles";
import { TypeWrapper } from "routes/dashboard/default/components/MedicalNotesTable/styles";
import DynamicMedicalNoteForm from "routes/patients/PatientProfile/components/PatientHeader/SignNotesTable/components/DynamicMedicalNoteForm";
import { BaseButton } from "shared/atoms/BaseButton";
import { Notification } from "shared/atoms/Notification";
import Popup from "shared/atoms/Popup";
import { CurrentPatientContext } from "utils/contexts";
import { reportError } from "utils/errorReporting";

import { Table } from "../components/Table";

export type Dialog = {
  show: boolean;
  status: Status | null;
};

const initialDialogState: Dialog = {
  show: false,
  status: null,
};

type StatusCounts = Record<Status, number>;

export const AdminBillingView: React.VFC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { patient, setPatientId } = useContext(CurrentPatientContext);
  const [notes, setNotes] = useState<Array<CareEventBilling>>([]);
  const [statusCounts, setStatusCounts] = useState<StatusCounts>({ completed: 0, discarded: 0, pending: 0 });
  const [filter, setFilter] = useState<Status>("pending");
  const [selectedCareEvent, setSelectedCareEvent] = useState<CareEventBilling | null>(null);
  const [selectedCareEventIDs, setSelectedCareEventIDs] = useState<number[]>([]);
  const [showCareEvent, setShowCareEvent] = useState(false);
  const [showDialog, setShowDialog] = useState<Dialog>(initialDialogState);
  const [error, setError] = useState<string | null>(null);

  const { data: careEvent } = useGetCareEvent(selectedCareEvent ? selectedCareEvent?.care_event_id : 0, {
    enabled: selectedCareEvent !== null,
  });
  const putCareEventBilling = usePutCareEventBilling();
  const { isLoading } = putCareEventBilling;

  const { data } = useGetCareEventsBilling();

  useEffect(() => {
    if (data) {
      const statusCountsObj = data.reduce((counts, obj) => {
        const { status } = obj;
        return { ...counts, [status]: (counts[status] || 0) + 1 }; // Increment the count for this status
      }, {} as StatusCounts);
      setStatusCounts(statusCountsObj);

      let filteredNotes = data;

      switch (filter) {
        case "completed":
          filteredNotes = filteredNotes.filter(note => note.status === "completed");
          break;
        case "discarded":
          filteredNotes = filteredNotes.filter(note => note.status === "discarded");
          break;
        case "pending":
          filteredNotes = filteredNotes.filter(note => note.status === "pending");
          break;
        default:
          break;
      }

      setNotes(filteredNotes);
    }
  }, [data, filter]);

  useEffect(() => {
    if (careEvent) {
      setPatientId(careEvent.patient_id);
    } else {
      setPatientId(null);
    }
  }, [careEvent, setPatientId]);

  const updateCareEventBillingStatus = async (status: Status) => {
    try {
      if (selectedCareEventIDs.length > 0) {
        putCareEventBilling.mutateAsync(
          {
            data: { care_event_ids: selectedCareEventIDs, status },
          },
          {
            onSuccess: () => {
              setSelectedCareEventIDs([]);
              closePopup();
            },
            onError: e => {
              reportError("AdminBillingView.tsx", e);
              setError(t("errors.generic"));
            },
          }
        );
      }
    } catch (e) {
      setError(t("errors.generic"));
      if (e instanceof Error || typeof e === "string") {
        reportError("AdminBillingView.tsx", e);
      }
    }
  };

  const clearSelectedCareEvent = () => {
    setSelectedCareEvent(null);
  };

  const closePopup = () => {
    setShowDialog(initialDialogState);
  };

  return (
    <>
      <PageContainer>
        <Container>
          <Filters>
            <FilterButtonContainer $active={filter === "pending"}>
              <BaseButton
                text={t("admin_billing.buttons.pending")}
                onClick={() => setFilter("pending")}
                variant="tertiary"
                icon="list"
                iconPlacement="start"
              />
              {statusCounts.pending > 0 && <Counter>{statusCounts.pending}</Counter>}
            </FilterButtonContainer>
            <FilterButtonContainer $active={filter === "completed"}>
              <BaseButton
                text={t("admin_billing.buttons.completed")}
                onClick={() => setFilter("completed")}
                variant="tertiary"
                icon="circle-check"
                iconPlacement="start"
              />
              {statusCounts.completed > 0 && <Counter>{statusCounts.completed}</Counter>}
            </FilterButtonContainer>
            <FilterButtonContainer $active={filter === "discarded"}>
              <BaseButton
                text={t("admin_billing.buttons.discarded")}
                onClick={() => setFilter("discarded")}
                variant="tertiary"
                icon="box-archive"
                iconPlacement="start"
              />
              {statusCounts.discarded > 0 && <Counter>{statusCounts.discarded}</Counter>}
            </FilterButtonContainer>
          </Filters>

          <ActionsContainer>
            {selectedCareEventIDs.length > 0 && (
              <Actions>
                <Label>{t("admin_billing.bulk_actions")}</Label>
                <BaseButton
                  text="Complete"
                  onClick={() => {
                    setShowDialog({ show: true, status: "completed" });
                  }}
                  variant="tertiary"
                  icon="circle-check"
                  iconPlacement="start"
                  style={{ color: theme.colors.redesign.g100 }}
                />
                <BaseButton
                  text="Discard"
                  onClick={() => {
                    setShowDialog({ show: true, status: "discarded" });
                  }}
                  variant="tertiary"
                  icon="box-archive"
                  iconPlacement="start"
                  style={{ color: theme.colors.redesign.r100 }}
                />
              </Actions>
            )}
          </ActionsContainer>

          <Table
            data={notes}
            filter={filter}
            setSelectedCareEvent={setSelectedCareEvent}
            selectedCareEventIDs={selectedCareEventIDs}
            setSelectedCareEventIDs={setSelectedCareEventIDs}
            setShowCareEvent={setShowCareEvent}
            setShowDialog={setShowDialog}
          />
        </Container>
      </PageContainer>

      {careEvent && showCareEvent && patient && (
        <Popup
          fullBgOpacity
          onClickOutside={() => {
            setSelectedCareEvent(null);
            setShowCareEvent(false);
          }}
          showCloseButton
        >
          <CareEventContainer>
            <DynamicMedicalNoteForm
              careEvent={{
                ...careEvent,
                medical_referral_id: selectedCareEvent?.medical_referral_id,
                plan_of_care_id: selectedCareEvent?.plan_of_care_id,
              }}
              animate
              open
              isAdminBillingView
              billingStatus={selectedCareEvent?.status}
              skipMerge
              showNoteHeader
              readOnly
              clearSelectedCareEvent={clearSelectedCareEvent}
            />
          </CareEventContainer>
        </Popup>
      )}

      {showDialog.show && showDialog.status && (
        <Popup fullBgOpacity onClickOutside={closePopup} showCloseButton>
          <PopupContent>
            <Header>
              {showDialog.status === "completed"
                ? t("admin_billing.mark_as_completed", { count: selectedCareEventIDs.length })
                : t("admin_billing.mark_as_discarded", { count: selectedCareEventIDs.length })}
            </Header>
            <BaseButton
              text={t("buttons.yes")}
              onClick={() => updateCareEventBillingStatus(showDialog.status as Status)}
              disabled={isLoading}
              loading={isLoading}
              uppercase
              style={{ marginBottom: "20px" }}
            />
            <BaseButton
              text={t("buttons.cancel")}
              onClick={closePopup}
              disabled={isLoading}
              variant="tertiaryBlue"
              uppercase
            />
            {error && (
              <Notification variant="danger" style={{ marginTop: "16px" }}>
                {error}
              </Notification>
            )}
          </PopupContent>
        </Popup>
      )}
    </>
  );
};

const PageContainer = styled.div`
  height: 100vh;
  overflow-y: auto;
`;

const Container = styled.div`
  max-width: 1024px;
  margin: 68px auto 0;
  padding: 0 24px 24px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.redesign.db20};
  border-radius: 8px;
  position: relative;

  ${props => props.theme.belowBreakpoint} {
    margin: 0;
    padding: 0;
  }
`;

const Filters = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.redesign.db20};
`;

const FilterButtonContainer = styled.div<{ $active: boolean }>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  & > button {
    height: 100%;
    padding: 16px 0;
    color: ${({ theme, $active }) => $active && theme.colors.redesign.b100};
  }
`;

const Counter = styled.div`
  color: ${({ theme }) => theme.colors.redesign.r80};
  font-size: 16px;
  font-weight: normal;
  margin-left: 8px;
`;

export const StyledTypeWrapper = styled(TypeWrapper)`
  width: auto;
`;

export const ButtonContainer = styled.div`
  cursor: pointer;
`;

export const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 430px;
  padding: 40px 26px 32px;
  text-align: center;
`;

export const CareEventContainer = styled.div`
  width: 770px;
  overflow-y: auto;

  ${props => props.theme.belowBreakpoint} {
    width: auto;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 56px;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  background: rgba(150, 192, 241, 0.08);
  border-radius: 8px;

  & > button {
    margin-right: 16px;
  }
`;

const Label = styled.div`
  margin-right: 8px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primary.base};
`;
