import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import invariant from "ts-invariant";

import type { Therapist } from "api/models/Therapist";
import { JoiningJA } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import { PrimaryButton, SecondaryButton } from "shared/atoms/Button";
import { getUserData } from "shared/ProtectedRoute/queries";
import { removeAuthCookies } from "utils/cookie";
import { userIsPartner, userIsPhysician } from "utils/profile/profileHelper";

import { OnboardingButtons, OnboardingHeader, OnboardingMessage } from "../components";
import completeOnboarding from "../queries/completeOnboarding";

const Complete = (): JSX.Element => {
  const { profile, setProfile } = useProfileContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isPartner = userIsPartner(profile);
  const isPhysician = userIsPhysician(profile);

  invariant(profile);

  const onboard = () => {
    completeOnboarding({ profileId: profile.id, isPartner, isPhysician })
      .then(() => {
        getUserData()
          .then(response => {
            const {
              data: { data },
            } = response;

            setProfile(data as unknown as Therapist);
            if (data.state === "ready") {
              navigate("/dashboard");
            }
          })
          .catch(() => {
            removeAuthCookies();
          });
      })
      .catch(error => {
        if (error?.response?.status && error.response.status === 406) {
          navigate("/dashboard");
        }
      });
  };

  useEffect(() => {
    if (isPartner || isPhysician) {
      onboard();
    }
  }, []);

  return isPartner || isPhysician ? (
    <></>
  ) : (
    <>
      <StyledJoiningJA alt="Joining Joint Academy" src={JoiningJA} />

      <OnboardingHeader>{t("onboarding.4.title")}</OnboardingHeader>

      <OnboardingMessage data-testid="onboarding-complete-page-body">{t("onboarding.4.body")}</OnboardingMessage>

      <OnboardingButtons>
        <SecondaryButton data-testid="onboarding-complete-back-btn" onClick={() => navigate("/onboarding/about-you")}>
          <span>{t("buttons.back")}</span>
        </SecondaryButton>

        <PrimaryButton data-testid="onboarding-complete-page-next-btn" onClick={onboard}>
          <span>{t("onboarding.4.complete")}</span>
        </PrimaryButton>
      </OnboardingButtons>
    </>
  );
};

export default Complete;

const StyledJoiningJA = styled.img`
  display: block;
  max-width: 401px;
  width: 100%;
  margin: ${props => props.theme.spacing.S_20} auto 0;

  ${props => props.theme.belowMobileBreakpoint} {
    max-width: 265px;
  }
`;
