import type React from "react";
import { useContext } from "react";

import type { PrimaryJoint } from "api/schemas/Patients";
import without from "utils/array/without";
import { PatientsFilterContext } from "utils/contexts";

import JointLabel from "../JointLabel";

import { StyledCheckBox } from "./helpers";
import stringifyJoint from "./stringifyJoint";
import type { Filters } from "./useFilters";

type PrimaryJointFilterProps = {
  joints: PrimaryJoint[];
};

const PrimaryJointsFilter: React.VFC<PrimaryJointFilterProps> = ({ joints }) => {
  const { getFilterState, setFilter } = useContext<Filters>(PatientsFilterContext);

  const togglePrimaryJointSelection = (jointId: string) => {
    const { selection } = getFilterState("primary_joint");

    if (selection) {
      const isSelected = selection.includes(jointId);
      const nextSelection = isSelected ? without(selection, jointId) : [...selection, jointId];
      const nextState = {
        active: nextSelection.length > 0,
        selection: nextSelection,
      };

      setFilter("primary_joint", nextState);
    }
  };

  const isPrimaryJointSelected = (jointId: string) => {
    const { selection } = getFilterState("primary_joint");

    return selection ? selection.includes(jointId) : false;
  };

  return (
    <>
      {joints.map((joint: PrimaryJoint) => {
        const jointId = stringifyJoint(joint);
        const isSelected = isPrimaryJointSelected(jointId);

        return (
          <StyledCheckBox
            key={`filter-${jointId}`}
            name={`filter-${jointId}`}
            label=""
            checked={isSelected}
            onChange={() => {
              togglePrimaryJointSelection(jointId);
            }}
          >
            <JointLabel joint={joint} />
          </StyledCheckBox>
        );
      })}
    </>
  );
};

export default PrimaryJointsFilter;
