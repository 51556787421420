import type React from "react";
import { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useMatch, useNavigate } from "react-router-dom";
import styled, { ThemeContext } from "styled-components";
import invariant from "ts-invariant";

import useGetPatients from "api/hooks/useGetPatients";
import type { Patients } from "api/schemas/Patients";
import { FilterIcon } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import Divider from "shared/atoms/Divider";
import FilterTag from "shared/atoms/FilterTag";
import IconButton from "shared/atoms/IconButton";
import VideoCallAwareContainer from "shared/atoms/VideoCallAwareContainer/VideoCallAwareContainer";
import usePageContentOffsetTop from "shared/styles/usePageContentOffsetTop";
import { AnalyticsPages, AnalyticsService } from "utils/analytics";
import { PatientsFilterContext } from "utils/contexts";
import { useQueryParams, useWindowSize } from "utils/hooks";
import joinUrl from "utils/url/joinUrl";

import { PatientsFilter, PatientsTable } from "../PatientsList";
import type { Filter, Filters } from "../PatientsList/PatientsFilter/useFilters";

const PatientsList: React.VFC = () => {
  const theme = useContext(ThemeContext);
  const { width } = useWindowSize();
  const { t } = useTranslation();
  const match = useMatch("*");
  const params = useQueryParams();
  const navigate = useNavigate();
  const { activeFilters, matchesActiveFilters, filterLabel, toggleFilter, setFilter } =
    useContext<Filters>(PatientsFilterContext);
  const offsetTop = usePageContentOffsetTop();
  const [isOpen, setIsOpen] = useState(false);
  const [patients, setPatients] = useState<Patients[]>([]);
  const { profile } = useProfileContext();
  invariant(profile);

  const { data = [], isLoading } = useGetPatients({
    userID: profile.id,
    config: {
      params: {
        enabled_columns: ["primaryJoint", "lastActivityCompletedAt", "activityAdherence", "addressState"],
      },
    },
  });

  useEffect(() => {
    if (data.length > 0) {
      const filtered = data.filter(matchesActiveFilters);
      setPatients(filtered);
    }
  }, [data, matchesActiveFilters]);

  useEffect(() => {
    if (match) {
      AnalyticsService.viewedPage(AnalyticsPages.PATIENTS.PATIENTS_LIST, match.pathname);
    }
  }, [match?.pathname]);

  const breakpointMobile = Number.parseInt(theme.breakpointMobile, 10);

  const closeFilterTag = (key: Filter) => {
    switch (key) {
      case "primary_joint":
        setFilter("primary_joint", { active: false, selection: [] });
        break;
      default:
        toggleFilter(key);
    }
    if (params.get("filter") === key && match) {
      params.delete("filter");
      navigate(joinUrl(match?.pathname, params));
    }
  };
  const renderedTags = activeFilters.map((key: Filter) => {
    return <FilterTag key={key} close={() => closeFilterTag(key)} label={filterLabel(key)} />;
  });

  return (
    <StyledDiv $pageContentOffsetTop={offsetTop} data-testid="patients-list">
      <PageWrapper $pageContentOffsetTop={offsetTop}>
        <PatientsFilter open={isOpen} close={() => setIsOpen(false)} patients={data} />
        <ListContainer>
          <Container>
            <IconButtonWrapper>
              <IconButton
                dataTestId="expand-filters"
                label={t("patients.filters")}
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
              >
                <FilterIcon />
              </IconButton>
            </IconButtonWrapper>
            {width >= breakpointMobile && <TagsContainer>{renderedTags}</TagsContainer>}
          </Container>
          {width < breakpointMobile && (
            <TagsContainer $haveFilters={activeFilters.length > 0}>{renderedTags}</TagsContainer>
          )}
          <Divider />
          <PatientsTable isLoading={isLoading} patients={patients} />
        </ListContainer>
      </PageWrapper>
    </StyledDiv>
  );
};

type StyledDivProps = {
  $pageContentOffsetTop: number;
};

const StyledDiv = styled.div<StyledDivProps>`
  background-color: ${props => props.theme.colors.greys.light2};
  width: 100%;
  display: block;
  box-sizing: border-box;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-top: ${props => props.$pageContentOffsetTop}px;
  height: 100vh;
`;

type PageWrapperProps = {
  $pageContentOffsetTop: number;
};

const PageWrapper = styled.div<PageWrapperProps>`
  display: flex;
  flex-direction: row;
`;

type IconButtonWrapperProps = {
  $leftMargin?: boolean;
};

const IconButtonWrapper = styled.div<IconButtonWrapperProps>`
  padding: ${props => props.theme.spacing.S_5} 0;
  margin-left: ${props => (props.$leftMargin ? "auto" : "0")};
  height: 32px;
  display: flex;
  align-items: center;
`;

const ListContainer = styled(VideoCallAwareContainer)`
  flex: 3;
  box-sizing: border-box;
  width: 100%;
  padding: ${props => props.theme.spacing.S_10} ${props => props.theme.spacing.S_15};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

type TagsContainerProps = {
  $haveFilters?: boolean;
};

const TagsContainer = styled.div<TagsContainerProps>`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  min-height: 42px;
  margin: 0 ${props => props.theme.spacing.S_15};
  ${props => props.theme.belowMobileBreakpoint} {
    min-height: ${props => (props.$haveFilters ? "42px" : "auto")};
    margin: 0 0 ${props => props.theme.spacing.S_10} 0;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: ${props => props.theme.spacing.S_10} 0;
  ${props => props.theme.belowMobileBreakpoint} {
    margin: ${props => props.theme.spacing.S_5} 0;
  }
  min-height: 42px;
`;

export default PatientsList;
