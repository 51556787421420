import type React from "react";

import styled from "styled-components";

import { useGetPhysicianHandledPatient } from "api/hooks/useGetPhysicianHandledPatient";
import Spinner from "shared/atoms/Spinner";
import { Table } from "shared/molecules/Table";

import PhysicianMedicalReferralItem from "../../../PatientHeader/PhysicianToDoTable/components/PhysicianMedicalReferralItem";
import PlanOfCareItem from "../../../PatientHeader/PhysicianToDoTable/components/PlanOfCareItem";

interface Props {
  $isFullWidthOnMobile?: boolean;
  patientId: number;
}

const PhysicanToDoTable: React.VFC<Props> = ({ $isFullWidthOnMobile = false, patientId }) => {
  const { data, isLoading } = useGetPhysicianHandledPatient(patientId);
  const {
    medical_referral_status,
    plan_of_care_status,
    medical_referral_id,
    plan_of_care_id,
    plan_of_care_handled_at,
    referral_handled_at,
  } = data || {};

  if (isLoading) {
    return <Spinner small />;
  }

  if (!data) {
    return null;
  }

  return (
    <TableContainer>
      <Table overflow="visible" $isFullWidthOnMobile={$isFullWidthOnMobile}>
        <tbody>
          <DividerRow>
            <td>
              <PlanOfCareItem
                patientId={patientId}
                planOfCareId={plan_of_care_id}
                date={plan_of_care_handled_at}
                status={plan_of_care_status || "pending"}
              />
            </td>
          </DividerRow>
          <DividerRow>
            <td>
              <PhysicianMedicalReferralItem
                patientId={patientId}
                medicalReferralId={medical_referral_id}
                date={referral_handled_at}
                status={medical_referral_status || "pending"}
              />
            </td>
          </DividerRow>
        </tbody>
      </Table>
    </TableContainer>
  );
};

const TableContainer = styled.div`
  margin: 32px 0;
`;

const DividerRow = styled.tr`
  border-bottom: 1px solid #e0e0e0;

  &:last-of-type {
    border-bottom: none;
  }
`;

export default PhysicanToDoTable;
