import type React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useFeatureToggleContext } from "contexts/FeatureToggleContext";
import { Notification } from "shared/atoms/Notification";
import { Feature } from "utils/features/types";

import { ErrorWrapper } from "./helpers";
import PatientDataList from "./PatientDataList";
import PatientHeaderDropdown from "./PatientHeaderDropdown/PatientHeaderDropdown";
import PatientStickyNote from "./PatientStickyNote/PatientStickyNote";

const Container = styled.div`
  padding-bottom: 20px;
`;

interface Props {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly patientError?: Error | null;
  useToggleIcon?: boolean;
  useBackgroundOverlay?: boolean;
}

const PatientHeaderDropdownInfo: React.VFC<Props> = ({
  isOpen,
  onClose,
  patientError = null,
  useToggleIcon = false,
  useBackgroundOverlay = true,
}) => {
  const { t } = useTranslation();
  const { hasFeature } = useFeatureToggleContext();

  return (
    <PatientHeaderDropdown
      useBackgroundOverlay={useBackgroundOverlay}
      useToggleIcon={useToggleIcon}
      open={isOpen}
      closeMenu={onClose}
    >
      {patientError ? (
        <ErrorWrapper>
          <Notification variant="danger" style={{ margin: "10px" }}>
            {t("errors.generic")}
          </Notification>
        </ErrorWrapper>
      ) : (
        <Container>
          <PatientDataList />
          {hasFeature(Feature.PATIENT_STICKY_NOTE) && <PatientStickyNote />}
        </Container>
      )}
    </PatientHeaderDropdown>
  );
};

export default PatientHeaderDropdownInfo;
