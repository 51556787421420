import { useMemo, useState } from "react";

import type { GridColDef } from "@mui/x-data-grid";
import { format, parseISO } from "date-fns";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import useGetPartnerInvitedPatients from "api/hooks/useGetPartnerInvitedPatients";
import { MUIDataGrid } from "shared/molecules/MUIDataGrid";

import { HeaderContainer, Title, Wrapper } from "../helpers";

const paginationModel = { page: 0, pageSize: 20 };

export const InvitationList: React.VFC = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(paginationModel.page);
  const { data, isLoading } = useGetPartnerInvitedPatients(page + 1);
  const rows = data?.invitations || [];
  const metadata = data?.metadata;

  const columns: GridColDef[] = useMemo(
    () => [
      { field: "id", headerName: t("clinic_partner.invitation_list.id"), flex: 1 },
      { field: "patient_name", headerName: t("clinic_partner.invitation_list.patient_name"), flex: 1 },
      {
        field: "sent_at",
        headerName: t("clinic_partner.invitation_list.sent_at"),
        valueFormatter: (value: string) => (value ? format(parseISO(value), "PP") : ""),
        flex: 1,
      },
    ],
    []
  );

  return (
    <Container>
      <HeaderContainer>
        <Title>{t("clinic_partner.invitation_list.header")}</Title>
      </HeaderContainer>
      <Wrapper>
        <MUIDataGrid
          columns={columns}
          rows={rows}
          loading={isLoading}
          initialState={{ pagination: { paginationModel } }}
          paginationMode="server"
          onPaginationModelChange={model => setPage(model.page)}
          rowCount={metadata?.total_count}
          rowSelection={false}
          localeText={{ noRowsLabel: t("clinic_partner.invitation_list.no_rows") as string }}
          sx={{
            border: 0,
            "& .MuiDataGrid-row:hover": {
              backgroundColor: "inherit",
            },
          }}
        />
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 40px;
`;
