// REACT_APP_MARKET uses UK, while BE uses GB. UK should be retired once env var is removed.
// MarketTypes also works as a map so we can use { ..., UK: "GB", ... } and by using the
// getAppMarket from marketHelper we will get the corrected market
export const MarketTypes = {
  SE: "SE",
  GB: "GB",
  US: "US",
  UK: "GB",
} as const;

export type Market = typeof MarketTypes[keyof typeof MarketTypes];
export type MarketMap = keyof typeof MarketTypes;

export const isTypeMarket = (value: unknown): value is Market => Object.values(MarketTypes).includes(value as Market);
export const isTypeMarketMap = (value: unknown): value is MarketMap =>
  Object.keys(MarketTypes).includes(value as MarketMap);

export type ConfigMarket = Market | "GLOBAL";
