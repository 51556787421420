import type { UseMutationOptions, UseMutationResult } from "react-query";
import { useMutation, useQueryClient } from "react-query";
import invariant from "ts-invariant";

import type { PhysicianMedicalReferral, PhysicianPlanOfCare } from "api/schemas/physician";
import type { GetPatientCareEventsResponse } from "api/types/GetPatientCareEvents";
import type { PutPatientCareEventArgs, PutPatientCareEventResponse } from "api/types/PutPatientCareEvent";
import { useProfileContext } from "contexts/ProfileContext";
import {
  applyChanges,
  reduceCareEventToFields,
} from "routes/patients/PatientProfile/components/DynamicMedicalNotes/helpers/careEventHelpers";
import { getEditableFieldNamesFromConfig } from "routes/patients/PatientProfile/components/DynamicMedicalNotes/helpers/dynamicMedicalNoteHelpers";
import { type Market, MedicalNoteTypes } from "types";
import { reportError } from "utils/errorReporting";
import { getMedicalNoteConfig } from "utils/medicalNotes";

import useApiClient from "../useApiClient";

import { getCareEventQueryKey } from "./useGetCareEvent";
import { getPatientCareEventsQueryKey, mergePatientCareEvent } from "./useGetPatientCareEventsQuery";
import { getPTCareEventsQueryKey } from "./useGetPTCareEventsQuery";
import { usePutPhysicianMedicalReferral } from "./usePutPhysicianMedicalReferral";
import { usePutPhysicianPlanOfCare } from "./usePutPhysicianPlanOfCare";

const usePutPatientCareEventMutation = (
  options?: UseMutationOptions<PutPatientCareEventResponse, Error, PutPatientCareEventArgs>
): UseMutationResult<PutPatientCareEventResponse, Error, PutPatientCareEventArgs> => {
  const client = useApiClient();

  return useMutation<PutPatientCareEventResponse, Error, PutPatientCareEventArgs>(
    vars => client.putPatientCareEvent(vars),
    options
  );
};

export default usePutPatientCareEventMutation;

// Abstraction with minimal dependencies for optimistically updating a medical note in a safe way
interface InnerArgs extends PutPatientCareEventArgs {
  rethrow?: boolean;
  skipInvalidation?: boolean;
}

type ReturnValue = Omit<
  UseMutationResult<PutPatientCareEventResponse, Error, PutPatientCareEventArgs>,
  "mutate" | "mutateAsync"
> & {
  mergeCareEvent: (args: InnerArgs) => Promise<void>;
};

interface Args {
  label: string;
  patient_id: number;
  skipMerge?: boolean;
}

export const useMergeCareEvent = ({ label, patient_id, skipMerge }: Args): ReturnValue => {
  const { profile } = useProfileContext();
  invariant(profile, "Profile context not set");
  const key = getPatientCareEventsQueryKey({ patientId: patient_id, signed: false });
  const queryClient = useQueryClient();
  const { mutateAsync, ...rest } = usePutPatientCareEventMutation({
    retry: (failureCount, error) => {
      reportError("DynamicMedicalNoteForm Autosave Error", error, {
        action: "usePutPatientCareEventMutation",
      });
      return failureCount < 1;
    },
  });
  const { mutateAsync: putPhysicianMedicalReferral } = usePutPhysicianMedicalReferral();
  const { mutateAsync: putPhysicianPlanOfCare } = usePutPhysicianPlanOfCare();

  const invalidate = () => {
    queryClient.invalidateQueries(key);
    queryClient.invalidateQueries(getPTCareEventsQueryKey({ userId: profile.id }));
  };

  const mergeCareEvent = async ({ careEventId, data, skipInvalidation, rethrow }: InnerArgs) => {
    if (skipMerge) {
      if (label === MedicalNoteTypes.PHYSICIAN_MEDICAL_REFERRAL) {
        try {
          await putPhysicianMedicalReferral({
            medicalReferralID: careEventId,
            data: { ...(data as PhysicianMedicalReferral), status: "pending" },
          });
          return;
        } catch (error) {
          if (rethrow) throw error;
        }
      }

      if (label === MedicalNoteTypes.PHYSICIAN_PLAN_OF_CARE) {
        try {
          await putPhysicianPlanOfCare({
            planOfCareID: careEventId,
            data: { ...(data as PhysicianPlanOfCare), status: "pending" },
          });
          return;
        } catch (error) {
          if (rethrow) throw error;
        }
      }

      try {
        await mutateAsync(
          { careEventId, data },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(getCareEventQueryKey(careEventId));
            },
          }
        );
        return;
      } catch (error) {
        if (rethrow) throw error;
      }
    }

    queryClient.cancelQueries(key);
    const rollbackData = queryClient.getQueryData<GetPatientCareEventsResponse>(key);
    queryClient.setQueryData(key, mergePatientCareEvent(careEventId, data));
    const reference = rollbackData?.find(careEvent => careEvent.id === careEventId);
    invariant(reference, `Could not find care event with id ${careEventId}`);
    const updatedCareEvent = applyChanges(reference, data);
    const config = getMedicalNoteConfig(profile.market as Market, reference.label);
    const fieldNames = getEditableFieldNamesFromConfig(config, true);
    const editableValues = reduceCareEventToFields(fieldNames, updatedCareEvent);

    try {
      await mutateAsync({ careEventId, data: editableValues });
    } catch (error) {
      queryClient.setQueryData(key, rollbackData);
      if (rethrow) throw error;
    } finally {
      if (!skipInvalidation) invalidate();
    }
  };

  return { mergeCareEvent, ...rest };
};
