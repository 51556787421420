import type { UseQueryOptions } from "react-query";
import { useQuery } from "react-query";

import type { PhysicianPlanOfCare } from "api/schemas/physician";
import useApiClient from "api/useApiClient";

type QueryKey = ["getManagerPlanOfCare", number];
export const getManagerPlanOfCareQueryKey = (planOfCareID: number): QueryKey => ["getManagerPlanOfCare", planOfCareID];

export const useGetManagerPlanOfCare = (
  planOfCareID: number,
  options?: UseQueryOptions<PhysicianPlanOfCare, Error, PhysicianPlanOfCare, QueryKey>
) => {
  const client = useApiClient();

  return useQuery(getManagerPlanOfCareQueryKey(planOfCareID), () => client.getManagerPlanOfCare(planOfCareID), options);
};
