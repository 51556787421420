import { useState } from "react";

import type { PaginationArgs } from "api/schemas/common";

export const paginationModel = { page: 0, pageSize: 20 };

export const usePagination = () => {
  const [pagination, setPagination] = useState<PaginationArgs>({
    limit: paginationModel.pageSize,
    page: paginationModel.page,
  });

  return { pagination, setPagination };
};
