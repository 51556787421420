import type React from "react";

import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { faCircleCheck, faCircleXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { type GridColDef } from "@mui/x-data-grid";
import { format, parseISO } from "date-fns";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";

import type { CareEventBilling, Status } from "api/schemas/CareEventBilling";
import { CPTCode } from "routes/dashboard/default/components/MedicalNotesTable/CPTCode";
import { MUIDataGrid } from "shared/molecules/MUIDataGrid";
import { useWindowSize } from "utils/hooks";

import type { Dialog } from "../views/AdminBillingView";
import { ButtonContainer } from "../views/AdminBillingView";

const paginationModel = { page: 0, pageSize: 10 };

interface Props {
  data: CareEventBilling[];
  filter: Status;
  setSelectedCareEvent: (careEvent: CareEventBilling) => void;
  selectedCareEventIDs: number[];
  setSelectedCareEventIDs: (ids: number[]) => void;
  setShowCareEvent: (show: boolean) => void;
  setShowDialog: (value: Dialog) => void;
}

export const Table: React.VFC<Props> = ({
  data,
  filter,
  setSelectedCareEvent,
  selectedCareEventIDs,
  setSelectedCareEventIDs,
  setShowCareEvent,
  setShowDialog,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isSmallScreen } = useWindowSize();

  const getColumns = (): GridColDef[] => {
    let columns: GridColDef[] = [
      {
        field: "patient_name",
        headerName: t("dashboard.tables.notes.labels.patient"),
        flex: 1,
        ...(isSmallScreen && { minWidth: 120 }),
      },
      {
        field: "icd_codes",
        headerName: t("dashboard.tables.notes.labels.icd"),
        valueFormatter: value => value?.[0],
        flex: 1,
        ...(isSmallScreen && { minWidth: 120 }),
      },
      {
        field: "cpt_codes",
        headerName: t("dashboard.tables.notes.labels.cpt"),
        renderCell: ({ value }) => <CPTCode cptCode={value?.[0]} />,
        flex: 1,
        ...(isSmallScreen && { minWidth: 120 }),
      },
      {
        field: "type",
        headerName: t("dashboard.tables.notes.labels.type"),
        flex: 1,
        ...(isSmallScreen && { minWidth: 120 }),
      },
      {
        field: "therapist_name",
        headerName: t("dashboard.tables.notes.labels.assigned_therapist"),
        flex: 1,
        ...(isSmallScreen && { minWidth: 120 }),
      },
    ];

    if (filter === "pending") {
      columns = columns.concat([
        {
          field: "created_at",
          headerName: t("dashboard.tables.notes.labels.created_at"),
          valueFormatter: value => (value ? format(parseISO(value), "PP") : ""),
          flex: 1,
          ...(isSmallScreen && { minWidth: 120 }),
        },
        {
          field: "signed_at",
          headerName: t("dashboard.tables.notes.labels.signed_at"),
          valueFormatter: value => (value ? format(parseISO(value), "PP") : ""),
          flex: 1,
          ...(isSmallScreen && { minWidth: 120 }),
        },
        {
          field: "_complete",
          type: "actions",
          headerName: t("dashboard.tables.notes.labels.complete"),
          renderCell: value => {
            return (
              <ButtonContainer
                onClick={() => {
                  setSelectedCareEventIDs([value.row.care_event_id]);
                  setShowDialog({ show: true, status: "completed" });
                }}
              >
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  color={theme.colors.redesign.g100}
                  style={{ height: "18px", width: "18px" }}
                />
              </ButtonContainer>
            );
          },
          disableColumnMenu: true,
          sortable: false,
          flex: 1,
          ...(isSmallScreen && { minWidth: 120 }),
        },
        {
          field: "_discard",
          type: "actions",
          headerName: t("dashboard.tables.notes.labels.discard"),
          renderCell: value => {
            return (
              <ButtonContainer
                onClick={() => {
                  setSelectedCareEventIDs([value.row.care_event_id]);
                  setShowDialog({ show: true, status: "discarded" });
                }}
              >
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  color={theme.colors.redesign.r100}
                  style={{ height: "18px", width: "18px" }}
                />
              </ButtonContainer>
            );
          },
          disableColumnMenu: true,
          sortable: false,
          flex: 1,
          ...(isSmallScreen && { minWidth: 120 }),
        },
      ]);
    } else {
      columns = columns.concat([
        {
          field: "manager_name",
          headerName: t("dashboard.tables.notes.labels.handled_by"),
          flex: 1,
          ...(isSmallScreen && { minWidth: 120 }),
        },
        {
          field: "handled_at",
          headerName: t("dashboard.tables.notes.labels.handled_at"),
          valueFormatter: value => (value ? format(parseISO(value), "PP") : ""),
          flex: 1,
          ...(isSmallScreen && { minWidth: 120 }),
        },
      ]);
    }

    columns.push({
      field: "_view",
      type: "actions",
      headerName: t("dashboard.tables.notes.labels.view"),
      renderCell: value => {
        return (
          <ButtonContainer
            onClick={() => {
              setSelectedCareEvent(value.row);
              setShowCareEvent(true);
            }}
          >
            <FontAwesomeIcon icon={faSearch} color={theme.colors.black} style={{ height: "18px", width: "18px" }} />
          </ButtonContainer>
        );
      },
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      ...(isSmallScreen && { minWidth: 120 }),
    });

    return columns;
  };

  return (
    <MUIDataGrid
      rows={data}
      columns={getColumns()}
      getRowId={row => row.care_event_id}
      rowSelectionModel={selectedCareEventIDs}
      onRowSelectionModelChange={rowSelectionModel => {
        setSelectedCareEventIDs(rowSelectionModel as number[]);
      }}
      initialState={{ pagination: { paginationModel } }}
      checkboxSelection
    />
  );
};
