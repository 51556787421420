import type React from "react";

import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { LoginPageWrapper } from "routes/login/components/LoginPageWrapper";

import EmailSignup from "./views/EmailSignup";

const SignupRoutes: React.VFC = () => {
  const { search } = useLocation();

  return (
    <LoginPageWrapper>
      <Routes>
        <Route
          path="therapist/bankid"
          element={
            <Navigate
              to={{
                pathname: "/login/bankid",
                search,
              }}
            />
          }
        />
        <Route path="therapist/email" element={<EmailSignup />} />
        <Route path="doctor/email" element={<EmailSignup />} />
        <Route path="clinic_partner/email" element={<EmailSignup />} />
        <Route path="physician/email" element={<EmailSignup />} />
      </Routes>
    </LoginPageWrapper>
  );
};

export default SignupRoutes;
