import type React from "react";

import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import invariant from "ts-invariant";

import useGetPatients from "api/hooks/useGetPatients";
import { useFeatureToggleContext } from "contexts/FeatureToggleContext";
import { useProfileContext } from "contexts/ProfileContext";
import PremiumIcon from "shared/atoms/PremiumIcon";
import { MUIDataGrid } from "shared/molecules/MUIDataGrid";
import useLocalizedDate from "utils/date";
import { Feature } from "utils/features/types";
import isNonEmptyString from "utils/string/is-non-empty-string";

const paginationModel = { page: 0, pageSize: 10 };

const ReadyForDischargeTable: React.VFC = props => {
  const { t } = useTranslation();
  const { parseISO, format, formats } = useLocalizedDate();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { profile } = useProfileContext();
  invariant(profile, "Profile should be defined");

  const { hasFeature } = useFeatureToggleContext();
  const isSelfCareEnabled = hasFeature(Feature.SELFCARE);

  const { data: patients = [], isLoading } = useGetPatients(
    {
      userID: profile.id,
      config: {
        params: {
          enabled_columns: ["billableCareEvents", "premiumActivationOccurredAt"],
          include_deleted: false,
          ready_for_discharge: true,
        },
      },
    },
    { enabled: isSelfCareEnabled }
  );

  const navigateToPatientDetails = (patientId: number) =>
    navigate(`/patients/${patientId}`, {
      state: {
        pathname,
        text: "dashboard",
      },
    });

  if (!isSelfCareEnabled) {
    return null;
  }

  return (
    <div {...props}>
      <MUIDataGrid
        columns={[
          {
            field: "full_name",
            headerName: t("dashboard.tables.discharge.labels.patient"),
            valueGetter: (_value, row) => `${row.first_name || ""} ${row.last_name || ""}`,
            renderCell: ({ row }) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <PremiumIcon size="small" premiumType={row.premium_type} margin="0 4px 0 0 " />
                <Name $deleted={row.deleted}>
                  {`${row.first_name} ${row.last_name}`}
                  {row.deleted && <Closed data-testid="closed-account">{t("errors.account_closed")}</Closed>}
                </Name>
              </div>
            ),
            flex: 1,
          },
          { field: "billable_care_events", headerName: t("dashboard.tables.discharge.labels.careEvents"), flex: 1 },
          {
            field: "premium_activation_occurred_at",
            headerName: t("dashboard.tables.discharge.labels.date"),
            valueFormatter: value =>
              isNonEmptyString(value) ? format(parseISO(value), formats.LONG_LOCALIZED_DATE_PP) : "",
            flex: 1,
          },
        ]}
        rows={patients}
        loading={isLoading}
        onRowClick={row => navigateToPatientDetails(parseInt(row.id as string, 10))}
        initialState={{ pagination: { paginationModel } }}
        title={t("dashboard.tables.discharge.title")}
        notifications={patients?.length ?? 0}
      />
    </div>
  );
};

export default ReadyForDischargeTable;

const Name = styled.div<{ $deleted: boolean }>`
  color: ${props => props.theme.colors.primary.base};
  font-weight: ${props => props.theme.fontWeight.medium};
  display: flex;
  ${props => props.theme.belowBreakpoint} {
    flex-direction: column;
    color: ${props => (props.$deleted ? props.theme.colors.tomato : props.theme.colors.primary.base)};
  }
`;

const Closed = styled.div`
  margin-left: ${props => props.theme.spacing.S_10};
  color: ${props => props.theme.colors.tomato};
  font-weight: ${props => props.theme.fontWeight.regular};
  ${props => props.theme.belowBreakpoint} {
    display: none;
    margin-left: 0;
  }
`;
