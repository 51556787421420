import type { Patients } from "api/schemas/Patients";
import { DataRegionTypes } from "types";
import { getAppDataRegion } from "utils/region";

type TF = (k: string) => string;

const formatUSCoverage = (t: TF, patient: Patients) => {
  const insurance = patient.us_insurance_profiles?.[0];
  const providerGroup = patient.us_provider_group_profile?.name;
  if (providerGroup) {
    return providerGroup;
  }
  if (insurance) {
    const { insurance_company_name: name, medicare, status, insurance_company_supported } = insurance;
    let supported;
    if (insurance_company_supported !== undefined) {
      supported = `, ${
        insurance_company_supported
          ? t("patients.header_info.insurance_supported")
          : t("patients.header_info.insurance_unsupported")
      }`;
    }

    const formattedStatus = status ? ` (${t(`patients.header_info.insurance_status.${status}`)}${supported})` : "";
    const formattedName = medicare ? "Medicare" : name || "";

    return `${formattedName}${formattedStatus}`;
  }
  return "";
};

const formatUKCoverage = (patient: Patients) => {
  return patient.payer_name;
};

export const formatCoverage = (t: TF, patient: Patients) => {
  const currentMarket = getAppDataRegion();
  switch (currentMarket) {
    case DataRegionTypes.US:
      return formatUSCoverage(t, patient);
    case DataRegionTypes.UK:
      return formatUKCoverage(patient);
    default:
      return "";
  }
};
