import type React from "react";
import { useState } from "react";

import { parseISO } from "date-fns";
import { format } from "date-fns/format";
import { useTranslation } from "react-i18next";

import { useGetPatientIcdCodesQuery } from "api/hooks/useGetPatientIcdCodes";
import { usePostPhysicianPlanOfCare } from "api/hooks/usePostPhysicianPlanOfCare";
import type { CareEvent } from "api/models/CareEvent";
import type { Status } from "api/schemas/physician";
import useApiClient from "api/useApiClient";
import { CareEventContainer } from "routes/admin-billing/views/AdminBillingView";
import { StatusIcon } from "routes/dashboard/physician/components";
import { PlanOfCarePDF } from "routes/dashboard/physician/components/PDF";
import { StyledPDFDownloadLink } from "routes/dashboard/physician/components/PDF/styles";
import Spinner from "shared/atoms/Spinner";
import { MedicalNoteTypes } from "types";

import DynamicMedicalNoteForm from "../../SignNotesTable/components/DynamicMedicalNoteForm";

import TodoItem from "./TodoItem";

interface Props {
  patientId: number;
  planOfCareId: number | null | undefined;
  date: string | undefined;
  status: Status;
}

const PlanOfCareItem: React.VFC<Props> = ({ patientId, planOfCareId, date, status }) => {
  const { t } = useTranslation();
  const client = useApiClient();

  const [planOfCareCareEvent, setPlanOfCareCareEvent] = useState<CareEvent | null>(null);
  const [planOfCareIsLoading, setPlanOfCareIsLoading] = useState(false);
  const postPhysicianPlanOfCare = usePostPhysicianPlanOfCare();
  const { data: availableICDCodes = [] } = useGetPatientIcdCodesQuery({
    patientId,
  });
  const [expanded, setExpanded] = useState(false);

  const onPlanOfCareClick = () => {
    setPlanOfCareIsLoading(true);

    if (planOfCareId === null) {
      postPhysicianPlanOfCare.mutateAsync(
        { data: { patient_id: patientId } },
        {
          onSuccess(planOfCareData) {
            client.getCareEvent(planOfCareData.care_event_id).then(ce => {
              setPlanOfCareCareEvent({ ...ce, ...planOfCareData, label: MedicalNoteTypes.PHYSICIAN_PLAN_OF_CARE });
            });
            setPlanOfCareIsLoading(false);
          },
          onSettled() {
            setPlanOfCareIsLoading(false);
          },
        }
      );
    } else if (planOfCareId) {
      client.getPhysicianPlanOfCare(planOfCareId).then(poc => {
        client
          .getCareEvent(poc.care_event_id)
          .then(ce => {
            setPlanOfCareCareEvent({ ...ce, ...poc, label: MedicalNoteTypes.PHYSICIAN_PLAN_OF_CARE });
          })
          .finally(() => {
            setPlanOfCareIsLoading(false);
          });
      });
    }
  };

  return (
    <TodoItem
      expanded={expanded}
      title={t("dashboard.tables.notes.types.PHYSICIAN_PLAN_OF_CARE")}
      secondaryTitle={date ? format(parseISO(date), "PP") : ""}
      onChange={(_, isExpanded) => {
        if (isExpanded) {
          onPlanOfCareClick();
        }
        setExpanded(isExpanded);
      }}
      icon={<StatusIcon status={status} />}
    >
      <>
        {planOfCareIsLoading && <Spinner small />}
        {planOfCareCareEvent && !planOfCareIsLoading && (
          <CareEventContainer>
            <DynamicMedicalNoteForm
              careEvent={planOfCareCareEvent}
              animate
              open
              isPhysician
              skipMerge
              showNoteHeader
              readOnly={planOfCareCareEvent.status !== "pending"}
              clearSelectedCareEvent={() => {
                setExpanded(false);
              }}
            />
            {planOfCareCareEvent.status !== "pending" && (
              <div style={{ display: "flex", marginTop: "28px" }}>
                <StyledPDFDownloadLink
                  document={<PlanOfCarePDF availableICDCodes={availableICDCodes} planOfCare={planOfCareCareEvent} />}
                  fileName={`plan_of_care_${patientId}_${planOfCareCareEvent.handled_at}`}
                >
                  {t("patients.medical_records.partner_pdf_download_button")}
                </StyledPDFDownloadLink>
              </div>
            )}
          </CareEventContainer>
        )}
      </>
    </TodoItem>
  );
};

export default PlanOfCareItem;
