import { useMutation } from "react-query";

import type { PhysicianPlanOfCare, PostArgs } from "api/schemas/physician";
import useApiClient from "api/useApiClient";

export const usePostPhysicianPlanOfCare = () => {
  const client = useApiClient();

  return useMutation<PhysicianPlanOfCare, Error, PostArgs>(args => client.postPhysicianPlanOfCare(args));
};
