import { Document, Page, Text, View } from "@react-pdf/renderer";
import { parseISO } from "date-fns";
import { useTranslation } from "react-i18next";

import type { PhysicianMedicalReferral } from "api/schemas/physician";
import type { GetPatientIcdCodesResponse } from "api/types/GetPatientIcdCodes";
import { getInitials } from "routes/videocall/components/DisabledPublicationsOverlay";

import { styles } from "./styles";

const REQUEST_SERVICES = [
  "therapeutic_exercises",
  "stretching",
  "strengthening",
  "increase_rom",
  "mobilization",
  "myofascial_release",
  "gait_training",
  "home_exercise_program",
  "heat_cold_pack",
  "electrical_stimulation",
  "ultrasound",
  "phonophoresis",
  "lumbar_cervical_traction",
  "iontophoresis",
];

const SPECIALITY_SERVICES = ["vestibular_rehab_therapy", "alter_g_anti_gravity_treadmill", "dry_needling"];

interface Props {
  medicalReferral: PhysicianMedicalReferral;
  availableICDCodes: GetPatientIcdCodesResponse;
}

export const MedicalReferralPDF: React.VFC<Props> = ({ availableICDCodes, medicalReferral }) => {
  const { t } = useTranslation();

  const {
    comment,
    condition,
    diagnosis,
    handled_at,
    icd_codes,
    npi,
    occupational_therapy,
    patient_birthday,
    patient_name,
    physical_therapy,
    physician_name,
    treatment_duration,
    treatment_frequency,
    request_services,
    speciality_services,
    speech_therapy,
  } = medicalReferral;

  const filteredICDCodes = availableICDCodes.filter(item => icd_codes.includes(item.code));

  const getCondition = () => {
    return condition === "acute"
      ? t("patients.notes.fields.condition_options.acute")
      : t("patients.notes.fields.condition_options.chronic");
  };

  const getRequestedServices = () => {
    return (
      <View style={[styles.row, { gap: 40 }]}>
        <View>
          {REQUEST_SERVICES.slice(0, 7).map(service => {
            return (
              <View style={[styles.row, styles.mb]} key={service}>
                <View style={request_services?.includes(service) ? styles.checkbox_filled : styles.checkbox} />
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <Text>{t(`patients.notes.fields.request_services_options.${service}`)}</Text>
              </View>
            );
          })}
        </View>
        <View>
          {REQUEST_SERVICES.slice(7).map(service => {
            return (
              <View style={[styles.row, styles.mb]} key={service}>
                <View style={request_services?.includes(service) ? styles.checkbox_filled : styles.checkbox} />
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <Text>{t(`patients.notes.fields.request_services_options.${service}`)}</Text>
              </View>
            );
          })}
        </View>
      </View>
    );
  };

  const getSpecialityServices = () => {
    return SPECIALITY_SERVICES.map(service => {
      return (
        <View style={[styles.row, styles.mb]} key={service}>
          <View style={speciality_services?.includes(service) ? styles.checkbox_filled : styles.checkbox} />
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <Text>{t(`patients.notes.fields.speciality_services_options.${service}`)}</Text>
        </View>
      );
    });
  };

  return (
    <Document>
      <Page style={styles.page}>
        <Text style={styles.title}>{t("dashboard.tables.notes.types.PHYSICIAN_MEDICAL_REFERRAL")}</Text>

        <View style={styles.section}>
          <View style={[styles.row, styles.mb, { justifyContent: "space-between" }]}>
            <View style={styles.row}>
              <Text style={styles.label}>{t("patients.extended_medical_note.patient_name.label")}</Text>
              <Text>{patient_name}</Text>
            </View>

            <View style={styles.row}>
              <Text style={styles.label}>{t("patients.notes.fields.handled_at")}</Text>
              <Text>{handled_at ? parseISO(handled_at).toLocaleDateString("en-US", { timeZone: "UTC" }) : null}</Text>
            </View>
          </View>

          <View style={[styles.row, styles.mb]}>
            <Text style={styles.label}>{t("patients.extended_medical_note.patient_birthday.label")}</Text>
            <Text>{patient_birthday}</Text>
          </View>

          <View style={[styles.row, styles.mb]}>
            <Text style={styles.label}>{t("patients.notes.fields.physician_name")}</Text>
            <Text>{physician_name}</Text>
          </View>

          <View style={[styles.row, styles.mb]}>
            <Text style={styles.label}>{t("patients.notes.fields.npi")}</Text>
            <Text>{npi}</Text>
          </View>
        </View>

        <View style={styles.section}>
          <Text style={[styles.bold, styles.mb]}>{t("patients.notes.fields.diagnosis")}</Text>
          <Text>{diagnosis}</Text>
        </View>

        <View style={styles.section}>
          <Text style={[styles.bold, styles.mb]}>{t("patients.notes.fields.icd_codes")}</Text>
          {filteredICDCodes.map(icdCode => (
            <View key={icdCode.code} style={[styles.row, styles.mb]}>
              <Text style={styles.label}>{icdCode.code}</Text>
              <Text>{icdCode.description}</Text>
            </View>
          ))}
        </View>

        <View style={[styles.row, styles.section]}>
          <Text style={styles.label}>{t("patients.notes.fields.condition")}</Text>
          <Text>{getCondition()}</Text>
        </View>

        <View style={styles.section}>
          <View style={[styles.row, styles.mb]}>
            <View style={physical_therapy ? styles.checkbox_filled : styles.checkbox} />
            <Text>{t("patients.notes.fields.physical_therapy")}</Text>
          </View>

          <View style={[styles.row, styles.mb]}>
            <View style={occupational_therapy ? styles.checkbox_filled : styles.checkbox} />
            <Text>{t("patients.notes.fields.occupational_therapy")}</Text>
          </View>

          <View style={[styles.row, styles.mb]}>
            <View style={speech_therapy ? styles.checkbox_filled : styles.checkbox} />
            <Text>{t("patients.notes.fields.speech_therapy")}</Text>
          </View>
        </View>

        <View style={styles.section}>
          <View style={[styles.row, styles.mb]}>
            <Text style={styles.label}>{t("patients.notes.fields.treatment_frequency")}</Text>
            <Text>{treatment_frequency}</Text>
          </View>

          <View style={[styles.row, styles.mb]}>
            <Text style={styles.label}>{t("patients.notes.fields.treatment_duration")}</Text>
            <Text>{treatment_duration}</Text>
          </View>
        </View>

        <View style={[styles.row, styles.section]}>
          <View style={{ flex: 2 }}>
            <Text style={[styles.bold, styles.mb]}>{t("patients.notes.fields.request_services")}</Text>
            {getRequestedServices()}
          </View>

          <View style={{ flex: 1 }}>
            <Text style={[styles.bold, styles.mb]}>{t("patients.notes.fields.speciality_services")}</Text>
            {getSpecialityServices()}
          </View>
        </View>

        <View style={{ marginBottom: 40 }}>
          <Text style={[styles.bold, styles.mb]}>{t("patients.notes.fields.comment")}</Text>
          <Text>{comment}</Text>
        </View>

        <View wrap={false}>
          <View style={{ marginBottom: 40 }}>
            <Text>{physician_name}</Text>
            <View style={[styles.border, styles.mb, { width: 200, marginTop: 5 }]} />
            <Text>{t("physician.printed_name")}</Text>
          </View>

          <View>
            <Text>{getInitials(physician_name)}</Text>
            <View style={[styles.border, styles.mb, { width: 200 }]} />
            <Text>{t("physician.signature")}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
