import styled from "styled-components";

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.white};
`;

export const Header = styled.div`
  display: flex;
  padding: 0 10px;
`;

export const Title = styled.h2`
  font-size: 18px;
  font-weight: 500;
`;
