import type React from "react";
import { useContext } from "react";

import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import invariant from "ts-invariant";

import { getPatientCareEventsQueryKey, removePatientCareEvent } from "api/hooks/useGetPatientCareEventsQuery";
import { getPTCareEventsQueryKey } from "api/hooks/useGetPTCareEventsQuery";
import { CancelIcon } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import { removeNote } from "routes/patients/queries/notesApiHandler";
import { PrimaryButton, TextButton } from "shared/atoms/Button";
import Col from "shared/atoms/Col";
import Hidden from "shared/atoms/Hidden";
import { Notification } from "shared/atoms/Notification";
import Row from "shared/atoms/Row";
import type { MedicalNoteType } from "types";
import { CurrentPatientContext } from "utils/contexts";
import { isKickOffNote } from "utils/medicalNotes";
import { isInitialHybrid, isRTMInitial } from "utils/medicalNotes/isNoteType";

import WarningPopup from "../../PatientHeader/SignNotesTable/components/ICDWarningPopup";
import { isICDCodeMatchingTreatment } from "../../PatientHeader/SignNotesTable/utils/ICDCodes";
import type { ShowButtons } from "../../PatientHeader/SignNotesTable/utils/types";

interface Props {
  cid: number;
  confirmDisabled?: boolean;
  medicalNoteType: MedicalNoteType;
  showConfirmation: ShowButtons;
  setReadOnly: (newReadOnly: boolean) => void;
  setShowConfirmation: (newShow: ShowButtons) => void;
  discardFromDashboard?: () => void;
}

const MedicalNoteFormButtons: React.VFC<Props> = ({
  cid,
  confirmDisabled,
  medicalNoteType,
  showConfirmation,
  setReadOnly,
  setShowConfirmation,
  discardFromDashboard,
}) => {
  const {
    getValues,
    trigger,
    formState: { isValid },
  } = useFormContext();

  const { patient } = useContext(CurrentPatientContext);
  const patientId = Number(useParams().patientId);
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { profile } = useProfileContext();
  invariant(profile);

  const NOT_DANGER_OR_SUCCESS = !(
    showConfirmation?.type === "saved" ||
    showConfirmation?.type === "success" ||
    showConfirmation?.type === "danger"
  );

  // Signing of notes different from kickoff, supplemenetal and discharge is disabled if the treatment is not started
  const isSigningDisabled = !(
    isKickOffNote(medicalNoteType) ||
    isRTMInitial(medicalNoteType) ||
    isInitialHybrid(medicalNoteType) ||
    medicalNoteType.toLocaleUpperCase() === "SUPPLEMENTAL" ||
    medicalNoteType.toLocaleUpperCase() === "DISCHARGE" ||
    medicalNoteType.toLocaleUpperCase() === "INITIAL_RE_EVAL" ||
    medicalNoteType.toLocaleUpperCase() === "INITIAL_EVAL" ||
    patient.treatment_started
  );

  const initiateSigning = async () => {
    await trigger();

    if (isValid) {
      setReadOnly(true);
      setShowConfirmation({ isOpen: true, type: "info" });
    }
  };

  const initiateDelete = async () => {
    if (patientId) {
      const key = getPatientCareEventsQueryKey({ patientId, signed: false });
      queryClient.cancelQueries(key);
      const rollbackData = queryClient.getQueryData(key);
      queryClient.setQueryData(key, removePatientCareEvent(cid));
      try {
        await removeNote(cid);
      } catch {
        queryClient.setQueryData(key, rollbackData);
        setShowConfirmation({ isOpen: true, type: "danger" });
      } finally {
        queryClient.invalidateQueries(key);
        queryClient.invalidateQueries(getPTCareEventsQueryKey({ userId: profile.id }));
      }
    } else {
      try {
        await removeNote(cid);
        if (discardFromDashboard) discardFromDashboard();
      } catch {
        setShowConfirmation({ isOpen: true, type: "danger" });
      }
    }
  };

  const getTreatmentICDCode = () => {
    switch (patient.ailment) {
      case "joint_pain":
        // must match up ICD code type "back" with pain location "lower_back"
        return patient.most_painful_location?.location === "lower_back"
          ? "back"
          : patient.most_painful_location?.location;
      case "osteoporosis":
        return "osteoporosis";
      default:
        return undefined;
    }
  };

  const renderNotificationButtons = (type: ShowButtons["type"]) => {
    switch (type) {
      case "danger":
        return (
          <PrimaryButton type="button" onClick={() => setShowConfirmation({ isOpen: false })}>
            {t("buttons.ok")}
          </PrimaryButton>
        );
      case "warning":
        return (
          <PrimaryButton type="button" data-testid="confirm-delete-button" onClick={initiateDelete}>
            {t("buttons.delete")}
          </PrimaryButton>
        );
      case "saved":
      case "success":
        return null;
      case "info":
      default:
        return (
          <>
            {isKickOffNote(medicalNoteType) &&
              isICDCodeMatchingTreatment(getValues("icd_codes"), getTreatmentICDCode()) === false && (
                <WarningPopup content={t("icd_popup.message")} />
              )}
            <PrimaryButton disabled={confirmDisabled} data-testid="confirm-sign-button" type="submit">
              {t("buttons.confirm_sign")}
            </PrimaryButton>
          </>
        );
    }
  };

  if (showConfirmation?.isOpen) {
    return (
      <>
        <Hidden type="belowTablet">
          <Notification variant={showConfirmation?.type}>
            <Row align="center" justify="space-between">
              {/* FIXME: type translation */}
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              <span>{t(`patients.notes.confirm.${showConfirmation?.type}`)}</span>
              <Row>
                {NOT_DANGER_OR_SUCCESS && (
                  <TextButton
                    data-testid="cancel-note-button"
                    type="button"
                    onClick={() => {
                      setReadOnly(false);
                      setShowConfirmation({ isOpen: false });
                    }}
                  >
                    {t("buttons.cancel")}
                  </TextButton>
                )}
                {renderNotificationButtons(showConfirmation?.type)}
              </Row>
            </Row>
          </Notification>
        </Hidden>
        <Hidden type="aboveTablet">
          <StyledNotification variant={showConfirmation?.type}>
            <Row align="center" justify="space-between">
              {/* FIXME: type translation */}
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              <span>{t(`patients.notes.confirm.${showConfirmation?.type}`)}</span>
            </Row>
          </StyledNotification>
          <Col align="center" justify="center" reverse>
            {NOT_DANGER_OR_SUCCESS && (
              <TextButton
                data-testid="cancel-note-button"
                type="button"
                onClick={() => setShowConfirmation({ isOpen: false })}
              >
                {t("buttons.cancel")}
              </TextButton>
            )}
            {renderNotificationButtons(showConfirmation?.type)}
          </Col>
        </Hidden>
      </>
    );
  }

  return (
    <ButtonWrapper>
      {isSigningDisabled && (
        <DisabledSigningWarning data-testid="disabled-signing-warning">
          {t("patients.notes.disabled_signing")}
        </DisabledSigningWarning>
      )}
      <TextButton
        data-testid="medical-note-delete-button"
        type="button"
        onClick={() => {
          setReadOnly(true);
          setShowConfirmation({ isOpen: true, type: "warning" });
        }}
      >
        <CancelIcon />
        {t("buttons.delete")}
      </TextButton>
      <PrimaryButton data-testid="sign-button" type="button" onClick={initiateSigning} disabled={isSigningDisabled}>
        {t("buttons.sign")}
      </PrimaryButton>
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;

  button {
    margin-left: ${props => props.theme.spacing.S_15};
    max-width: 400px;
  }

  ${props => props.theme.belowBreakpoint} {
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;

    button {
      margin-left: 0;
      min-width: 100px;
      margin-top: ${props => props.theme.spacing.S_15};
      padding-left: ${props => props.theme.spacing.S_20};
      padding-right: ${props => props.theme.spacing.S_20};
      flex: 1;

      &:first-child {
        margin-right: ${props => props.theme.spacing.S_15};
      }

      &:last-child {
        align-self: stretch;
        flex: 0 0 100%;
      }
    }
  }
`;

const StyledNotification = styled(Notification)`
  margin: 0 ${props => props.theme.spacing.S_20};
  margin-bottom: ${props => props.theme.spacing.S_10};
  width: calc(100% - 40px);
`;

const DisabledSigningWarning = styled.div`
  color: ${props => props.theme.colors.salmon};
  line-height: 1.5em;
`;

export { MedicalNoteFormButtons };
