import type { DateTimeString } from "types/DateTimeString";
import type { ValidLocale } from "types/validLocale";

import type { Avatars } from "./Avatars";

/** https://github.com/Jojnts/jojnts-service/blob/e257ccfdc14a3984f84e7fc7691aa5d8546572d8/app/models/user.rb#L78 */
export const UserRole = {
  Admin: "admin",
  Affiliate: "affiliate",
  CareAdmin: "care_admin",
  Patient: "patient",
  HCPAdmin: "hcp_admin",
  SuperAdmin: "super_admin",
  SupportAdmin: "support_admin",
  Therapist: "therapist",
  Partner: "clinic_partner",
  Physician: "physician",
} as const;
export type UserRole = typeof UserRole[keyof typeof UserRole];
export const isTypeUserRole = (value: unknown): value is UserRole =>
  Object.values(UserRole).includes(value as UserRole);

/**
 * As the `state` property sits on the User model, it will contain values for both Patients and Therapists.
 * This is not an exhaustive list, pease update as needed.
 */
export type UserState =
  | "discharged"
  | "in_onboarding"
  | "in_selfcare"
  | "in_six_week_course"
  | "in_sustain_course"
  | "ready"
  | "treatment_ended";

/**
 * The User contains the base properties for all system users
 * https://github.com/Jojnts/jojnts-service/blob/develop/spec/swagger_helper.rb#L461
 */
export type User = {
  active_time_off_period?: number | null;
  avatars: Avatars;
  created_at?: DateTimeString | null;
  email: string | null;
  first_name: string | null;
  iana_timezone: string | null;
  id: number;
  external_uuid?: string;
  invited_by_id?: number | null;
  language_code?: string;
  last_login?: DateTimeString | null;
  last_name: string | null;
  locale?: ValidLocale;
  market?: string;
  personal_invitation_url?: string | null;
  preferred_name: string | null;
  provider: string | null;
  roles: UserRole[];
  state: UserState | null;
  timekit_resource_id?: number | null;
  timezone: string | null;
  timezone_offset: number;
  uid: string;
  unsubscribed: boolean | null;
};
