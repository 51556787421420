import type React from "react";

import styled from "styled-components";

import { InvitationList } from "./components/InvitationList";
import { PatientList } from "./components/PatientList";

const Container = styled.div`
  padding: ${props => props.theme.spacing.S_20};
  margin-bottom: ${props => props.theme.spacing.S_20};
`;

const PartnerDashboard: React.VFC = () => {
  return (
    <Container>
      <PatientList />
      <InvitationList />
    </Container>
  );
};
export default PartnerDashboard;
