import type React from "react";

import { useTranslation } from "react-i18next";

interface Props {
  readonly careEventLabel: string;
  readonly weekNumber?: number | undefined;
}

const MedicalNoteLabel: React.VFC<Props> = ({ careEventLabel, weekNumber }) => {
  const { t } = useTranslation();

  if (careEventLabel.toLocaleUpperCase() === "PROTOCOL_WEEKLY" && weekNumber) {
    return (
      <span data-testid="medical-note-label">
        {t(`dashboard.tables.notes.types.PROTOCOL_WEEKLY`, { week_number: weekNumber - 1 })}
      </span>
    );
  }

  return (
    // FIXME: type translation
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <span data-testid="medical-note-label">{t(`dashboard.tables.notes.types.${careEventLabel.toUpperCase()}`)}</span>
  );
};

export default MedicalNoteLabel;
