import type React from "react";

import { Route, Routes } from "react-router-dom";
import styled from "styled-components";

import { PatientProfile, PatientsList } from "./views";

const PatientsContainer = styled.div`
  background: ${props => props.theme.colors.white};
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  min-height: 100vh;
`;

const PatientsRoutes: React.VFC = () => {
  return (
    <Routes>
      <Route path=":patientId/*" element={<PatientProfile />} />
      <Route
        path="/"
        element={
          <PatientsContainer>
            <PatientsList />
          </PatientsContainer>
        }
      />
    </Routes>
  );
};

export default PatientsRoutes;
