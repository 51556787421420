import type React from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const TodoItem: React.VFC<{
  title: string;
  secondaryTitle: string;
  children: React.ReactNode;
  onChange: (event: React.SyntheticEvent, expanded: boolean) => void;
  icon: React.ReactNode;
  expanded: boolean;
}> = ({ title, secondaryTitle, children, onChange, icon, expanded }) => {
  return (
    <StyledAccordion expanded={expanded} onChange={onChange}>
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <SummaryWrapper>
          {icon}
          <Typography sx={{ flexGrow: 1 }}>
            <strong>{title}</strong>
          </Typography>
          <Typography>{secondaryTitle}</Typography>
        </SummaryWrapper>
      </StyledAccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </StyledAccordion>
  );
};

const StyledAccordion = styled(Accordion)({
  backgroundColor: "white",
  boxShadow: "none",
  borderRadius: "0!important",
});

const StyledAccordionSummary = styled(AccordionSummary)({
  borderRadius: 0,
});

const SummaryWrapper = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  gap: "16px",
  width: "100%",
});

export default TodoItem;
