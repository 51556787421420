import type { DataGridProps } from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid";
import styled from "styled-components";

import { useMUIDataGridLocale } from "./useMUIDataGridLocale";

interface Props extends DataGridProps {
  title?: string;
  notifications?: number;
}

export const MUIDataGrid: React.VFC<Props> = ({ title, notifications, ...props }) => {
  const locale = useMUIDataGridLocale();

  const getDataGrid = () => {
    return (
      <DataGrid
        pageSizeOptions={[5, 10, 25, 50]}
        sx={{ border: 0, cursor: "pointer" }}
        localeText={locale.components.MuiDataGrid.defaultProps.localeText}
        {...props}
      />
    );
  };

  if (title) {
    return (
      <Container>
        <TableHeader>
          <Title>{title}</Title>
          {notifications && <Notifications>{notifications}</Notifications>}
        </TableHeader>
        {getDataGrid()}
      </Container>
    );
  }

  return getDataGrid();
};

const Container = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.redesign.db20};
  border-radius: 8px;
`;

const TableHeader = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 48px;
  padding-left: 10px;
  color: ${({ theme }) => theme.colors.greys.slate};
`;

const Notifications = styled.div`
  font-size: 24px;
  font-weight: 400;
  margin-left: 16px;
  color: ${({ theme }) => theme.colors.salmon};
`;
