import type React from "react";
import { useContext } from "react";

import { useTranslation } from "react-i18next";

import { usePutPatientMarkAsPrioMutation } from "api/hooks/usePutPatientMarkAsPrioMutation";
import { NewMessageIcon, StarHollow } from "assets";
import { useFeatureToggleContext } from "contexts/FeatureToggleContext";
import { useProfileContext } from "contexts/ProfileContext";
import { CurrentPatientContext } from "utils/contexts";
import { Feature } from "utils/features/types";

import { Button, Buttons, LinkButton } from "./helpers";
import PatientHeaderInfoAddNoteButton from "./PatientHeaderInfoAddNoteButton";
import PatientHeaderInfoCallButton from "./PatientHeaderInfoCallButton";
import PatientHeaderInfoExtraButton from "./PatientHeaderInfoExtraButton";

interface Props {
  toggleEndTreatment: () => void;
}

const PatientHeaderButtonRow: React.VFC<Props> = ({ toggleEndTreatment }) => {
  const { patient, previousTreatmentId } = useContext(CurrentPatientContext);
  const { profile } = useProfileContext();
  const { hasFeature } = useFeatureToggleContext();
  const { t } = useTranslation();
  const putPatientMarkAsPrioMutation = usePutPatientMarkAsPrioMutation({
    patientId: patient?.id,
    therapistId: profile?.id,
    therapistMarket: profile?.market,
    previousTreatmentId,
  });

  const togglePriority = () => {
    const variables = {
      data: {
        prio: !patient.prio,
      },
    };

    putPatientMarkAsPrioMutation.mutate(variables);
  };

  return (
    <>
      <Buttons>
        {hasFeature(Feature.MARK_PATIENT_AS_PRIO) && (
          <Button onClick={togglePriority} data-testid="mark-prio-button" main>
            <StarHollow style={{ width: 20, height: 20 }} />
            {patient.prio
              ? t("patients.header_info.unmark_as_priority_button")
              : t("patients.header_info.mark_as_priority_button")}
          </Button>
        )}

        {hasFeature(Feature.PATIENT_MESSAGES) && (
          <LinkButton to={`/messages/${patient.id}`}>
            <NewMessageIcon />
            <div data-testid="open-conversation-button">{t("patients.header_info.open_chat_button")}</div>
          </LinkButton>
        )}

        {hasFeature(Feature.WRITE_PATIENT_NOTE) && (
          <PatientHeaderInfoAddNoteButton customDropdownStyling="default-position" />
        )}

        {hasFeature(Feature.CALL_PATIENT) && <PatientHeaderInfoCallButton />}

        {hasFeature(Feature.END_TREATMENT) && <PatientHeaderInfoExtraButton toggleEndTreatment={toggleEndTreatment} />}
      </Buttons>
    </>
  );
};

export default PatientHeaderButtonRow;
