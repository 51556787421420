// TODO: Refactor components to TS
/* eslint-disable @typescript-eslint/ban-ts-comment */
import type React from "react";
import { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useMatch, useNavigate, useParams } from "react-router-dom";
import styled, { ThemeContext } from "styled-components";
import invariant from "ts-invariant";

import { useProfileContext } from "contexts/ProfileContext";
import { getTextLessonPart, handleType, isTranslatedActivity } from "routes/patients/utils/activityHelpers";
import Divider from "shared/atoms/Divider";
import { Notification } from "shared/atoms/Notification";
import Spinner from "shared/atoms/Spinner";
import { AnalyticsPages, AnalyticsService } from "utils/analytics";
import { CurrentPatientContext } from "utils/contexts";
import { useWindowSize } from "utils/hooks";

import Calendar from "./Calendar";
import ProtocolActivityGroup from "./ProtocolActivityGroup";
import ProtocolHeader from "./ProtocolHeader";
import type { Activity, Protocol } from "./queries/useProtocol";
import useProtocol from "./queries/useProtocol";

const ProtocolView: React.VFC = () => {
  const theme = useContext(ThemeContext);
  const { previousTreatmentId } = useContext(CurrentPatientContext);
  const { patientId, weekInfo } = useParams<{ patientId?: string; weekInfo?: string }>();
  const navigate = useNavigate();
  const match = useMatch("*");
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const [requiredVisible, setRequiredVisible] = useState(false);
  const { profile } = useProfileContext();

  invariant(profile);

  const { response, error, isLoading } = useProtocol({ weekInfo, patientId, treatment_id: previousTreatmentId });
  const data = response?.data;

  useEffect(() => {
    if (match) AnalyticsService.viewedPage(AnalyticsPages.PATIENTS.PROTOCOL, match.pathname);
  }, [match?.pathname]);

  const handlePageChange = (direction: string) => {
    if (direction === "next") {
      navigate(`/patients/${patientId}/protocol/${data?.protocol_week.next_id}`);
    } else if (direction === "previous") {
      navigate(`/patients/${patientId}/protocol/${data?.protocol_week.previous_id}`);
    }
  };

  const handleCurrentClick = () => {
    navigate(`/patients/${patientId}/protocol/current`);
  };

  const handleLessonPart = (activityName: string) => {
    return t("activities.part", { number: getTextLessonPart(activityName) });
  };

  const headerData = ({ protocol_week: protocolWeek }: Protocol) => {
    return {
      startDate: protocolWeek.start_date,
      endDate: protocolWeek.end_date,
      nextId: protocolWeek.next_id,
      previousId: protocolWeek.previous_id,
      overdue: protocolWeek.overdue,
      overdueDays: protocolWeek.overdue_delayed_days,
      weekNumber: protocolWeek.number,
    };
  };

  const activityData = (activities: Activity[], isOverdue: boolean) => {
    return activities.map(activity => {
      const type = activity.content_type;
      const activityId = activity.content_id;

      let gqlId = type === "functionality" ? 1 : null;

      if (handleType(type) === "lesson" || type === "exercise") {
        gqlId = data?.translations.find(item => item.content_id === activityId)?.translations?.library_id || null;
      }

      let current = null;
      if (type === "exercise") {
        current = activity.completed_at
          ? data?.includes[type][activityId].performed_level.number
          : data?.includes[type][activityId].predicted_level.number;
      }

      const part = type === "text_lesson" && data ? handleLessonPart(data.includes[type][activityId].name) : null;

      let name = "";

      if (type === "meet_your_pt") {
        name = t("activities.meet_your_pt", {
          profession: profile?.therapist_profile?.profession ?? "physical_therapist",
        });
      } else {
        name = isTranslatedActivity(type)
          ? (data?.translations.find(item => item.content_id === activityId)?.translations?.title as string)
          : // FIXME: type translation
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            (t(`activities.${data?.includes[type][activityId].name}`) as string);
      }

      return {
        name,
        gqlId,
        part,
        levels: type === "exercise" ? data?.includes[type][activityId].max_level_number : null,
        currentLevel: current,
        completedAt: activity.completed_at,
        comment: activity.comment,
        reply: activity.reply,
        contentType: handleType(type),
        id: activity.id,
        required: activity.required,
        isPast: !isOverdue && (data?.protocol_week.next_id !== null || data?.protocol_week.overdue),
        premium: activity.premium,
      };
    });
  };

  const groupData = (isOverdue: boolean) => {
    if (data && isOverdue) {
      return activityData(data.protocol_week.overdue_activities, isOverdue);
    }

    return data?.protocol_week.days.map(day => {
      return {
        date: day.date,
        activities: activityData(day.activities, isOverdue),
      };
    });
  };

  const selectChange = (day: number) => {
    // overdue activities
    if (data?.protocol_week.overdue_activities.filter(({ required }) => required === true).length !== 0)
      setRequiredVisible(true);
    // small screen, only a day of activities visible
    else if (
      width <= parseInt(theme.breakpointLarge, 10) &&
      data?.protocol_week.days[day].activities.filter(activity => activity.required).length !== 0
    ) {
      setRequiredVisible(true);
    } else if (width <= parseInt(theme.breakpointLarge, 10)) {
      setRequiredVisible(false);
    }
  };

  useEffect(() => {
    if (data) {
      // overdue activities
      if (data?.protocol_week.overdue_activities.filter(activity => activity.required).length !== 0)
        setRequiredVisible(true);
      // big screen all activities visible
      else if (
        width > parseInt(theme.breakpointLarge, 10) &&
        data?.protocol_week.days.filter(
          ({ activities }) => activities.filter(activity => activity.required).length !== 0
        ).length !== 0
      ) {
        setRequiredVisible(true);
      } else if (width > parseInt(theme.breakpointLarge, 10)) {
        setRequiredVisible(false);
      }
    }
  }, [data]);

  return (
    <>
      {!error && data && (
        <>
          <ProtocolHeader
            weekInfo={headerData(data)}
            empty={!!error}
            onPageChange={direction => handlePageChange(direction)}
            onCurrentClick={() => handleCurrentClick()}
          />
          {/* TODO fix when refactoring ProtocolActivityGroup */}
          {/* @ts-ignore */}
          {data.protocol_week?.overdue && <ProtocolActivityGroup activities={groupData(true)} />}
          {/* TODO fix when refactoring Calendar */}
          {/* @ts-ignore */}
          <Calendar days={groupData(false)} onSelectChange={day => selectChange(day)} />
          <BottomWrapper>
            <Divider />
            {requiredVisible && <RequiredText>{t("patients.protocol.required")}</RequiredText>}
          </BottomWrapper>
        </>
      )}
      {(isLoading || error) && (
        <CenterContainer>
          {isLoading && <Spinner small />}
          {error && <Notification variant="danger">{t("errors.generic")}</Notification>}
        </CenterContainer>
      )}
    </>
  );
};

export default ProtocolView;

const CenterContainer = styled.div`
  height: 300px;
  width: 100%;
  background-color: ${props => props.theme.colors.white};
  display: flex;
  padding-top: ${props => props.theme.spacing.S_30};
  align-items: flex-start;
  justify-content: center;
`;

const BottomWrapper = styled.div`
  width: 100%;
  max-width: 1110px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 0 ${props => props.theme.spacing.S_30};
`;

const RequiredText = styled.div`
  width: 100%;
  ${props => props.theme.font.header5}
  color: ${props => props.theme.colors.greys.dark};
  padding: ${props => props.theme.spacing.S_10} 0 ${props => props.theme.spacing.S_60};
`;
