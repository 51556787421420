import type React from "react";
import { useEffect } from "react";

import { Route, Routes, useMatch } from "react-router-dom";

import { useProfileContext } from "contexts/ProfileContext";
import Container from "shared/atoms/Container";
import usePageContentOffsetTop from "shared/styles/usePageContentOffsetTop";
import { AnalyticsPages, AnalyticsService } from "utils/analytics";
import { userIsPartner, userIsPhysician } from "utils/profile/profileHelper";

import Dashboard from "./default/views/Dashboard";
import PartnerDashboard from "./partnerui/PartnerDashboard";
import PhysicianDashboard from "./physician/views/PhysicianDashboard";

const DashboardRoutes: React.VFC = () => {
  const { profile } = useProfileContext();
  const match = useMatch("dashboard/*");
  const offsetTop = usePageContentOffsetTop();
  const isPartner = userIsPartner(profile);
  const isPhysician = userIsPhysician(profile);

  useEffect(() => {
    if (match?.pathname) {
      AnalyticsService.viewedPage(AnalyticsPages.DASHBOARD, match?.pathname);
    }
  }, [match?.pathname]);

  if (isPartner) {
    return (
      <Routes>
        <Route
          path="/"
          element={
            <Container $pageContentOffsetTop={offsetTop}>
              <PartnerDashboard />
            </Container>
          }
        />
      </Routes>
    );
  }

  if (isPhysician) {
    return (
      <Routes>
        <Route
          path="/"
          element={
            <Container $pageContentOffsetTop={offsetTop}>
              <PhysicianDashboard />
            </Container>
          }
        />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Container $pageContentOffsetTop={offsetTop}>
            <Dashboard />
          </Container>
        }
      />
    </Routes>
  );
};

export default DashboardRoutes;
