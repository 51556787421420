import { z } from "zod";

type StorageOptions = {
  keepOnClear: boolean;
};

function createStorageDefinition<Type>(schema: z.ZodType<Type>, options: StorageOptions = { keepOnClear: false }) {
  return { schema, options };
}

export const storageDefinitions = {
  calendar_settings: createStorageDefinition(
    z.object({
      show_my_availability: z.boolean(),
      show_others_availability: z.boolean(),
      show_canceled: z.boolean(),
      show_rescheduled: z.boolean(),
      show_weekends: z.boolean(),
    }),
    { keepOnClear: true }
  ),
  collapsedSidebar: createStorageDefinition(z.enum(["collapsed"])),
  hideSideContainer: createStorageDefinition(z.enum(["hide"])),
  language: createStorageDefinition(z.string(), { keepOnClear: true }),
  "login-remember-pn": createStorageDefinition(z.string(), { keepOnClear: true }),
  "login-remember-email": createStorageDefinition(z.string(), { keepOnClear: true }),
  "medical-notes-sort": createStorageDefinition(
    z.object({
      selectedColumn: z.string(),
      descending: z.boolean(),
    })
  ),
  patient_list_visible_columns: createStorageDefinition(
    z.object({
      id: z.boolean().optional(),
      name: z.boolean().optional(),
      icons: z.boolean().optional(),
      ailment: z.boolean().optional(),
      week: z.boolean().optional(),
      status: z.boolean().optional(),
      last_activity_completed_at: z.boolean().optional(),
      adherence: z.boolean().optional(),
      coverage: z.boolean().optional(),
      subscription: z.boolean().optional(),
      address_state: z.boolean().optional(),
    })
  ),
  "suggestion-dismissed": createStorageDefinition(z.record(z.number())),
  "tours-completed": createStorageDefinition(z.record(z.boolean())),
};

export type StorageKey = keyof typeof storageDefinitions;

export type StorageValue<T extends StorageKey> = z.infer<typeof storageDefinitions[T]["schema"]>;
