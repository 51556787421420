import type React from "react";
import { useState } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { usePutCareEventBilling } from "api/hooks/usePutCareEventBilling";
import type { Status } from "api/schemas/CareEventBilling";
import { Header } from "routes/calendar/components/EventDetails/styles";
import { BaseButton } from "shared/atoms/BaseButton";
import { Notification } from "shared/atoms/Notification";
import Popup from "shared/atoms/Popup";
import { reportError } from "utils/errorReporting";

interface Props {
  cid: number;
  clearSelectedCareEvent?: () => void;
}

const MedicalNoteFormButtonsAdminBilling: React.VFC<Props> = ({ cid, clearSelectedCareEvent }) => {
  const { t } = useTranslation();
  const [showMarkAsCompleted, setShowMarkAsCompleted] = useState(false);
  const [showMarkAsDiscarded, setShowMarkAsDiscarded] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { mutateAsync, isLoading } = usePutCareEventBilling();

  const updateCareEventBillingStatus = async (careEventID: number, status: Status) => {
    mutateAsync(
      {
        data: { care_event_ids: [careEventID], status },
      },
      {
        onSuccess: () => {
          if (clearSelectedCareEvent) {
            clearSelectedCareEvent();
          }
          setShowMarkAsCompleted(false);
          setShowMarkAsDiscarded(false);
        },
        onError: e => {
          reportError("AppointmentDetails.tsx", e);
          setError(t("errors.generic"));
        },
      }
    );
  };

  return (
    <>
      <ButtonWrapper>
        <BaseButton
          text={t("admin_billing.buttons.discard")}
          onClick={() => setShowMarkAsDiscarded(true)}
          variant="tertiary"
          uppercase
        />
        <BaseButton text={t("admin_billing.buttons.complete")} onClick={() => setShowMarkAsCompleted(true)} uppercase />
      </ButtonWrapper>

      {showMarkAsCompleted && (
        <Popup fullBgOpacity onClickOutside={() => setShowMarkAsCompleted(false)} showCloseButton>
          <PopupContent>
            <Header>{t("admin_billing.mark_as_completed", { count: 1 })}</Header>
            <BaseButton
              text={t("buttons.yes")}
              onClick={() => updateCareEventBillingStatus?.(cid, "completed")}
              disabled={isLoading}
              loading={isLoading}
              uppercase
              style={{ marginBottom: "20px" }}
            />
            <BaseButton
              text={t("buttons.cancel")}
              onClick={() => setShowMarkAsCompleted(false)}
              disabled={isLoading}
              variant="tertiaryBlue"
              uppercase
            />
            {error && (
              <Notification variant="danger" style={{ marginTop: "16px" }}>
                {error}
              </Notification>
            )}
          </PopupContent>
        </Popup>
      )}

      {showMarkAsDiscarded && (
        <Popup fullBgOpacity onClickOutside={() => setShowMarkAsDiscarded(false)} showCloseButton>
          <PopupContent>
            <Header>{t("admin_billing.mark_as_discarded", { count: 1 })}</Header>
            <BaseButton
              text={t("buttons.yes")}
              onClick={() => updateCareEventBillingStatus(cid, "discarded")}
              disabled={isLoading}
              loading={isLoading}
              uppercase
              style={{ marginBottom: "20px" }}
            />
            <BaseButton
              text={t("buttons.cancel")}
              onClick={() => setShowMarkAsDiscarded(false)}
              disabled={isLoading}
              variant="tertiaryBlue"
              uppercase
            />
            {error && (
              <Notification variant="danger" style={{ marginTop: "16px" }}>
                {error}
              </Notification>
            )}
          </PopupContent>
        </Popup>
      )}
    </>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin: 30px;

  button {
    margin-left: ${props => props.theme.spacing.S_15};
    max-width: 400px;
    flex: 0;
  }

  ${props => props.theme.belowBreakpoint} {
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;

    button {
      margin-left: 0;
      min-width: 100px;
      margin-top: ${props => props.theme.spacing.S_15};
      padding-left: ${props => props.theme.spacing.S_20};
      padding-right: ${props => props.theme.spacing.S_20};
      flex: 1;

      &:first-child {
        margin-right: ${props => props.theme.spacing.S_15};
      }

      &:last-child {
        align-self: stretch;
        flex: 0 0 100%;
      }
    }
  }
`;

export { MedicalNoteFormButtonsAdminBilling };

const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 430px;
  padding: 40px 26px 32px;
  text-align: center;
`;
