import type { AxiosResponse } from "axios";

import { AxiosInstance } from "api";

interface Roles {
  isPartner: boolean;
  isPhysician: boolean;
}

const prefixString = ({ isPartner, isPhysician }: Roles) => {
  if (isPartner) return "clinic_partners";
  if (isPhysician) return "physicians";
  return "therapists";
};

interface Args extends Roles {
  profileId: number;
}

const completeOnboarding = ({ profileId, isPartner, isPhysician }: Args): Promise<AxiosResponse> =>
  AxiosInstance.post(`/${prefixString({ isPartner, isPhysician })}/${profileId}/complete_onboarding`);

export default completeOnboarding;
