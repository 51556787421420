import { z } from "zod";

const StatusSchema = z.enum(["completed", "discarded", "pending"]);
export type Status = z.infer<typeof StatusSchema>;

export const CareEventBillingSchema = z.object({
  care_event_id: z.number(),
  cpt_codes: z.array(z.string()).nullable(),
  created_at: z.string(),
  handled_at: z.string().nullable(),
  icd_codes: z.array(z.string()),
  label: z.string(),
  manager_name: z.string().nullable(),
  medical_referral_id: z.number().nullable(),
  patient_birthday: z.string(),
  patient_id: z.number(),
  patient_name: z.string(),
  plan_of_care_id: z.number().nullable(),
  signed_at: z.string(),
  status: StatusSchema,
  therapist_name: z.string(),
  type: z.enum(["Rtm", "Non-Rtm", "Virtual"]),
});
export type CareEventBilling = z.infer<typeof CareEventBillingSchema>;

const PutCareEventBillingArgsSchema = z.object({
  data: z.object({
    care_event_ids: z.array(z.number()),
    status: StatusSchema,
  }),
});
export type PutCareEventBillingArgs = z.infer<typeof PutCareEventBillingArgsSchema>;

const PostCareEventSuggestedArgsSchema = z.object({
  patientID: z.number(),
  data: z.object({
    cpt_codes: z.array(z.string()).optional(),
    label: z.string(),
    message_interaction_id: z.number().optional(),
    rtm_care_event_log_id: z.number().optional(),
    user_course_week_id: z.number().optional(),
  }),
});
export type PostCareEventSuggestedArgs = z.infer<typeof PostCareEventSuggestedArgsSchema>;
