import { useQuery } from "react-query";

import useApiClient from "api/useApiClient";

export const getPhysicianUnhandledPatientQueryKey = (patientID: number) => ["getPhysicianUnhandledPatient", patientID];

export const useGetPhysicianUnhandledPatient = (patientID: number) => {
  const client = useApiClient();

  return useQuery(getPhysicianUnhandledPatientQueryKey(patientID), () =>
    client.getPhysicianUnhandledPatient(patientID)
  );
};
