import type { Locale } from "date-fns";
import { enGB, enUS, sv } from "date-fns/locale";
import { useTranslation } from "react-i18next";

type Language = "enUS" | "enGB" | "sv";

const useLocale = (): Locale => {
  const { i18n } = useTranslation();
  const locales = { enUS, enGB, sv };

  let language;
  if (i18n.language.split("-")[0] === "en") {
    language = (i18n.language.split("-")[0] + i18n.language.split("-")[1]) as Language;
  } else {
    language = i18n.language.split("-")[0] as Language;
  }
  const locale = locales[language] || locales.enGB;

  return locale;
};

export default useLocale;
