import type React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useGetPatientQuery } from "api/hooks/useGetPatientQuery";
import type { PatientBillingAddress, Payer } from "api/models/CareEvent";
import type { Ailment } from "api/schemas/patients/HealthJournal";
import { useProfileContext } from "contexts/ProfileContext";
import MUITextInput from "shared/atoms/inputs/MUITextInput";
import { type MedicalNoteFieldConfig, MedicalNoteFieldTypes, type MedicalNoteType } from "types";

import { DynamicField, LegacySection, SectionRow } from ".";

interface Props {
  readonly readonly: boolean;
  readonly fields: MedicalNoteFieldConfig[] | undefined;
  readonly legacyText: string | undefined | null;
  readonly onChange?: () => void;
  readonly label?: MedicalNoteType;
  readonly patientId: number;
  readonly payer?: Payer;
  readonly showNoteHeader?: boolean;
  readonly ailment?: Ailment;
  readonly painLocation?: string | null;
  readonly patientBillingAddress?: PatientBillingAddress | null;
}

const TopSection: React.VFC<Props> = ({
  readonly,
  fields,
  legacyText,
  onChange,
  label,
  patientId,
  payer,
  showNoteHeader,
  ailment,
  painLocation,
  patientBillingAddress,
}) => {
  const { data: patient } = useGetPatientQuery({ patientId });
  const { t } = useTranslation();
  const { profile } = useProfileContext();

  const patientName = `${patient?.first_name} ${patient?.last_name}`;
  const personalNumber = patient?.personal_number;

  return (
    <Container data-testid="top-section">
      {/* FIXME: type translation
      eslint-disable-next-line @typescript-eslint/ban-ts-comment
      @ts-ignore */}
      {showNoteHeader && <Label>{t(`dashboard.tables.notes.types.${label.toUpperCase()}`)}</Label>}
      <StyledSectionRow>
        <NameField
          name="patient_name"
          label={t("patients.extended_medical_note.patient_name.label")}
          value={patientName}
          disabled
          unregistered
        />
        <DateField
          name={personalNumber ? "patient_personal_number" : "patient_birthday"}
          label={
            personalNumber
              ? t("patients.medical_records.personal_number")
              : t("patients.extended_medical_note.patient_birthday.label")
          }
          value={
            personalNumber ||
            (patient?.birthday && new Date(patient?.birthday).toLocaleDateString(profile?.locale, { timeZone: "UTC" }))
          }
          disabled
          unregistered
        />
      </StyledSectionRow>
      {fields &&
        fields.map(field => {
          if (field.type === MedicalNoteFieldTypes.PATIENT_BILLING_ADDRESS) {
            if (!patientBillingAddress?.address && !patientBillingAddress?.zip_code) {
              return null;
            }

            const patientAddress = patientBillingAddress?.address
              ? `${patientBillingAddress.address} ${patientBillingAddress.zip_code}`
              : "";

            return (
              <SectionRow key={`section-row-${field.name}`}>
                <MUITextInput
                  label={t("patients.notes.fields.patient_billing_address")}
                  name={field.name}
                  disabled={field.readonly}
                  value={patientAddress}
                  unregistered
                />
              </SectionRow>
            );
          }

          return (
            <SectionRow key={`section-row-${field.name}`}>
              <DynamicField
                type={field.type}
                name={field.name}
                readonly={readonly || field.readonly}
                required={field.required}
                options={field.options}
                onChange={onChange}
                note_type={label}
                payer={payer}
                patientId={patientId}
                ailment={ailment}
                painLocation={painLocation}
              />
            </SectionRow>
          );
        })}
      {legacyText && <LegacySection legacyText={legacyText} />}
    </Container>
  );
};

const Container = styled.div`
  padding: ${props => props.theme.spacing.S_20};
  padding-right: 120px;
  border-bottom: 1px solid ${props => props.theme.colors.redesign.db30};

  ${props => props.theme.belowBreakpoint} {
    padding-right: ${props => props.theme.spacing.S_20};
  }
`;

const Label = styled.div`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 24px;
`;

const StyledSectionRow = styled(SectionRow)`
  ${props => props.theme.belowBreakpoint} {
    flex-direction: column;
  }
`;

const NameField = styled(MUITextInput)`
  flex: 1;
  margin-right: ${props => props.theme.spacing.S_15};

  ${props => props.theme.belowBreakpoint} {
    margin-right: 0;
  }
`;

const DateField = styled(MUITextInput)`
  width: 135px;
  flex: none;

  ${props => props.theme.belowBreakpoint} {
    width: auto;
    flex: 1;
  }
`;

export { TopSection };
