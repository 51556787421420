import { PDFDownloadLink, StyleSheet } from "@react-pdf/renderer";
import styled from "styled-components";

import { styles as buttonStyles } from "shared/atoms/BaseButton/BaseButton";

export const styles = StyleSheet.create({
  bold: {
    fontFamily: "Helvetica-Bold",
  },
  border: {
    borderBottom: "1px solid black",
  },
  checkbox: {
    width: 10,
    height: 10,
    border: "1px solid black",
    marginRight: 5,
  },
  checkbox_filled: {
    width: 10,
    height: 10,
    border: "1px solid black",
    backgroundColor: "black",
    marginRight: 5,
  },
  header: {
    fontFamily: "Helvetica-Bold",
    fontSize: 14,
    borderBottom: "1px solid black",
  },
  mb: {
    marginBottom: 5,
  },
  label: {
    fontFamily: "Helvetica-Bold",
    marginRight: 8,
  },
  page: { paddingTop: 35, paddingBottom: 65, paddingHorizontal: 35, fontSize: 10 },
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 35,
    textAlign: "right",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  section: {
    marginBottom: 10,
  },
  title: {
    fontFamily: "Helvetica-Bold",
    fontSize: 24,
    marginBottom: 20,
  },
});

export const StyledPDFDownloadLink = styled(PDFDownloadLink)`
  ${buttonStyles.tertiaryBlue}

  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  text-decoration: none;
  text-transform: uppercase;
  margin-left: auto;
`;
