export const DomainMailExtensionMapTypes = {
  SE: "se",
  GB: "uk",
  US: "com",
  UK: "uk",
} as const;
export type DomainMailExtension = typeof DomainMailExtensionMapTypes[keyof typeof DomainMailExtensionMapTypes];
export type DomainMailExtensionMap = keyof typeof DomainMailExtensionMapTypes;

export const isTypeMailDomainExtensionMap = (value: unknown): value is DomainMailExtensionMap =>
  Object.keys(DomainMailExtensionMapTypes).includes(value as DomainMailExtensionMap);
