import { useMutation, useQueryClient } from "react-query";

import type { PhysicianPlanOfCare, PutPhysicianPlanOfCareArgs } from "api/schemas/physician";
import useApiClient from "api/useApiClient";

import { getPhysicianHandledPatientQueryKey } from "./useGetPhysicianHandledPatient";
import { queryKeyPrefix as handledQueryPrefix } from "./useGetPhysicianHandledPatients";
import { getPhysicianUnhandledPatientQueryKey } from "./useGetPhysicianUnhandledPatient";
import { queryKeyPrefix as unhandledQueryPrefix } from "./useGetPhysicianUnhandledPatients";

export const usePutPhysicianPlanOfCare = (patientId?: number) => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<PhysicianPlanOfCare, Error, PutPhysicianPlanOfCareArgs>(
    args => client.putPhysicianPlanOfCare(args),
    {
      onSuccess: (data, variables) => {
        if (variables.data.status === "accepted" || variables.data.status === "rejected") {
          if (patientId) {
            queryClient.invalidateQueries(getPhysicianHandledPatientQueryKey(patientId));
            queryClient.invalidateQueries(getPhysicianUnhandledPatientQueryKey(patientId));
          }
          queryClient.invalidateQueries(handledQueryPrefix);
          queryClient.invalidateQueries(unhandledQueryPrefix);
        }
      },
    }
  );
};
