import type React from "react";
import { useState } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { usePutPhysicianMedicalReferral } from "api/hooks/usePutPhysicianMedicalReferral";
import { usePutPhysicianPlanOfCare } from "api/hooks/usePutPhysicianPlanOfCare";
import type { CareEvent } from "api/models/CareEvent";
import type { Status } from "api/schemas/physician";
import { Header } from "routes/calendar/components/EventDetails/styles";
import { BaseButton } from "shared/atoms/BaseButton";
import { Notification } from "shared/atoms/Notification";
import Popup from "shared/atoms/Popup";
import { MedicalNoteTypes } from "types";
import { reportError } from "utils/errorReporting";

interface Props {
  note: CareEvent;
  clearSelectedCareEvent?: () => void;
  patientId?: number;
}

const MedicalNoteFormButtonsPhysician: React.VFC<Props> = ({ note, clearSelectedCareEvent, patientId }) => {
  const { t } = useTranslation();
  const [showMarkAsCompleted, setShowMarkAsCompleted] = useState(false);
  const [showMarkAsDiscarded, setShowMarkAsDiscarded] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { mutateAsync: mutateAsyncReferral, isLoading: isLoadingReferral } = usePutPhysicianMedicalReferral(patientId);
  const { mutateAsync: mutateAsyncPlanOfCare, isLoading: isLoadingPlanOfCare } = usePutPhysicianPlanOfCare(patientId);

  const isLoading = isLoadingReferral || isLoadingPlanOfCare;

  const updateSatus = async (status: Status) => {
    if (note.label.toLocaleUpperCase() === MedicalNoteTypes.PHYSICIAN_MEDICAL_REFERRAL) {
      mutateAsyncReferral(
        {
          medicalReferralID: note.id,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          data: { ...note, status },
        },
        {
          onSuccess: () => {
            setShowMarkAsCompleted(false);
            setShowMarkAsDiscarded(false);
            if (clearSelectedCareEvent) clearSelectedCareEvent();
          },
          onError: e => {
            reportError("MedicalNoteFormButtonsPhysician.tsx", e);
            setError(t("errors.generic"));
          },
        }
      );
    } else if (note.label.toLocaleUpperCase() === MedicalNoteTypes.PHYSICIAN_PLAN_OF_CARE) {
      mutateAsyncPlanOfCare(
        {
          planOfCareID: note.id,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          data: { ...note, status },
        },
        {
          onSuccess: () => {
            setShowMarkAsCompleted(false);
            setShowMarkAsDiscarded(false);
            if (clearSelectedCareEvent) clearSelectedCareEvent();
          },
          onError: e => {
            reportError("MedicalNoteFormButtonsPhysician.tsx", e);
            setError(t("errors.generic"));
          },
        }
      );
    }
  };

  return (
    <>
      <ButtonWrapper>
        <BaseButton
          text={t("admin_billing.buttons.discard")}
          onClick={() => setShowMarkAsDiscarded(true)}
          variant="tertiary"
          uppercase
          icon="xmark"
          iconPlacement="start"
        />
        <BaseButton text={t("buttons.sign")} onClick={() => setShowMarkAsCompleted(true)} uppercase />
      </ButtonWrapper>

      {showMarkAsCompleted && (
        <Popup fullBgOpacity onClickOutside={() => setShowMarkAsCompleted(false)} showCloseButton>
          <PopupContent>
            <Header>{t("physician.sign", { label: note.label })}</Header>
            <BaseButton
              text={t("buttons.yes")}
              onClick={() => updateSatus("accepted")}
              disabled={isLoading}
              loading={isLoading}
              uppercase
              style={{ marginBottom: "20px" }}
            />
            <BaseButton
              text={t("buttons.cancel")}
              onClick={() => setShowMarkAsCompleted(false)}
              disabled={isLoading}
              variant="tertiaryBlue"
              uppercase
            />
            {error && (
              <Notification variant="danger" style={{ marginTop: "16px" }}>
                {error}
              </Notification>
            )}
          </PopupContent>
        </Popup>
      )}

      {showMarkAsDiscarded && (
        <Popup fullBgOpacity onClickOutside={() => setShowMarkAsDiscarded(false)} showCloseButton>
          <PopupContent>
            <Header>{t("physician.discard", { label: note.label })}</Header>
            <BaseButton
              text={t("buttons.yes")}
              onClick={() => updateSatus("rejected")}
              disabled={isLoading}
              loading={isLoading}
              uppercase
              style={{ marginBottom: "20px" }}
            />
            <BaseButton
              text={t("buttons.cancel")}
              onClick={() => setShowMarkAsDiscarded(false)}
              disabled={isLoading}
              variant="tertiaryBlue"
              uppercase
            />
            {error && (
              <Notification variant="danger" style={{ marginTop: "16px" }}>
                {error}
              </Notification>
            )}
          </PopupContent>
        </Popup>
      )}
    </>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin: 30px;

  button {
    margin-left: ${props => props.theme.spacing.S_15};
    max-width: 400px;
    flex: 0;
  }

  ${props => props.theme.belowBreakpoint} {
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;

    button {
      margin-left: 0;
      min-width: 100px;
      margin-top: ${props => props.theme.spacing.S_15};
      padding-left: ${props => props.theme.spacing.S_20};
      padding-right: ${props => props.theme.spacing.S_20};
      flex: 1;

      &:first-child {
        margin-right: ${props => props.theme.spacing.S_15};
      }

      &:last-child {
        align-self: stretch;
        flex: 0 0 100%;
      }
    }
  }
`;

export { MedicalNoteFormButtonsPhysician };

const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 430px;
  padding: 40px 26px 32px;
  text-align: center;
`;
