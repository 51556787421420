import type { UseQueryOptions, UseQueryResult } from "react-query";
import { useQuery } from "react-query";

import type { GetPatientNoteArgs, GetPatientNoteResponse } from "api/types/GetPatientNote";
import useApiClient from "api/useApiClient";

export type UseGetPatientNoteQueryArgs = GetPatientNoteArgs;

type QueryKey = ["useGetPatientNoteQuery", UseGetPatientNoteQueryArgs];

export const getPatientNoteQueryKey = (args: UseGetPatientNoteQueryArgs): QueryKey => ["useGetPatientNoteQuery", args];

export const useGetPatientNoteQuery = (
  args: UseGetPatientNoteQueryArgs,
  options?: UseQueryOptions<GetPatientNoteResponse, Error, GetPatientNoteResponse, QueryKey>
): UseQueryResult<GetPatientNoteResponse, Error> => {
  const client = useApiClient();

  return useQuery(getPatientNoteQueryKey(args), () => client.getPatientNote(args), options);
};
