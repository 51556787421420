import type { PayerType } from "api/models/CareEvent";
import { PayerTypes } from "api/models/CareEvent";

const kvaCodes = {
  AV018: "AV018",
  AW005: "AW005",
  AW020: "AW020",
  AW027: "AW027",
  AW035: "AW035",
  DV132: "DV132",
  DV200: "DV200",
  GB009: "GB009",
  GB020: "GB020",
  PA007: "PA007",
  PB003: "PB003",
  PB009: "PB009",
  PG000: "PG000",
  PG001: "PG001",
  PG002: "PG002",
  PG003: "PG003",
  PT002: "PT002",
  QB001: "QB001",
  QB002: "QB002",
  QB003: "QB003",
  QG000: "QG000",
  QT007: "QT007",
  QT017: "QT017",
  QV007: "QV007",
  QV011: "QV011",
  UV012: "UV012",
  UX101: "UX101",
  XS003: "XS003",
  XS011: "XS011",
  XS012: "XS012",
  XS014: "XS014",
  XV007: "XV007",
  ZV020: "ZV020",
  ZV044: "ZV044",
  ZV051: "ZV051",
  ZV203: "ZV203",
  ZV402: "ZV402",
  ZV403: "ZV403",
};

const commonCodes = [
  kvaCodes.AV018,
  kvaCodes.AW005,
  kvaCodes.AW020,
  kvaCodes.AW027,
  kvaCodes.AW035,
  kvaCodes.DV132,
  kvaCodes.DV200,
  kvaCodes.GB009,
  kvaCodes.GB020,
  kvaCodes.PA007,
  kvaCodes.PB003,
  kvaCodes.PB009,
  kvaCodes.PG000,
  kvaCodes.PG001,
  kvaCodes.PG002,
  kvaCodes.PG003,
  kvaCodes.PT002,
  kvaCodes.QB001,
  kvaCodes.QB002,
  kvaCodes.QB003,
  kvaCodes.QG000,
  kvaCodes.QT007,
  kvaCodes.QT017,
  kvaCodes.QV007,
  kvaCodes.QV011,
  kvaCodes.UV012,
  kvaCodes.UX101,
  kvaCodes.XS011,
  kvaCodes.XV007,
  kvaCodes.ZV020,
  kvaCodes.ZV051,
  kvaCodes.ZV402,
  kvaCodes.ZV403,
];

const options: Record<PayerType, string[]> = {
  [PayerTypes.FROSJO]: [
    ...commonCodes.filter(code => ![kvaCodes.QB001, kvaCodes.QB002, kvaCodes.QB003, kvaCodes.PG000].includes(code)),
    kvaCodes.XS012,
    kvaCodes.ZV044,
  ],
  [PayerTypes.OTHER]: [...commonCodes, kvaCodes.XS003, kvaCodes.XS012, kvaCodes.XS014, kvaCodes.ZV044, kvaCodes.ZV203],
  [PayerTypes.STOCKHOLM]: [...commonCodes, kvaCodes.XS003, kvaCodes.XS012, kvaCodes.XS014, kvaCodes.ZV203],
  [PayerTypes.VGR]: commonCodes,
};

export const kvaCodesForPayer = (payerType: PayerType) => (options[payerType] || commonCodes).sort();
