import type { IconName } from "@fortawesome/pro-regular-svg-icons";
import {
  faArrowLeft,
  faArrowRight,
  faEdit,
  faList,
  faPhone,
  faPhoneArrowUpRight,
  faPhoneXmark,
  faXmark,
} from "@fortawesome/pro-regular-svg-icons";
import { faBoxArchive, faCircleCheck, faCirclePlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const iconStyles = {
  height: "16px",
  width: "16px",
};

export const getIcon = (icon?: IconName) => {
  switch (icon) {
    case "edit":
      return <FontAwesomeIcon icon={faEdit} style={iconStyles} />;
    case "arrow-left":
      return <FontAwesomeIcon icon={faArrowLeft} style={iconStyles} />;
    case "arrow-right":
      return <FontAwesomeIcon icon={faArrowRight} style={iconStyles} />;
    case "box-archive":
      return <FontAwesomeIcon icon={faBoxArchive} style={iconStyles} />;
    case "circle-check":
      return <FontAwesomeIcon icon={faCircleCheck} style={iconStyles} />;
    case "circle-plus":
      return <FontAwesomeIcon icon={faCirclePlus} color="#5096E8" style={{ height: "20px", width: "20px" }} />;
    case "list":
      return <FontAwesomeIcon icon={faList} style={iconStyles} />;
    case "phone":
      return <FontAwesomeIcon icon={faPhone} style={iconStyles} />;
    case "phone-arrow-up-right":
      return <FontAwesomeIcon icon={faPhoneArrowUpRight} style={iconStyles} />;
    case "phone-xmark":
      return <FontAwesomeIcon icon={faPhoneXmark} style={iconStyles} />;
    case "xmark":
      return <FontAwesomeIcon icon={faXmark} color="#f00" style={iconStyles} />;
    default:
      return null;
  }
};
