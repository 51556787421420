import type React from "react";
import { useContext } from "react";

import { useTranslation } from "react-i18next";
import invariant from "ts-invariant";

import { VideoCallIcon } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import { useVideoCallContext } from "contexts/VideoCallContext";
import { useVoiceCallContext } from "contexts/VoiceCallContext";
import { CurrentPatientContext } from "utils/contexts";

import { Button } from "../../helpers";

interface Props {
  readonly onCallInitiated?: () => void;
  readonly inDropdown?: boolean;
}

const VideoCallButton: React.VFC<Props> = ({ onCallInitiated, inDropdown }) => {
  const { profile } = useProfileContext();
  const { patient } = useContext(CurrentPatientContext);
  const { callData: videoCallData, setCallData } = useVideoCallContext();
  const { voiceCallData } = useVoiceCallContext();
  const { t } = useTranslation();

  // TODO: Remove when rebuilding mobile menu
  const videoCallsEnabled: boolean =
    profile?.therapist_profile?.video_calls_enabled || profile?.therapist_profile?.payer_video_call_required || false;

  return videoCallsEnabled ? (
    <Button
      $justifyContent={inDropdown ? "flex-start" : ""}
      style={{ border: inDropdown ? "none" : "" }}
      data-testid="video-call-button"
      disabled={!!videoCallData || !!voiceCallData}
      onClick={() => {
        invariant(profile);
        setCallData({
          caller: {
            id: profile.id,
            name: `${profile.first_name} ${profile.last_name}`,
          },
          callee: {
            id: patient.id,
            name: `${patient.first_name} ${patient.last_name}`,
          },
        });
        if (onCallInitiated) {
          onCallInitiated();
        }
      }}
    >
      <VideoCallIcon />
      {t("buttons.video_call")}
    </Button>
  ) : null;
};

export default VideoCallButton;
