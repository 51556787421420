import type React from "react";
import { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useMatch, useParams } from "react-router-dom";
import styled from "styled-components";

import { useGetPatientGoalsQuery } from "api/hooks/useGetPatientGoalsQuery";
import { useGetPatientHealthJournalQuery } from "api/hooks/useGetPatientHealthJournalQuery";
import type { Goal as GoalType } from "api/models/Goal";
import { EditIcon } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import IconButton from "shared/atoms/IconButton";
import PatientGoal from "shared/molecules/PatientGoal/PatientGoal";
import { AnalyticsPages, AnalyticsService } from "utils/analytics";
import { CurrentPatientContext } from "utils/contexts";
import { userIsTherapist } from "utils/profile/profileHelper";

import NonEditGoal from "./NonEditGoal";
import PastGoals from "./PastGoals";

export const isGoalUndefined = (goal: Partial<GoalType> | undefined): boolean =>
  goal === undefined || Object.values(goal).every(value => value === undefined || value === null);

interface Props {
  showPastGoals?: boolean;
}

const Goal: React.VFC<Props> = ({ showPastGoals = true }) => {
  const { t } = useTranslation();
  const { patientId } = useParams<Record<string, string>>();
  const { patient, previousTreatmentId } = useContext(CurrentPatientContext);
  const notSet = t("dashboard.tables.calls.goal.not_set");
  const { profile } = useProfileContext();
  const isTherapist = userIsTherapist(profile);
  const [editGoal, setEditGoal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [goal, setGoal] = useState<Partial<GoalType>>();
  const match = useMatch("*");
  const { data: healthJournal } = useGetPatientHealthJournalQuery({
    patientId: patient.id,
    treatment_id: previousTreatmentId,
  });
  const ailment = healthJournal?.ailment;

  const getPatientGoalsQuery = useGetPatientGoalsQuery({ patientId: patient.id, treatment_id: previousTreatmentId });

  useEffect(() => {
    if (getPatientGoalsQuery.data?.[0]) {
      setGoal({
        id: getPatientGoalsQuery.data[0].id,
        free_text: getPatientGoalsQuery.data[0].free_text,
        pain: getPatientGoalsQuery.data[0].pain,
        functionality: getPatientGoalsQuery.data[0].functionality,
        feeling: getPatientGoalsQuery.data[0].feeling,
      });
    }
  }, [getPatientGoalsQuery.data]);

  useEffect(() => {
    if (match) {
      AnalyticsService.viewedPage(AnalyticsPages.PATIENTS.INFORMATION.GOAL, match.pathname);
    }
  }, [match?.pathname]);

  const successFunc = ({ id, free_text, pain, functionality, feeling }: Partial<GoalType>) => {
    setSuccess(true);
    setGoal({ id, free_text, pain, functionality, feeling });
    setEditGoal(false);
    setTimeout(() => {
      setSuccess(false);
    }, 3000);
  };

  const goalUndefined = isGoalUndefined(goal);

  const isPhysicalFunctionAvailable = () => {
    const mostPainfulLocation = patient.most_painful_location?.location;
    switch (mostPainfulLocation) {
      case "hand":
      case "shoulder":
      case "neck":
        return false;
      default:
        return true;
    }
  };

  // should really be healthJournal?.ailment === "joint_pain" but lets use location to be backwards compatible
  const isPainAvailable = () => patient.most_painful_location?.location;

  const isFeelingAvailable = () => ailment === "osteoporosis" || ailment === "fall_prevention";

  return (
    <>
      <CardContainer data-testid="goal">
        <CardTitle>
          <Title>{t("patients.your_goal")}</Title>
          {editGoal || !isTherapist ? null : (
            <IconButton
              dataTestId="add-new-goal-button"
              label={goalUndefined ? t("buttons.add_new_goal") : t("buttons.edit_goal")}
              onClick={() => setEditGoal(true)}
            >
              <EditIcon />
            </IconButton>
          )}
        </CardTitle>
        <CardBody>
          {editGoal ? (
            <div data-testid="edit-goal-field">
              <PatientGoal
                id={patient?.id}
                onCancel={() => setEditGoal(false)}
                onSuccess={successFunc}
                currentGoal={{
                  id: goal?.id,
                  description: goal?.free_text,
                  functionality: goal?.functionality?.toString(),
                  pain: goal?.pain?.toString(),
                  feeling: goal?.feeling?.toString(),
                }}
                isPhysicalFunctionAvailable={isPhysicalFunctionAvailable}
                isFeelingAvailable={isFeelingAvailable}
                isPainAvailable={isPainAvailable}
              />
            </div>
          ) : (
            <NonEditGoal
              canDeleteGoal={isTherapist && !goalUndefined}
              success={success}
              setGoal={setGoal}
              patient={patient}
              patientId={patientId ?? ""}
              goal={goal}
              notSet={notSet}
              isPhysicalFunctionAvailable={isPhysicalFunctionAvailable}
              isFeelingAvailable={isFeelingAvailable}
              isPainAvailable={isPainAvailable}
              error={getPatientGoalsQuery.error}
            />
          )}
        </CardBody>
        {showPastGoals && <PastGoals data={getPatientGoalsQuery.data} />}
      </CardContainer>
    </>
  );
};

export default Goal;

export const Paragraph = styled.p`
  margin: ${props => props.theme.spacing.S_10} 0;
`;
export const ParagraphTitle = styled.span`
  display: block;
  ${props => props.theme.font.body1};
  color: ${props => props.theme.colors.greys.dark};
  font-weight: ${props => props.theme.fontWeight.light};
  font-size: 14px;
`;

/* stylelint-disable property-no-vendor-prefix */
/* stylelint-disable value-no-vendor-prefix */
export const ParagraphContent = styled.span`
  color: ${props => props.theme.colors.primary.base};
  white-space: pre-wrap;
  display: box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CardContainer = styled.div`
  padding: ${props => props.theme.spacing.S_20} ${props => props.theme.spacing.S_15};
  max-width: 1000px;
  box-sizing: border-box;
  margin: 0 auto;
  height: 460px;
  ${props => props.theme.belowBreakpoint} {
    width: 100%;
  }
`;

export const CardTitle = styled.div`
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: ${props => props.theme.spacing.S_30};
`;

export const Title = styled.span`
  ${props => props.theme.font.body1};
  color: ${props => props.theme.colors.primary.base};
  font-weight: ${props => props.theme.fontWeight.medium};
  margin: auto 0;
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.spacing.S_20} 0;
`;
